// tslint:disable
// / <reference path="./custom.d.ts" />
/**
 * Catalog Api v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as globalImportUrl from 'url';
import { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from './configuration';
import { httpClient as globalAxios } from '../../../http/http';
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * A model to represent asset favorites.
 * @export
 * @interface RobloxCatalogApiAssetFavoriteModel
 */
export interface RobloxCatalogApiAssetFavoriteModel {
  /**
   * The Id of the asset being favorited.
   * @type {number}
   * @memberof RobloxCatalogApiAssetFavoriteModel
   */
  assetId?: number;
  /**
   * The Id of the user favoriting the asset.
   * @type {number}
   * @memberof RobloxCatalogApiAssetFavoriteModel
   */
  userId?: number;
  /**
   * The time at which the user favorited the asset.
   * @type {string}
   * @memberof RobloxCatalogApiAssetFavoriteModel
   */
  created?: string;
}
/**
 *
 * @export
 * @interface RobloxCatalogApiBundleCreatorModel
 */
export interface RobloxCatalogApiBundleCreatorModel {
  /**
   *
   * @type {number}
   * @memberof RobloxCatalogApiBundleCreatorModel
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof RobloxCatalogApiBundleCreatorModel
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof RobloxCatalogApiBundleCreatorModel
   */
  type?: string;
}
/**
 *
 * @export
 * @interface RobloxCatalogApiBundleDetailsModel
 */
export interface RobloxCatalogApiBundleDetailsModel {
  /**
   *
   * @type {number}
   * @memberof RobloxCatalogApiBundleDetailsModel
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof RobloxCatalogApiBundleDetailsModel
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof RobloxCatalogApiBundleDetailsModel
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof RobloxCatalogApiBundleDetailsModel
   */
  bundleType?: string;
  /**
   *
   * @type {Array<RobloxCatalogApiBundleItemDetailModel>}
   * @memberof RobloxCatalogApiBundleDetailsModel
   */
  items?: Array<RobloxCatalogApiBundleItemDetailModel>;
  /**
   *
   * @type {RobloxCatalogApiBundleCreatorModel}
   * @memberof RobloxCatalogApiBundleDetailsModel
   */
  creator?: RobloxCatalogApiBundleCreatorModel;
  /**
   *
   * @type {RobloxCatalogApiBundleProductModel}
   * @memberof RobloxCatalogApiBundleDetailsModel
   */
  product?: RobloxCatalogApiBundleProductModel;
}
/**
 * A model to represent bundle favorites.
 * @export
 * @interface RobloxCatalogApiBundleFavoriteModel
 */
export interface RobloxCatalogApiBundleFavoriteModel {
  /**
   * The Id of the bundle being favorited.
   * @type {number}
   * @memberof RobloxCatalogApiBundleFavoriteModel
   */
  bundleId?: number;
  /**
   * The Id of the user favoriting the bundle.
   * @type {number}
   * @memberof RobloxCatalogApiBundleFavoriteModel
   */
  userId?: number;
  /**
   * The time at which the user favorited the bundle.
   * @type {string}
   * @memberof RobloxCatalogApiBundleFavoriteModel
   */
  created?: string;
}
/**
 *
 * @export
 * @interface RobloxCatalogApiBundleItemDetailModel
 */
export interface RobloxCatalogApiBundleItemDetailModel {
  /**
   *
   * @type {boolean}
   * @memberof RobloxCatalogApiBundleItemDetailModel
   */
  owned?: boolean;
  /**
   *
   * @type {number}
   * @memberof RobloxCatalogApiBundleItemDetailModel
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof RobloxCatalogApiBundleItemDetailModel
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof RobloxCatalogApiBundleItemDetailModel
   */
  type?: string;
}
/**
 *
 * @export
 * @interface RobloxCatalogApiBundleProductModel
 */
export interface RobloxCatalogApiBundleProductModel {
  /**
   *
   * @type {number}
   * @memberof RobloxCatalogApiBundleProductModel
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof RobloxCatalogApiBundleProductModel
   */
  type?: string;
  /**
   *
   * @type {boolean}
   * @memberof RobloxCatalogApiBundleProductModel
   */
  isPublicDomain?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof RobloxCatalogApiBundleProductModel
   */
  isForSale?: boolean;
  /**
   *
   * @type {number}
   * @memberof RobloxCatalogApiBundleProductModel
   */
  priceInRobux?: number;
  /**
   *
   * @type {boolean}
   * @memberof RobloxCatalogApiBundleProductModel
   */
  isFree?: boolean;
  /**
   *
   * @type {string}
   * @memberof RobloxCatalogApiBundleProductModel
   */
  noPriceText?: string;
  /**
   *
   * @type {RobloxCatalogApiPremiumPricingModel}
   * @memberof RobloxCatalogApiBundleProductModel
   */
  premiumPricing?: RobloxCatalogApiPremiumPricingModel;
}
/**
 * Basic catalog item model
 * @export
 * @interface RobloxCatalogApiCatalogItemModel
 */
export interface RobloxCatalogApiCatalogItemModel {
  /**
   *
   * @type {number}
   * @memberof RobloxCatalogApiCatalogItemModel
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof RobloxCatalogApiCatalogItemModel
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof RobloxCatalogApiCatalogItemModel
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof RobloxCatalogApiCatalogItemModel
   */
  type?: string;
  /**
   *
   * @type {number}
   * @memberof RobloxCatalogApiCatalogItemModel
   */
  priceInRobux?: number;
  /**
   *
   * @type {RobloxCatalogApiPremiumPricingModel}
   * @memberof RobloxCatalogApiCatalogItemModel
   */
  premiumPricing?: RobloxCatalogApiPremiumPricingModel;
}
/**
 * Response model for navigation menu items
 * @export
 * @interface RobloxCatalogApiCatalogMenuItemsResponseModel
 */
export interface RobloxCatalogApiCatalogMenuItemsResponseModel {
  /**
   *
   * @type {string}
   * @memberof RobloxCatalogApiCatalogMenuItemsResponseModel
   */
  defaultGearSubcategory?: RobloxCatalogApiCatalogMenuItemsResponseModelDefaultGearSubcategoryEnum;
  /**
   *
   * @type {string}
   * @memberof RobloxCatalogApiCatalogMenuItemsResponseModel
   */
  defaultCategory?: RobloxCatalogApiCatalogMenuItemsResponseModelDefaultCategoryEnum;
  /**
   *
   * @type {string}
   * @memberof RobloxCatalogApiCatalogMenuItemsResponseModel
   */
  defaultCategoryIdForRecommendedSearch?: RobloxCatalogApiCatalogMenuItemsResponseModelDefaultCategoryIdForRecommendedSearchEnum;
  /**
   *
   * @type {string}
   * @memberof RobloxCatalogApiCatalogMenuItemsResponseModel
   */
  defaultCreator?: RobloxCatalogApiCatalogMenuItemsResponseModelDefaultCreatorEnum;
  /**
   *
   * @type {string}
   * @memberof RobloxCatalogApiCatalogMenuItemsResponseModel
   */
  defaultCurrency?: RobloxCatalogApiCatalogMenuItemsResponseModelDefaultCurrencyEnum;
  /**
   *
   * @type {string}
   * @memberof RobloxCatalogApiCatalogMenuItemsResponseModel
   */
  defaultSortType?: RobloxCatalogApiCatalogMenuItemsResponseModelDefaultSortTypeEnum;
  /**
   *
   * @type {string}
   * @memberof RobloxCatalogApiCatalogMenuItemsResponseModel
   */
  defaultSortAggregation?: RobloxCatalogApiCatalogMenuItemsResponseModelDefaultSortAggregationEnum;
  /**
   *
   * @type {Array<string>}
   * @memberof RobloxCatalogApiCatalogMenuItemsResponseModel
   */
  categoriesWithCreator?: Array<
    RobloxCatalogApiCatalogMenuItemsResponseModelCategoriesWithCreatorEnum
  >;
  /**
   *
   * @type {number}
   * @memberof RobloxCatalogApiCatalogMenuItemsResponseModel
   */
  robloxUserId?: number;
  /**
   *
   * @type {string}
   * @memberof RobloxCatalogApiCatalogMenuItemsResponseModel
   */
  robloxUserName?: string;
  /**
   *
   * @type {string}
   * @memberof RobloxCatalogApiCatalogMenuItemsResponseModel
   */
  gearSubcategory?: RobloxCatalogApiCatalogMenuItemsResponseModelGearSubcategoryEnum;
  /**
   *
   * @type {string}
   * @memberof RobloxCatalogApiCatalogMenuItemsResponseModel
   */
  allCategories?: RobloxCatalogApiCatalogMenuItemsResponseModelAllCategoriesEnum;
  /**
   *
   * @type {string}
   * @memberof RobloxCatalogApiCatalogMenuItemsResponseModel
   */
  freeFilter?: RobloxCatalogApiCatalogMenuItemsResponseModelFreeFilterEnum;
  /**
   *
   * @type {string}
   * @memberof RobloxCatalogApiCatalogMenuItemsResponseModel
   */
  customRobuxFilter?: RobloxCatalogApiCatalogMenuItemsResponseModelCustomRobuxFilterEnum;
  /**
   *
   * @type {string}
   * @memberof RobloxCatalogApiCatalogMenuItemsResponseModel
   */
  robuxFilter?: RobloxCatalogApiCatalogMenuItemsResponseModelRobuxFilterEnum;
  /**
   * Response object representing all categories
   * @type {Array<RobloxCatalogApiCategoryModel>}
   * @memberof RobloxCatalogApiCatalogMenuItemsResponseModel
   */
  categories?: Array<RobloxCatalogApiCategoryModel>;
  /**
   * Response object representing all genres
   * @type {Array<RobloxCatalogApiGenreModel>}
   * @memberof RobloxCatalogApiCatalogMenuItemsResponseModel
   */
  genres?: Array<RobloxCatalogApiGenreModel>;
  /**
   *
   * @type {RobloxCatalogApiSortMenuModel}
   * @memberof RobloxCatalogApiCatalogMenuItemsResponseModel
   */
  sortMenu?: RobloxCatalogApiSortMenuModel;
  /**
   * Response object representing all creator filters
   * @type {Array<RobloxCatalogApiCreatorFilterModel>}
   * @memberof RobloxCatalogApiCatalogMenuItemsResponseModel
   */
  creatorFilters?: Array<RobloxCatalogApiCreatorFilterModel>;
  /**
   * Response object representing all price filters
   * @type {Array<RobloxCatalogApiPriceFilterModel>}
   * @memberof RobloxCatalogApiCatalogMenuItemsResponseModel
   */
  priceFilters?: Array<RobloxCatalogApiPriceFilterModel>;
}

/**
 * @export
 * @enum {string}
 */
export enum RobloxCatalogApiCatalogMenuItemsResponseModelDefaultGearSubcategoryEnum {
  Accessories = 'Accessories',
  All = 'All',
  AvatarAnimations = 'AvatarAnimations',
  BackAccessories = 'BackAccessories',
  BodyParts = 'BodyParts',
  Clothing = 'Clothing',
  Collectibles = 'Collectibles',
  FaceAccessories = 'FaceAccessories',
  Faces = 'Faces',
  Featured = 'Featured',
  FrontAccessories = 'FrontAccessories',
  Gear = 'Gear',
  HairAccessories = 'HairAccessories',
  Hats = 'Hats',
  Heads = 'Heads',
  NeckAccessories = 'NeckAccessories',
  Pants = 'Pants',
  Shirts = 'Shirts',
  ShoulderAccessories = 'ShoulderAccessories',
  Tshirts = 'Tshirts',
  WaistAccessories = 'WaistAccessories',
  Bundles = 'Bundles',
  AnimationBundles = 'AnimationBundles',
  EmoteAnimations = 'EmoteAnimations',
  CommunityCreations = 'CommunityCreations',
  Melee = 'Melee',
  Ranged = 'Ranged',
  Explosive = 'Explosive',
  PowerUp = 'PowerUp',
  Navigation = 'Navigation',
  Musical = 'Musical',
  Social = 'Social',
  Building = 'Building',
  Transport = 'Transport',
  Premium = 'Premium',
  Recommended = 'Recommended'
}
/**
 * @export
 * @enum {string}
 */
export enum RobloxCatalogApiCatalogMenuItemsResponseModelDefaultCategoryEnum {
  Accessories = 'Accessories',
  All = 'All',
  AvatarAnimations = 'AvatarAnimations',
  BodyParts = 'BodyParts',
  Clothing = 'Clothing',
  Collectibles = 'Collectibles',
  Featured = 'Featured',
  Gear = 'Gear',
  CommunityCreations = 'CommunityCreations',
  Premium = 'Premium',
  Recommended = 'Recommended'
}
/**
 * @export
 * @enum {string}
 */
export enum RobloxCatalogApiCatalogMenuItemsResponseModelDefaultCategoryIdForRecommendedSearchEnum {
  Accessories = 'Accessories',
  All = 'All',
  AvatarAnimations = 'AvatarAnimations',
  BodyParts = 'BodyParts',
  Clothing = 'Clothing',
  Collectibles = 'Collectibles',
  Featured = 'Featured',
  Gear = 'Gear',
  CommunityCreations = 'CommunityCreations',
  Premium = 'Premium',
  Recommended = 'Recommended'
}
/**
 * @export
 * @enum {string}
 */
export enum RobloxCatalogApiCatalogMenuItemsResponseModelDefaultCreatorEnum {
  User = 'User',
  Group = 'Group'
}
/**
 * @export
 * @enum {string}
 */
export enum RobloxCatalogApiCatalogMenuItemsResponseModelDefaultCurrencyEnum {
  All = 'All',
  Robux = 'Robux',
  Tickets = 'Tickets',
  CustomRobux = 'CustomRobux',
  CustomTickets = 'CustomTickets',
  Free = 'Free'
}
/**
 * @export
 * @enum {string}
 */
export enum RobloxCatalogApiCatalogMenuItemsResponseModelDefaultSortTypeEnum {
  Relevance = 'Relevance',
  Favorited = 'Favorited',
  Sales = 'Sales',
  Updated = 'Updated',
  PriceAsc = 'PriceAsc',
  PriceDesc = 'PriceDesc'
}
/**
 * @export
 * @enum {string}
 */
export enum RobloxCatalogApiCatalogMenuItemsResponseModelDefaultSortAggregationEnum {
  Past12Hours = 'Past12Hours',
  PastDay = 'PastDay',
  Past3Days = 'Past3Days',
  PastWeek = 'PastWeek',
  PastMonth = 'PastMonth',
  AllTime = 'AllTime'
}
/**
 * @export
 * @enum {string}
 */
export enum RobloxCatalogApiCatalogMenuItemsResponseModelCategoriesWithCreatorEnum {
  Accessories = 'Accessories',
  All = 'All',
  AvatarAnimations = 'AvatarAnimations',
  BodyParts = 'BodyParts',
  Clothing = 'Clothing',
  Collectibles = 'Collectibles',
  Featured = 'Featured',
  Gear = 'Gear',
  CommunityCreations = 'CommunityCreations',
  Premium = 'Premium',
  Recommended = 'Recommended'
}
/**
 * @export
 * @enum {string}
 */
export enum RobloxCatalogApiCatalogMenuItemsResponseModelGearSubcategoryEnum {
  Accessories = 'Accessories',
  All = 'All',
  AvatarAnimations = 'AvatarAnimations',
  BackAccessories = 'BackAccessories',
  BodyParts = 'BodyParts',
  Clothing = 'Clothing',
  Collectibles = 'Collectibles',
  FaceAccessories = 'FaceAccessories',
  Faces = 'Faces',
  Featured = 'Featured',
  FrontAccessories = 'FrontAccessories',
  Gear = 'Gear',
  HairAccessories = 'HairAccessories',
  Hats = 'Hats',
  Heads = 'Heads',
  NeckAccessories = 'NeckAccessories',
  Pants = 'Pants',
  Shirts = 'Shirts',
  ShoulderAccessories = 'ShoulderAccessories',
  Tshirts = 'Tshirts',
  WaistAccessories = 'WaistAccessories',
  Bundles = 'Bundles',
  AnimationBundles = 'AnimationBundles',
  EmoteAnimations = 'EmoteAnimations',
  CommunityCreations = 'CommunityCreations',
  Melee = 'Melee',
  Ranged = 'Ranged',
  Explosive = 'Explosive',
  PowerUp = 'PowerUp',
  Navigation = 'Navigation',
  Musical = 'Musical',
  Social = 'Social',
  Building = 'Building',
  Transport = 'Transport',
  Premium = 'Premium',
  Recommended = 'Recommended'
}
/**
 * @export
 * @enum {string}
 */
export enum RobloxCatalogApiCatalogMenuItemsResponseModelAllCategoriesEnum {
  Accessories = 'Accessories',
  All = 'All',
  AvatarAnimations = 'AvatarAnimations',
  BodyParts = 'BodyParts',
  Clothing = 'Clothing',
  Collectibles = 'Collectibles',
  Featured = 'Featured',
  Gear = 'Gear',
  CommunityCreations = 'CommunityCreations',
  Premium = 'Premium',
  Recommended = 'Recommended'
}
/**
 * @export
 * @enum {string}
 */
export enum RobloxCatalogApiCatalogMenuItemsResponseModelFreeFilterEnum {
  All = 'All',
  Robux = 'Robux',
  Tickets = 'Tickets',
  CustomRobux = 'CustomRobux',
  CustomTickets = 'CustomTickets',
  Free = 'Free'
}
/**
 * @export
 * @enum {string}
 */
export enum RobloxCatalogApiCatalogMenuItemsResponseModelCustomRobuxFilterEnum {
  All = 'All',
  Robux = 'Robux',
  Tickets = 'Tickets',
  CustomRobux = 'CustomRobux',
  CustomTickets = 'CustomTickets',
  Free = 'Free'
}
/**
 * @export
 * @enum {string}
 */
export enum RobloxCatalogApiCatalogMenuItemsResponseModelRobuxFilterEnum {
  All = 'All',
  Robux = 'Robux',
  Tickets = 'Tickets',
  CustomRobux = 'CustomRobux',
  CustomTickets = 'CustomTickets',
  Free = 'Free'
}

/**
 * Catalog Metadata model
 * @export
 * @interface RobloxCatalogApiCatalogMetadataModel
 */
export interface RobloxCatalogApiCatalogMetadataModel {
  /**
   * Default number of Splash tiles in catalog page
   * @type {number}
   * @memberof RobloxCatalogApiCatalogMetadataModel
   */
  numberOfCatalogItemsToDisplayOnSplash?: number;
  /**
   * Number of splash tiles in catalog page on phone
   * @type {number}
   * @memberof RobloxCatalogApiCatalogMetadataModel
   */
  numberOfCatalogItemsToDisplayOnSplashOnPhone?: number;
  /**
   * Should consumer use catalog sorts
   * @type {boolean}
   * @memberof RobloxCatalogApiCatalogMetadataModel
   */
  isCatalogSortsFromApiEnabled?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof RobloxCatalogApiCatalogMetadataModel
   */
  is3dInEachItemCardAbTestingEnabled?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof RobloxCatalogApiCatalogMetadataModel
   */
  is3dInEachItemCardEnabled?: boolean;
  /**
   *
   * @type {number}
   * @memberof RobloxCatalogApiCatalogMetadataModel
   */
  timeoutOn3dThumbnailRequestInMs?: number;
  /**
   * Determines whether Premium prices, when available, should be shown on catalog item tiles
   * @type {boolean}
   * @memberof RobloxCatalogApiCatalogMetadataModel
   */
  isPremiumPriceOnItemTilesEnabled?: boolean;
  /**
   * Determines whether Premium icons, when applicable, should be shown on catalog item tiles
   * @type {boolean}
   * @memberof RobloxCatalogApiCatalogMetadataModel
   */
  isPremiumIconOnItemTilesEnabled?: boolean;
  /**
   * Determines whether Premium sort is availabe
   * @type {boolean}
   * @memberof RobloxCatalogApiCatalogMetadataModel
   */
  isPremiumSortEnabled?: boolean;
}
/**
 * The detailed model for catalog items, complete with economy and ownership info
 * @export
 * @interface RobloxCatalogApiCatalogSearchDetailedCompleteResponseItem
 */
export interface RobloxCatalogApiCatalogSearchDetailedCompleteResponseItem {
  /**
   * whether item is available for rent
   * @type {boolean}
   * @memberof RobloxCatalogApiCatalogSearchDetailedCompleteResponseItem
   */
  isForRent?: boolean;
  /**
   * Id to pass into economy purchase api  userID of lowest private seller if resellable  Creator AgentID (deprecated) / CreatorTargetId otherwise
   * @type {number}
   * @memberof RobloxCatalogApiCatalogSearchDetailedCompleteResponseItem
   */
  expectedSellerId?: number;
  /**
   * Whether item is owned by user
   * @type {boolean}
   * @memberof RobloxCatalogApiCatalogSearchDetailedCompleteResponseItem
   */
  owned?: boolean;
  /**
   * Whether item can be purchased
   * @type {boolean}
   * @memberof RobloxCatalogApiCatalogSearchDetailedCompleteResponseItem
   */
  isPurchasable?: boolean;
  /**
   * The Item Id.
   * @type {number}
   * @memberof RobloxCatalogApiCatalogSearchDetailedCompleteResponseItem
   */
  id?: number;
  /**
   * The {Roblox.Catalog.Api.CatalogSearchDetailedResponseItem.ItemType} item type.
   * @type {string}
   * @memberof RobloxCatalogApiCatalogSearchDetailedCompleteResponseItem
   */
  itemType?: RobloxCatalogApiCatalogSearchDetailedCompleteResponseItemItemTypeEnum;
  /**
   * The {Roblox.Platform.Assets.AssetType} serialized if item is an asset.
   * @type {string}
   * @memberof RobloxCatalogApiCatalogSearchDetailedCompleteResponseItem
   */
  assetType?: RobloxCatalogApiCatalogSearchDetailedCompleteResponseItemAssetTypeEnum;
  /**
   * The {Roblox.Platform.Bundles.Core.BundleType} serialized if item is a bundle.
   * @type {string}
   * @memberof RobloxCatalogApiCatalogSearchDetailedCompleteResponseItem
   */
  bundleType?: RobloxCatalogApiCatalogSearchDetailedCompleteResponseItemBundleTypeEnum;
  /**
   * The item name.
   * @type {string}
   * @memberof RobloxCatalogApiCatalogSearchDetailedCompleteResponseItem
   */
  name?: string;
  /**
   * The item description.
   * @type {string}
   * @memberof RobloxCatalogApiCatalogSearchDetailedCompleteResponseItem
   */
  description?: string;
  /**
   * The product id of corresponding item.
   * @type {number}
   * @memberof RobloxCatalogApiCatalogSearchDetailedCompleteResponseItem
   */
  productId?: number;
  /**
   * The {System.Collections.Generic.IEnumerable`1}, serialized if item has genres.
   * @type {Array<string>}
   * @memberof RobloxCatalogApiCatalogSearchDetailedCompleteResponseItem
   */
  genres?: Array<RobloxCatalogApiCatalogSearchDetailedCompleteResponseItemGenresEnum>;
  /**
   * The {System.Collections.Generic.IEnumerable`1} contained in the bundle, serialized if item is a bundle.
   * @type {Array<RobloxCatalogApiBundleItemDetailModel>}
   * @memberof RobloxCatalogApiCatalogSearchDetailedCompleteResponseItem
   */
  bundledItems?: Array<RobloxCatalogApiBundleItemDetailModel>;
  /**
   * The {System.Collections.Generic.IEnumerable`1} if item has {Roblox.Catalog.Api.CatalogItemStatus}.
   * @type {Array<string>}
   * @memberof RobloxCatalogApiCatalogSearchDetailedCompleteResponseItem
   */
  itemStatus?: Array<RobloxCatalogApiCatalogSearchDetailedCompleteResponseItemItemStatusEnum>;
  /**
   * The {System.Collections.Generic.IEnumerable`1} if item has {Roblox.Catalog.Api.CatalogItemRestriction}.
   * @type {Array<string>}
   * @memberof RobloxCatalogApiCatalogSearchDetailedCompleteResponseItem
   */
  itemRestrictions?: Array<
    RobloxCatalogApiCatalogSearchDetailedCompleteResponseItemItemRestrictionsEnum
  >;
  /**
   * The {Roblox.Catalog.Api.CatalogSearchDetailedResponseItem.CreatorType} of the item\'s creator.
   * @type {string}
   * @memberof RobloxCatalogApiCatalogSearchDetailedCompleteResponseItem
   */
  creatorType?: RobloxCatalogApiCatalogSearchDetailedCompleteResponseItemCreatorTypeEnum;
  /**
   * The creator id of the item\'s creator.
   * @type {number}
   * @memberof RobloxCatalogApiCatalogSearchDetailedCompleteResponseItem
   */
  creatorTargetId?: number;
  /**
   * The creator name of the item\'s creator.
   * @type {string}
   * @memberof RobloxCatalogApiCatalogSearchDetailedCompleteResponseItem
   */
  creatorName?: string;
  /**
   * The item\'s price.
   * @type {number}
   * @memberof RobloxCatalogApiCatalogSearchDetailedCompleteResponseItem
   */
  price?: number;
  /**
   *
   * @type {RobloxCatalogApiPremiumPricingModel}
   * @memberof RobloxCatalogApiCatalogSearchDetailedCompleteResponseItem
   */
  premiumPricing?: RobloxCatalogApiPremiumPricingModel;
  /**
   * The item\'s lowest price, only if the item is resellable and there are resellers.
   * @type {number}
   * @memberof RobloxCatalogApiCatalogSearchDetailedCompleteResponseItem
   */
  lowestPrice?: number;
  /**
   * The localized string item status if the item\'s price should not be displayed.
   * @type {string}
   * @memberof RobloxCatalogApiCatalogSearchDetailedCompleteResponseItem
   */
  priceStatus?: string;
  /**
   * The number of items in stock, only if the item is resellable and is limitedEdition
   * @type {number}
   * @memberof RobloxCatalogApiCatalogSearchDetailedCompleteResponseItem
   */
  unitsAvailableForConsumption?: number;
  /**
   * The number of times the item has been purchased.
   * @type {number}
   * @memberof RobloxCatalogApiCatalogSearchDetailedCompleteResponseItem
   */
  purchaseCount?: number;
  /**
   * The number of times the item has been favorited.
   * @type {number}
   * @memberof RobloxCatalogApiCatalogSearchDetailedCompleteResponseItem
   */
  favoriteCount?: number;
}

/**
 * @export
 * @enum {string}
 */
export enum RobloxCatalogApiCatalogSearchDetailedCompleteResponseItemItemTypeEnum {
  Asset = 'Asset',
  Bundle = 'Bundle'
}
/**
 * @export
 * @enum {string}
 */
export enum RobloxCatalogApiCatalogSearchDetailedCompleteResponseItemAssetTypeEnum {
  Image = 'Image',
  TShirt = 'TShirt',
  Audio = 'Audio',
  Mesh = 'Mesh',
  Lua = 'Lua',
  HTML = 'HTML',
  Text = 'Text',
  Hat = 'Hat',
  Place = 'Place',
  Model = 'Model',
  Shirt = 'Shirt',
  Pants = 'Pants',
  Decal = 'Decal',
  Avatar = 'Avatar',
  Head = 'Head',
  Face = 'Face',
  Gear = 'Gear',
  Badge = 'Badge',
  GroupEmblem = 'GroupEmblem',
  Animation = 'Animation',
  Arms = 'Arms',
  Legs = 'Legs',
  Torso = 'Torso',
  RightArm = 'RightArm',
  LeftArm = 'LeftArm',
  LeftLeg = 'LeftLeg',
  RightLeg = 'RightLeg',
  Package = 'Package',
  YouTubeVideo = 'YouTubeVideo',
  GamePass = 'GamePass',
  App = 'App',
  Code = 'Code',
  Plugin = 'Plugin',
  SolidModel = 'SolidModel',
  MeshPart = 'MeshPart',
  HairAccessory = 'HairAccessory',
  FaceAccessory = 'FaceAccessory',
  NeckAccessory = 'NeckAccessory',
  ShoulderAccessory = 'ShoulderAccessory',
  FrontAccessory = 'FrontAccessory',
  BackAccessory = 'BackAccessory',
  WaistAccessory = 'WaistAccessory',
  ClimbAnimation = 'ClimbAnimation',
  DeathAnimation = 'DeathAnimation',
  FallAnimation = 'FallAnimation',
  IdleAnimation = 'IdleAnimation',
  JumpAnimation = 'JumpAnimation',
  RunAnimation = 'RunAnimation',
  SwimAnimation = 'SwimAnimation',
  WalkAnimation = 'WalkAnimation',
  PoseAnimation = 'PoseAnimation',
  LocalizationTableManifest = 'LocalizationTableManifest',
  LocalizationTableTranslation = 'LocalizationTableTranslation',
  EmoteAnimation = 'EmoteAnimation',
  Video = 'Video',
  TexturePack = 'TexturePack'
}
/**
 * @export
 * @enum {string}
 */
export enum RobloxCatalogApiCatalogSearchDetailedCompleteResponseItemBundleTypeEnum {
  BodyParts = 'BodyParts',
  AvatarAnimations = 'AvatarAnimations'
}
/**
 * @export
 * @enum {string}
 */
export enum RobloxCatalogApiCatalogSearchDetailedCompleteResponseItemGenresEnum {
  All = 'All',
  Tutorial = 'Tutorial',
  Scary = 'Scary',
  TownAndCity = 'TownAndCity',
  War = 'War',
  Funny = 'Funny',
  Fantasy = 'Fantasy',
  Adventure = 'Adventure',
  SciFi = 'SciFi',
  Pirate = 'Pirate',
  FPS = 'FPS',
  RPG = 'RPG',
  Sports = 'Sports',
  Ninja = 'Ninja',
  WildWest = 'WildWest'
}
/**
 * @export
 * @enum {string}
 */
export enum RobloxCatalogApiCatalogSearchDetailedCompleteResponseItemItemStatusEnum {
  New = 'New',
  Sale = 'Sale',
  XboxExclusive = 'XboxExclusive',
  AmazonExclusive = 'AmazonExclusive',
  GooglePlayExclusive = 'GooglePlayExclusive',
  IosExclusive = 'IosExclusive',
  SaleTimer = 'SaleTimer'
}
/**
 * @export
 * @enum {string}
 */
export enum RobloxCatalogApiCatalogSearchDetailedCompleteResponseItemItemRestrictionsEnum {
  ThirteenPlus = 'ThirteenPlus',
  LimitedUnique = 'LimitedUnique',
  Limited = 'Limited',
  BuildersClub = 'BuildersClub',
  TurboBuildersClub = 'TurboBuildersClub',
  OutrageousBuildersClub = 'OutrageousBuildersClub',
  Rthro = 'Rthro'
}
/**
 * @export
 * @enum {string}
 */
export enum RobloxCatalogApiCatalogSearchDetailedCompleteResponseItemCreatorTypeEnum {
  User = 'User',
  Group = 'Group'
}

/**
 * The detailed model for catalog items.
 * @export
 * @interface RobloxCatalogApiCatalogSearchDetailedResponseItem
 */
export interface RobloxCatalogApiCatalogSearchDetailedResponseItem {
  /**
   * The Item Id.
   * @type {number}
   * @memberof RobloxCatalogApiCatalogSearchDetailedResponseItem
   */
  id?: number;
  /**
   * The {Roblox.Catalog.Api.CatalogSearchDetailedResponseItem.ItemType} item type.
   * @type {string}
   * @memberof RobloxCatalogApiCatalogSearchDetailedResponseItem
   */
  itemType?: RobloxCatalogApiCatalogSearchDetailedResponseItemItemTypeEnum;
  /**
   * The {Roblox.Platform.Assets.AssetType} serialized if item is an asset.
   * @type {string}
   * @memberof RobloxCatalogApiCatalogSearchDetailedResponseItem
   */
  assetType?: RobloxCatalogApiCatalogSearchDetailedResponseItemAssetTypeEnum;
  /**
   * The {Roblox.Platform.Bundles.Core.BundleType} serialized if item is a bundle.
   * @type {string}
   * @memberof RobloxCatalogApiCatalogSearchDetailedResponseItem
   */
  bundleType?: RobloxCatalogApiCatalogSearchDetailedResponseItemBundleTypeEnum;
  /**
   * The item name.
   * @type {string}
   * @memberof RobloxCatalogApiCatalogSearchDetailedResponseItem
   */
  name?: string;
  /**
   * The item description.
   * @type {string}
   * @memberof RobloxCatalogApiCatalogSearchDetailedResponseItem
   */
  description?: string;
  /**
   * The product id of corresponding item.
   * @type {number}
   * @memberof RobloxCatalogApiCatalogSearchDetailedResponseItem
   */
  productId?: number;
  /**
   * The {System.Collections.Generic.IEnumerable`1}, serialized if item has genres.
   * @type {Array<string>}
   * @memberof RobloxCatalogApiCatalogSearchDetailedResponseItem
   */
  genres?: Array<RobloxCatalogApiCatalogSearchDetailedResponseItemGenresEnum>;
  /**
   * The {System.Collections.Generic.IEnumerable`1} contained in the bundle, serialized if item is a bundle.
   * @type {Array<RobloxCatalogApiBundleItemDetailModel>}
   * @memberof RobloxCatalogApiCatalogSearchDetailedResponseItem
   */
  bundledItems?: Array<RobloxCatalogApiBundleItemDetailModel>;
  /**
   * The {System.Collections.Generic.IEnumerable`1} if item has {Roblox.Catalog.Api.CatalogItemStatus}.
   * @type {Array<string>}
   * @memberof RobloxCatalogApiCatalogSearchDetailedResponseItem
   */
  itemStatus?: Array<RobloxCatalogApiCatalogSearchDetailedResponseItemItemStatusEnum>;
  /**
   * The {System.Collections.Generic.IEnumerable`1} if item has {Roblox.Catalog.Api.CatalogItemRestriction}.
   * @type {Array<string>}
   * @memberof RobloxCatalogApiCatalogSearchDetailedResponseItem
   */
  itemRestrictions?: Array<RobloxCatalogApiCatalogSearchDetailedResponseItemItemRestrictionsEnum>;
  /**
   * The {Roblox.Catalog.Api.CatalogSearchDetailedResponseItem.CreatorType} of the item\'s creator.
   * @type {string}
   * @memberof RobloxCatalogApiCatalogSearchDetailedResponseItem
   */
  creatorType?: RobloxCatalogApiCatalogSearchDetailedResponseItemCreatorTypeEnum;
  /**
   * The creator id of the item\'s creator.
   * @type {number}
   * @memberof RobloxCatalogApiCatalogSearchDetailedResponseItem
   */
  creatorTargetId?: number;
  /**
   * The creator name of the item\'s creator.
   * @type {string}
   * @memberof RobloxCatalogApiCatalogSearchDetailedResponseItem
   */
  creatorName?: string;
  /**
   * The item\'s price.
   * @type {number}
   * @memberof RobloxCatalogApiCatalogSearchDetailedResponseItem
   */
  price?: number;
  /**
   *
   * @type {RobloxCatalogApiPremiumPricingModel}
   * @memberof RobloxCatalogApiCatalogSearchDetailedResponseItem
   */
  premiumPricing?: RobloxCatalogApiPremiumPricingModel;
  /**
   * The item\'s lowest price, only if the item is resellable and there are resellers.
   * @type {number}
   * @memberof RobloxCatalogApiCatalogSearchDetailedResponseItem
   */
  lowestPrice?: number;
  /**
   * The localized string item status if the item\'s price should not be displayed.
   * @type {string}
   * @memberof RobloxCatalogApiCatalogSearchDetailedResponseItem
   */
  priceStatus?: string;
  /**
   * The number of items in stock, only if the item is resellable and is limitedEdition
   * @type {number}
   * @memberof RobloxCatalogApiCatalogSearchDetailedResponseItem
   */
  unitsAvailableForConsumption?: number;
  /**
   * The number of times the item has been purchased.
   * @type {number}
   * @memberof RobloxCatalogApiCatalogSearchDetailedResponseItem
   */
  purchaseCount?: number;
  /**
   * The number of times the item has been favorited.
   * @type {number}
   * @memberof RobloxCatalogApiCatalogSearchDetailedResponseItem
   */
  favoriteCount?: number;
}

/**
 * @export
 * @enum {string}
 */
export enum RobloxCatalogApiCatalogSearchDetailedResponseItemItemTypeEnum {
  Asset = 'Asset',
  Bundle = 'Bundle'
}
/**
 * @export
 * @enum {string}
 */
export enum RobloxCatalogApiCatalogSearchDetailedResponseItemAssetTypeEnum {
  Image = 'Image',
  TShirt = 'TShirt',
  Audio = 'Audio',
  Mesh = 'Mesh',
  Lua = 'Lua',
  HTML = 'HTML',
  Text = 'Text',
  Hat = 'Hat',
  Place = 'Place',
  Model = 'Model',
  Shirt = 'Shirt',
  Pants = 'Pants',
  Decal = 'Decal',
  Avatar = 'Avatar',
  Head = 'Head',
  Face = 'Face',
  Gear = 'Gear',
  Badge = 'Badge',
  GroupEmblem = 'GroupEmblem',
  Animation = 'Animation',
  Arms = 'Arms',
  Legs = 'Legs',
  Torso = 'Torso',
  RightArm = 'RightArm',
  LeftArm = 'LeftArm',
  LeftLeg = 'LeftLeg',
  RightLeg = 'RightLeg',
  Package = 'Package',
  YouTubeVideo = 'YouTubeVideo',
  GamePass = 'GamePass',
  App = 'App',
  Code = 'Code',
  Plugin = 'Plugin',
  SolidModel = 'SolidModel',
  MeshPart = 'MeshPart',
  HairAccessory = 'HairAccessory',
  FaceAccessory = 'FaceAccessory',
  NeckAccessory = 'NeckAccessory',
  ShoulderAccessory = 'ShoulderAccessory',
  FrontAccessory = 'FrontAccessory',
  BackAccessory = 'BackAccessory',
  WaistAccessory = 'WaistAccessory',
  ClimbAnimation = 'ClimbAnimation',
  DeathAnimation = 'DeathAnimation',
  FallAnimation = 'FallAnimation',
  IdleAnimation = 'IdleAnimation',
  JumpAnimation = 'JumpAnimation',
  RunAnimation = 'RunAnimation',
  SwimAnimation = 'SwimAnimation',
  WalkAnimation = 'WalkAnimation',
  PoseAnimation = 'PoseAnimation',
  LocalizationTableManifest = 'LocalizationTableManifest',
  LocalizationTableTranslation = 'LocalizationTableTranslation',
  EmoteAnimation = 'EmoteAnimation',
  Video = 'Video',
  TexturePack = 'TexturePack'
}
/**
 * @export
 * @enum {string}
 */
export enum RobloxCatalogApiCatalogSearchDetailedResponseItemBundleTypeEnum {
  BodyParts = 'BodyParts',
  AvatarAnimations = 'AvatarAnimations'
}
/**
 * @export
 * @enum {string}
 */
export enum RobloxCatalogApiCatalogSearchDetailedResponseItemGenresEnum {
  All = 'All',
  Tutorial = 'Tutorial',
  Scary = 'Scary',
  TownAndCity = 'TownAndCity',
  War = 'War',
  Funny = 'Funny',
  Fantasy = 'Fantasy',
  Adventure = 'Adventure',
  SciFi = 'SciFi',
  Pirate = 'Pirate',
  FPS = 'FPS',
  RPG = 'RPG',
  Sports = 'Sports',
  Ninja = 'Ninja',
  WildWest = 'WildWest'
}
/**
 * @export
 * @enum {string}
 */
export enum RobloxCatalogApiCatalogSearchDetailedResponseItemItemStatusEnum {
  New = 'New',
  Sale = 'Sale',
  XboxExclusive = 'XboxExclusive',
  AmazonExclusive = 'AmazonExclusive',
  GooglePlayExclusive = 'GooglePlayExclusive',
  IosExclusive = 'IosExclusive',
  SaleTimer = 'SaleTimer'
}
/**
 * @export
 * @enum {string}
 */
export enum RobloxCatalogApiCatalogSearchDetailedResponseItemItemRestrictionsEnum {
  ThirteenPlus = 'ThirteenPlus',
  LimitedUnique = 'LimitedUnique',
  Limited = 'Limited',
  BuildersClub = 'BuildersClub',
  TurboBuildersClub = 'TurboBuildersClub',
  OutrageousBuildersClub = 'OutrageousBuildersClub',
  Rthro = 'Rthro'
}
/**
 * @export
 * @enum {string}
 */
export enum RobloxCatalogApiCatalogSearchDetailedResponseItemCreatorTypeEnum {
  User = 'User',
  Group = 'Group'
}

/**
 * ApiPageResponse for catalog search
 * @export
 * @interface RobloxCatalogApiCatalogSearchPageResponseRobloxCatalogApiCatalogSearchDetailedResponseItem
 */
export interface RobloxCatalogApiCatalogSearchPageResponseRobloxCatalogApiCatalogSearchDetailedResponseItem {
  /**
   * Keyword used for search query
   * @type {string}
   * @memberof RobloxCatalogApiCatalogSearchPageResponseRobloxCatalogApiCatalogSearchDetailedResponseItem
   */
  keyword?: string;
  /**
   *
   * @type {string}
   * @memberof RobloxCatalogApiCatalogSearchPageResponseRobloxCatalogApiCatalogSearchDetailedResponseItem
   */
  previousPageCursor?: string;
  /**
   *
   * @type {string}
   * @memberof RobloxCatalogApiCatalogSearchPageResponseRobloxCatalogApiCatalogSearchDetailedResponseItem
   */
  nextPageCursor?: string;
  /**
   *
   * @type {Array<RobloxCatalogApiCatalogSearchDetailedResponseItem>}
   * @memberof RobloxCatalogApiCatalogSearchPageResponseRobloxCatalogApiCatalogSearchDetailedResponseItem
   */
  data?: Array<RobloxCatalogApiCatalogSearchDetailedResponseItem>;
}
/**
 * ApiPageResponse for catalog search
 * @export
 * @interface RobloxCatalogApiCatalogSearchPageResponseRobloxCatalogApiCatalogSearchResponseItem
 */
export interface RobloxCatalogApiCatalogSearchPageResponseRobloxCatalogApiCatalogSearchResponseItem {
  /**
   * Keyword used for search query
   * @type {string}
   * @memberof RobloxCatalogApiCatalogSearchPageResponseRobloxCatalogApiCatalogSearchResponseItem
   */
  keyword?: string;
  /**
   *
   * @type {string}
   * @memberof RobloxCatalogApiCatalogSearchPageResponseRobloxCatalogApiCatalogSearchResponseItem
   */
  previousPageCursor?: string;
  /**
   *
   * @type {string}
   * @memberof RobloxCatalogApiCatalogSearchPageResponseRobloxCatalogApiCatalogSearchResponseItem
   */
  nextPageCursor?: string;
  /**
   *
   * @type {Array<RobloxCatalogApiCatalogSearchResponseItem>}
   * @memberof RobloxCatalogApiCatalogSearchPageResponseRobloxCatalogApiCatalogSearchResponseItem
   */
  data?: Array<RobloxCatalogApiCatalogSearchResponseItem>;
}
/**
 *
 * @export
 * @interface RobloxCatalogApiCatalogSearchRequestModel
 */
export interface RobloxCatalogApiCatalogSearchRequestModel {
  /**
   *
   * @type {string}
   * @memberof RobloxCatalogApiCatalogSearchRequestModel
   */
  category?: RobloxCatalogApiCatalogSearchRequestModelCategoryEnum;
  /**
   *
   * @type {string}
   * @memberof RobloxCatalogApiCatalogSearchRequestModel
   */
  subcategory?: RobloxCatalogApiCatalogSearchRequestModelSubcategoryEnum;
  /**
   *
   * @type {string}
   * @memberof RobloxCatalogApiCatalogSearchRequestModel
   */
  sortAggregation?: RobloxCatalogApiCatalogSearchRequestModelSortAggregationEnum;
  /**
   *
   * @type {string}
   * @memberof RobloxCatalogApiCatalogSearchRequestModel
   */
  sortCurrency?: RobloxCatalogApiCatalogSearchRequestModelSortCurrencyEnum;
  /**
   *
   * @type {Array<string>}
   * @memberof RobloxCatalogApiCatalogSearchRequestModel
   */
  genres?: Array<RobloxCatalogApiCatalogSearchRequestModelGenresEnum>;
  /**
   *
   * @type {string}
   * @memberof RobloxCatalogApiCatalogSearchRequestModel
   */
  sortType?: RobloxCatalogApiCatalogSearchRequestModelSortTypeEnum;
  /**
   *
   * @type {string}
   * @memberof RobloxCatalogApiCatalogSearchRequestModel
   */
  creatorType?: RobloxCatalogApiCatalogSearchRequestModelCreatorTypeEnum;
  /**
   *
   * @type {number}
   * @memberof RobloxCatalogApiCatalogSearchRequestModel
   */
  creatorTargetId?: number;
  /**
   *
   * @type {string}
   * @memberof RobloxCatalogApiCatalogSearchRequestModel
   */
  creatorName?: string;
  /**
   *
   * @type {number}
   * @memberof RobloxCatalogApiCatalogSearchRequestModel
   */
  maxPrice?: number;
  /**
   *
   * @type {number}
   * @memberof RobloxCatalogApiCatalogSearchRequestModel
   */
  minPrice?: number;
  /**
   *
   * @type {string}
   * @memberof RobloxCatalogApiCatalogSearchRequestModel
   */
  keyword?: string;
  /**
   *
   * @type {boolean}
   * @memberof RobloxCatalogApiCatalogSearchRequestModel
   */
  includeNotForSale?: boolean;
  /**
   *
   * @type {Array<string>}
   * @memberof RobloxCatalogApiCatalogSearchRequestModel
   */
  tagNames?: Array<string>;
}

/**
 * @export
 * @enum {string}
 */
export enum RobloxCatalogApiCatalogSearchRequestModelCategoryEnum {
  Accessories = 'Accessories',
  All = 'All',
  AvatarAnimations = 'AvatarAnimations',
  BodyParts = 'BodyParts',
  Clothing = 'Clothing',
  Collectibles = 'Collectibles',
  Featured = 'Featured',
  Gear = 'Gear',
  CommunityCreations = 'CommunityCreations',
  Premium = 'Premium',
  Recommended = 'Recommended'
}
/**
 * @export
 * @enum {string}
 */
export enum RobloxCatalogApiCatalogSearchRequestModelSubcategoryEnum {
  Accessories = 'Accessories',
  All = 'All',
  AvatarAnimations = 'AvatarAnimations',
  BackAccessories = 'BackAccessories',
  BodyParts = 'BodyParts',
  Clothing = 'Clothing',
  Collectibles = 'Collectibles',
  FaceAccessories = 'FaceAccessories',
  Faces = 'Faces',
  Featured = 'Featured',
  FrontAccessories = 'FrontAccessories',
  Gear = 'Gear',
  HairAccessories = 'HairAccessories',
  Hats = 'Hats',
  Heads = 'Heads',
  NeckAccessories = 'NeckAccessories',
  Pants = 'Pants',
  Shirts = 'Shirts',
  ShoulderAccessories = 'ShoulderAccessories',
  Tshirts = 'Tshirts',
  WaistAccessories = 'WaistAccessories',
  Bundles = 'Bundles',
  AnimationBundles = 'AnimationBundles',
  EmoteAnimations = 'EmoteAnimations',
  CommunityCreations = 'CommunityCreations',
  Melee = 'Melee',
  Ranged = 'Ranged',
  Explosive = 'Explosive',
  PowerUp = 'PowerUp',
  Navigation = 'Navigation',
  Musical = 'Musical',
  Social = 'Social',
  Building = 'Building',
  Transport = 'Transport',
  Premium = 'Premium',
  Recommended = 'Recommended'
}
/**
 * @export
 * @enum {string}
 */
export enum RobloxCatalogApiCatalogSearchRequestModelSortAggregationEnum {
  Past12Hours = 'Past12Hours',
  PastDay = 'PastDay',
  Past3Days = 'Past3Days',
  PastWeek = 'PastWeek',
  PastMonth = 'PastMonth',
  AllTime = 'AllTime'
}
/**
 * @export
 * @enum {string}
 */
export enum RobloxCatalogApiCatalogSearchRequestModelSortCurrencyEnum {
  All = 'All',
  Robux = 'Robux',
  Tickets = 'Tickets',
  CustomRobux = 'CustomRobux',
  CustomTickets = 'CustomTickets',
  Free = 'Free'
}
/**
 * @export
 * @enum {string}
 */
export enum RobloxCatalogApiCatalogSearchRequestModelGenresEnum {
  TownAndCity = 'TownAndCity',
  Medieval = 'Medieval',
  SciFi = 'SciFi',
  Fighting = 'Fighting',
  Horror = 'Horror',
  Naval = 'Naval',
  Adventure = 'Adventure',
  Sports = 'Sports',
  Comedy = 'Comedy',
  Western = 'Western',
  Military = 'Military',
  Building = 'Building',
  Fps = 'Fps',
  Rpg = 'Rpg'
}
/**
 * @export
 * @enum {string}
 */
export enum RobloxCatalogApiCatalogSearchRequestModelSortTypeEnum {
  Relevance = 'Relevance',
  Favorited = 'Favorited',
  Sales = 'Sales',
  Updated = 'Updated',
  PriceAsc = 'PriceAsc',
  PriceDesc = 'PriceDesc'
}
/**
 * @export
 * @enum {string}
 */
export enum RobloxCatalogApiCatalogSearchRequestModelCreatorTypeEnum {
  User = 'User',
  Group = 'Group'
}

/**
 *
 * @export
 * @interface RobloxCatalogApiCatalogSearchResponseItem
 */
export interface RobloxCatalogApiCatalogSearchResponseItem {
  /**
   *
   * @type {number}
   * @memberof RobloxCatalogApiCatalogSearchResponseItem
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof RobloxCatalogApiCatalogSearchResponseItem
   */
  itemType?: RobloxCatalogApiCatalogSearchResponseItemItemTypeEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum RobloxCatalogApiCatalogSearchResponseItemItemTypeEnum {
  Asset = 'Asset',
  Bundle = 'Bundle'
}

/**
 *
 * @export
 * @interface RobloxCatalogApiCatalogSortModel
 */
export interface RobloxCatalogApiCatalogSortModel {
  /**
   * Name of the sort
   * @type {string}
   * @memberof RobloxCatalogApiCatalogSortModel
   */
  name?: string;
  /**
   * Display name of the sort
   * @type {string}
   * @memberof RobloxCatalogApiCatalogSortModel
   */
  displayName?: string;
  /**
   * Number of items to be displayed for the sort
   * @type {number}
   * @memberof RobloxCatalogApiCatalogSortModel
   */
  numberOfItems?: number;
  /**
   * Items in the sort
   * @type {Array<RobloxCatalogApiCatalogSearchResponseItem>}
   * @memberof RobloxCatalogApiCatalogSortModel
   */
  items?: Array<RobloxCatalogApiCatalogSearchResponseItem>;
}
/**
 *
 * @export
 * @interface RobloxCatalogApiCatalogSortsResponseModel
 */
export interface RobloxCatalogApiCatalogSortsResponseModel {
  /**
   * Response object representing all categories
   * @type {Array<RobloxCatalogApiCatalogSortModel>}
   * @memberof RobloxCatalogApiCatalogSortsResponseModel
   */
  sorts?: Array<RobloxCatalogApiCatalogSortModel>;
}
/**
 * Response model for category
 * @export
 * @interface RobloxCatalogApiCategoryModel
 */
export interface RobloxCatalogApiCategoryModel {
  /**
   * Category type
   * @type {string}
   * @memberof RobloxCatalogApiCategoryModel
   */
  category?: RobloxCatalogApiCategoryModelCategoryEnum;
  /**
   * Category id
   * @type {number}
   * @memberof RobloxCatalogApiCategoryModel
   */
  categoryId?: number;
  /**
   * Category name
   * @type {string}
   * @memberof RobloxCatalogApiCategoryModel
   */
  name?: string;
  /**
   * Category order index
   * @type {number}
   * @memberof RobloxCatalogApiCategoryModel
   */
  orderIndex?: number;
  /**
   * Subcategories under this category
   * @type {Array<RobloxCatalogApiSubcategoryModel>}
   * @memberof RobloxCatalogApiCategoryModel
   */
  subcategories?: Array<RobloxCatalogApiSubcategoryModel>;
  /**
   * Gets or sets whether the category is searchable in search bar
   * @type {boolean}
   * @memberof RobloxCatalogApiCategoryModel
   */
  isSearchable?: boolean;
}

/**
 * @export
 * @enum {string}
 */
export enum RobloxCatalogApiCategoryModelCategoryEnum {
  Accessories = 'Accessories',
  All = 'All',
  AvatarAnimations = 'AvatarAnimations',
  BodyParts = 'BodyParts',
  Clothing = 'Clothing',
  Collectibles = 'Collectibles',
  Featured = 'Featured',
  Gear = 'Gear',
  CommunityCreations = 'CommunityCreations',
  Premium = 'Premium',
  Recommended = 'Recommended'
}

/**
 *
 * @export
 * @interface RobloxCatalogApiCreatorContainerV2
 */
export interface RobloxCatalogApiCreatorContainerV2 {
  /**
   *
   * @type {number}
   * @memberof RobloxCatalogApiCreatorContainerV2
   */
  creatorId?: number;
  /**
   *
   * @type {string}
   * @memberof RobloxCatalogApiCreatorContainerV2
   */
  creatorType?: string;
  /**
   *
   * @type {string}
   * @memberof RobloxCatalogApiCreatorContainerV2
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof RobloxCatalogApiCreatorContainerV2
   */
  creatorProfileLink?: string;
}
/**
 * Response model for creator filter
 * @export
 * @interface RobloxCatalogApiCreatorFilterModel
 */
export interface RobloxCatalogApiCreatorFilterModel {
  /**
   * Creator user Id
   * @type {number}
   * @memberof RobloxCatalogApiCreatorFilterModel
   */
  userId?: number;
  /**
   * Creator name
   * @type {string}
   * @memberof RobloxCatalogApiCreatorFilterModel
   */
  name?: string;
  /**
   * Whether this filter is selected by default
   * @type {boolean}
   * @memberof RobloxCatalogApiCreatorFilterModel
   */
  isSelected?: boolean;
}
/**
 * Response model for genre
 * @export
 * @interface RobloxCatalogApiGenreModel
 */
export interface RobloxCatalogApiGenreModel {
  /**
   * Genre type
   * @type {string}
   * @memberof RobloxCatalogApiGenreModel
   */
  genre?: RobloxCatalogApiGenreModelGenreEnum;
  /**
   * Genre name
   * @type {string}
   * @memberof RobloxCatalogApiGenreModel
   */
  name?: string;
  /**
   * Whether this genre is selected by default
   * @type {boolean}
   * @memberof RobloxCatalogApiGenreModel
   */
  isSelected?: boolean;
}

/**
 * @export
 * @enum {string}
 */
export enum RobloxCatalogApiGenreModelGenreEnum {
  TownAndCity = 'TownAndCity',
  Medieval = 'Medieval',
  SciFi = 'SciFi',
  Fighting = 'Fighting',
  Horror = 'Horror',
  Naval = 'Naval',
  Adventure = 'Adventure',
  Sports = 'Sports',
  Comedy = 'Comedy',
  Western = 'Western',
  Military = 'Military',
  Building = 'Building',
  Fps = 'Fps',
  Rpg = 'Rpg'
}

/**
 *
 * @export
 * @interface RobloxCatalogApiItemContainerV2
 */
export interface RobloxCatalogApiItemContainerV2 {
  /**
   *
   * @type {number}
   * @memberof RobloxCatalogApiItemContainerV2
   */
  assetId?: number;
  /**
   *
   * @type {string}
   * @memberof RobloxCatalogApiItemContainerV2
   */
  name?: string;
  /**
   *
   * @type {number}
   * @memberof RobloxCatalogApiItemContainerV2
   */
  price?: number;
  /**
   *
   * @type {number}
   * @memberof RobloxCatalogApiItemContainerV2
   */
  premiumPrice?: number;
  /**
   *
   * @type {string}
   * @memberof RobloxCatalogApiItemContainerV2
   */
  absoluteUrl?: string;
  /**
   *
   * @type {string}
   * @memberof RobloxCatalogApiItemContainerV2
   */
  audioUrl?: string;
}
/**
 *
 * @export
 * @interface RobloxCatalogApiMultigetItemDetailsRequestItem
 */
export interface RobloxCatalogApiMultigetItemDetailsRequestItem {
  /**
   *
   * @type {string}
   * @memberof RobloxCatalogApiMultigetItemDetailsRequestItem
   */
  itemType?: RobloxCatalogApiMultigetItemDetailsRequestItemItemTypeEnum;
  /**
   *
   * @type {number}
   * @memberof RobloxCatalogApiMultigetItemDetailsRequestItem
   */
  id?: number;
}

/**
 * @export
 * @enum {string}
 */
export enum RobloxCatalogApiMultigetItemDetailsRequestItemItemTypeEnum {
  Asset = 'Asset',
  Bundle = 'Bundle'
}

/**
 *
 * @export
 * @interface RobloxCatalogApiMultigetItemDetailsRequestModel
 */
export interface RobloxCatalogApiMultigetItemDetailsRequestModel {
  /**
   *
   * @type {Array<RobloxCatalogApiMultigetItemDetailsRequestItem>}
   * @memberof RobloxCatalogApiMultigetItemDetailsRequestModel
   */
  items?: Array<RobloxCatalogApiMultigetItemDetailsRequestItem>;
}
/**
 * A model to represent owned bundles.
 * @export
 * @interface RobloxCatalogApiOwnedBundleModel
 */
export interface RobloxCatalogApiOwnedBundleModel {
  /**
   *
   * @type {number}
   * @memberof RobloxCatalogApiOwnedBundleModel
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof RobloxCatalogApiOwnedBundleModel
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof RobloxCatalogApiOwnedBundleModel
   */
  bundleType?: string;
  /**
   *
   * @type {RobloxCatalogApiBundleCreatorModel}
   * @memberof RobloxCatalogApiOwnedBundleModel
   */
  creator?: RobloxCatalogApiBundleCreatorModel;
}
/**
 * Defines the Premium pricing for a catalog item
 * @export
 * @interface RobloxCatalogApiPremiumPricingModel
 */
export interface RobloxCatalogApiPremiumPricingModel {
  /**
   * The Premium discount percentage for a catalog item
   * @type {number}
   * @memberof RobloxCatalogApiPremiumPricingModel
   */
  premiumDiscountPercentage?: number;
  /**
   * The Premium price for a catalog item
   * @type {number}
   * @memberof RobloxCatalogApiPremiumPricingModel
   */
  premiumPriceInRobux?: number;
}
/**
 * Response model for price filter
 * @export
 * @interface RobloxCatalogApiPriceFilterModel
 */
export interface RobloxCatalogApiPriceFilterModel {
  /**
   * Currency Type of this price filter
   * @type {string}
   * @memberof RobloxCatalogApiPriceFilterModel
   */
  currencyType?: RobloxCatalogApiPriceFilterModelCurrencyTypeEnum;
  /**
   * Price filter Name
   * @type {string}
   * @memberof RobloxCatalogApiPriceFilterModel
   */
  name?: string;
  /**
   * Whether if this price filter exclude price sorts
   * @type {boolean}
   * @memberof RobloxCatalogApiPriceFilterModel
   */
  excludePriceSorts?: boolean;
}

/**
 * @export
 * @enum {string}
 */
export enum RobloxCatalogApiPriceFilterModelCurrencyTypeEnum {
  All = 'All',
  Robux = 'Robux',
  Tickets = 'Tickets',
  CustomRobux = 'CustomRobux',
  CustomTickets = 'CustomTickets',
  Free = 'Free'
}

/**
 *
 * @export
 * @interface RobloxCatalogApiProductContainerV2
 */
export interface RobloxCatalogApiProductContainerV2 {
  /**
   *
   * @type {number}
   * @memberof RobloxCatalogApiProductContainerV2
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof RobloxCatalogApiProductContainerV2
   */
  priceInRobux?: number;
  /**
   *
   * @type {boolean}
   * @memberof RobloxCatalogApiProductContainerV2
   */
  isForSale?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof RobloxCatalogApiProductContainerV2
   */
  isPublicDomain?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof RobloxCatalogApiProductContainerV2
   */
  isResellable?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof RobloxCatalogApiProductContainerV2
   */
  isLimited?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof RobloxCatalogApiProductContainerV2
   */
  isLimitedUnique?: boolean;
  /**
   *
   * @type {number}
   * @memberof RobloxCatalogApiProductContainerV2
   */
  serialNumber?: number;
  /**
   *
   * @type {boolean}
   * @memberof RobloxCatalogApiProductContainerV2
   */
  isRental?: boolean;
  /**
   *
   * @type {number}
   * @memberof RobloxCatalogApiProductContainerV2
   */
  rentalDurationInHours?: number;
  /**
   *
   * @type {number}
   * @memberof RobloxCatalogApiProductContainerV2
   */
  bcRequirement?: number;
  /**
   *
   * @type {number}
   * @memberof RobloxCatalogApiProductContainerV2
   */
  totalPrivateSales?: number;
  /**
   *
   * @type {number}
   * @memberof RobloxCatalogApiProductContainerV2
   */
  sellerId?: number;
  /**
   *
   * @type {string}
   * @memberof RobloxCatalogApiProductContainerV2
   */
  sellerName?: string;
  /**
   *
   * @type {number}
   * @memberof RobloxCatalogApiProductContainerV2
   */
  lowestPrivateSaleUserAssetId?: number;
  /**
   *
   * @type {boolean}
   * @memberof RobloxCatalogApiProductContainerV2
   */
  isXboxExclusiveItem?: boolean;
  /**
   *
   * @type {string}
   * @memberof RobloxCatalogApiProductContainerV2
   */
  offsaleDeadline?: string;
  /**
   *
   * @type {string}
   * @memberof RobloxCatalogApiProductContainerV2
   */
  noPriceText?: string;
  /**
   *
   * @type {boolean}
   * @memberof RobloxCatalogApiProductContainerV2
   */
  isFree?: boolean;
}
/**
 *
 * @export
 * @interface RobloxCatalogApiRecommendationViewModelV2
 */
export interface RobloxCatalogApiRecommendationViewModelV2 {
  /**
   *
   * @type {RobloxCatalogApiItemContainerV2}
   * @memberof RobloxCatalogApiRecommendationViewModelV2
   */
  item?: RobloxCatalogApiItemContainerV2;
  /**
   *
   * @type {RobloxCatalogApiCreatorContainerV2}
   * @memberof RobloxCatalogApiRecommendationViewModelV2
   */
  creator?: RobloxCatalogApiCreatorContainerV2;
  /**
   *
   * @type {RobloxCatalogApiProductContainerV2}
   * @memberof RobloxCatalogApiRecommendationViewModelV2
   */
  product?: RobloxCatalogApiProductContainerV2;
}
/**
 * Recommendations Metadata Model
 * @export
 * @interface RobloxCatalogApiRecommendationsMetadataModel
 */
export interface RobloxCatalogApiRecommendationsMetadataModel {
  /**
   *
   * @type {number}
   * @memberof RobloxCatalogApiRecommendationsMetadataModel
   */
  numOfRecommendationsDisplayed?: number;
  /**
   *
   * @type {number}
   * @memberof RobloxCatalogApiRecommendationsMetadataModel
   */
  numOfRecommendationsRetrieved?: number;
  /**
   *
   * @type {boolean}
   * @memberof RobloxCatalogApiRecommendationsMetadataModel
   */
  isCarouselEnabled?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof RobloxCatalogApiRecommendationsMetadataModel
   */
  isCarouselTestingEnabled?: boolean;
  /**
   *
   * @type {number}
   * @memberof RobloxCatalogApiRecommendationsMetadataModel
   */
  carouselTestingVariation?: number;
  /**
   *
   * @type {boolean}
   * @memberof RobloxCatalogApiRecommendationsMetadataModel
   */
  isRecommendationFromCatalogApiEnabled?: boolean;
}
/**
 * Response model for sort aggregation
 * @export
 * @interface RobloxCatalogApiSortAggregationModel
 */
export interface RobloxCatalogApiSortAggregationModel {
  /**
   * Sort aggregation type
   * @type {string}
   * @memberof RobloxCatalogApiSortAggregationModel
   */
  sortAggregation?: RobloxCatalogApiSortAggregationModelSortAggregationEnum;
  /**
   * Sort aggregation name
   * @type {string}
   * @memberof RobloxCatalogApiSortAggregationModel
   */
  name?: string;
  /**
   * Whether this sort aggregation is selected by default
   * @type {boolean}
   * @memberof RobloxCatalogApiSortAggregationModel
   */
  isSelected?: boolean;
  /**
   * Whether this sort aggregation has sub menu
   * @type {boolean}
   * @memberof RobloxCatalogApiSortAggregationModel
   */
  hasSubMenu?: boolean;
  /**
   * Whether this sort aggregation is price related
   * @type {boolean}
   * @memberof RobloxCatalogApiSortAggregationModel
   */
  isPriceRelated?: boolean;
}

/**
 * @export
 * @enum {string}
 */
export enum RobloxCatalogApiSortAggregationModelSortAggregationEnum {
  Past12Hours = 'Past12Hours',
  PastDay = 'PastDay',
  Past3Days = 'Past3Days',
  PastWeek = 'PastWeek',
  PastMonth = 'PastMonth',
  AllTime = 'AllTime'
}

/**
 * Response model for sort menu
 * @export
 * @interface RobloxCatalogApiSortMenuModel
 */
export interface RobloxCatalogApiSortMenuModel {
  /**
   * List of sort options
   * @type {Array<RobloxCatalogApiSortOptionModel>}
   * @memberof RobloxCatalogApiSortMenuModel
   */
  sortOptions?: Array<RobloxCatalogApiSortOptionModel>;
  /**
   * List of sort aggregations
   * @type {Array<RobloxCatalogApiSortAggregationModel>}
   * @memberof RobloxCatalogApiSortMenuModel
   */
  sortAggregations?: Array<RobloxCatalogApiSortAggregationModel>;
}
/**
 * Response model for sort option
 * @export
 * @interface RobloxCatalogApiSortOptionModel
 */
export interface RobloxCatalogApiSortOptionModel {
  /**
   * Sort type
   * @type {string}
   * @memberof RobloxCatalogApiSortOptionModel
   */
  sortType?: RobloxCatalogApiSortOptionModelSortTypeEnum;
  /**
   * Descending or Ascending order of the sort
   * @type {string}
   * @memberof RobloxCatalogApiSortOptionModel
   */
  sortOrder?: RobloxCatalogApiSortOptionModelSortOrderEnum;
  /**
   * Sort option Name
   * @type {string}
   * @memberof RobloxCatalogApiSortOptionModel
   */
  name?: string;
  /**
   * Whether this sort option is selected by default
   * @type {boolean}
   * @memberof RobloxCatalogApiSortOptionModel
   */
  isSelected?: boolean;
  /**
   * Whether this sort option has sub menu
   * @type {boolean}
   * @memberof RobloxCatalogApiSortOptionModel
   */
  hasSubMenu?: boolean;
  /**
   * Whether this sort option is price related
   * @type {boolean}
   * @memberof RobloxCatalogApiSortOptionModel
   */
  isPriceRelated?: boolean;
}

/**
 * @export
 * @enum {string}
 */
export enum RobloxCatalogApiSortOptionModelSortTypeEnum {
  Relevance = 'Relevance',
  Favorited = 'Favorited',
  Sales = 'Sales',
  Updated = 'Updated',
  PriceAsc = 'PriceAsc',
  PriceDesc = 'PriceDesc'
}
/**
 * @export
 * @enum {string}
 */
export enum RobloxCatalogApiSortOptionModelSortOrderEnum {
  Asc = 'Asc',
  Desc = 'Desc'
}

/**
 * Response model for subcategory
 * @export
 * @interface RobloxCatalogApiSubcategoryModel
 */
export interface RobloxCatalogApiSubcategoryModel {
  /**
   * Subcategory type
   * @type {string}
   * @memberof RobloxCatalogApiSubcategoryModel
   */
  subcategory?: RobloxCatalogApiSubcategoryModelSubcategoryEnum;
  /**
   * Subcategory id
   * @type {number}
   * @memberof RobloxCatalogApiSubcategoryModel
   */
  subcategoryId?: number;
  /**
   * Subcategory name
   * @type {string}
   * @memberof RobloxCatalogApiSubcategoryModel
   */
  name?: string;
  /**
   * Subcategory short name
   * @type {string}
   * @memberof RobloxCatalogApiSubcategoryModel
   */
  shortName?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum RobloxCatalogApiSubcategoryModelSubcategoryEnum {
  Accessories = 'Accessories',
  All = 'All',
  AvatarAnimations = 'AvatarAnimations',
  BackAccessories = 'BackAccessories',
  BodyParts = 'BodyParts',
  Clothing = 'Clothing',
  Collectibles = 'Collectibles',
  FaceAccessories = 'FaceAccessories',
  Faces = 'Faces',
  Featured = 'Featured',
  FrontAccessories = 'FrontAccessories',
  Gear = 'Gear',
  HairAccessories = 'HairAccessories',
  Hats = 'Hats',
  Heads = 'Heads',
  NeckAccessories = 'NeckAccessories',
  Pants = 'Pants',
  Shirts = 'Shirts',
  ShoulderAccessories = 'ShoulderAccessories',
  Tshirts = 'Tshirts',
  WaistAccessories = 'WaistAccessories',
  Bundles = 'Bundles',
  AnimationBundles = 'AnimationBundles',
  EmoteAnimations = 'EmoteAnimations',
  CommunityCreations = 'CommunityCreations',
  Melee = 'Melee',
  Ranged = 'Ranged',
  Explosive = 'Explosive',
  PowerUp = 'PowerUp',
  Navigation = 'Navigation',
  Musical = 'Musical',
  Social = 'Social',
  Building = 'Building',
  Transport = 'Transport',
  Premium = 'Premium',
  Recommended = 'Recommended'
}

/**
 *
 * @export
 * @interface RobloxPagingExclusiveStartKeyCursorSystemNullableSystemInt64
 */
export interface RobloxPagingExclusiveStartKeyCursorSystemNullableSystemInt64 {
  /**
   *
   * @type {number}
   * @memberof RobloxPagingExclusiveStartKeyCursorSystemNullableSystemInt64
   */
  key?: number;
  /**
   *
   * @type {string}
   * @memberof RobloxPagingExclusiveStartKeyCursorSystemNullableSystemInt64
   */
  sortOrder?: RobloxPagingExclusiveStartKeyCursorSystemNullableSystemInt64SortOrderEnum;
  /**
   *
   * @type {string}
   * @memberof RobloxPagingExclusiveStartKeyCursorSystemNullableSystemInt64
   */
  pagingDirection?: RobloxPagingExclusiveStartKeyCursorSystemNullableSystemInt64PagingDirectionEnum;
  /**
   *
   * @type {number}
   * @memberof RobloxPagingExclusiveStartKeyCursorSystemNullableSystemInt64
   */
  pageNumber?: number;
  /**
   *
   * @type {string}
   * @memberof RobloxPagingExclusiveStartKeyCursorSystemNullableSystemInt64
   */
  discriminator?: string;
  /**
   *
   * @type {number}
   * @memberof RobloxPagingExclusiveStartKeyCursorSystemNullableSystemInt64
   */
  count?: number;
}

/**
 * @export
 * @enum {string}
 */
export enum RobloxPagingExclusiveStartKeyCursorSystemNullableSystemInt64SortOrderEnum {
  Asc = 'Asc',
  Desc = 'Desc'
}
/**
 * @export
 * @enum {string}
 */
export enum RobloxPagingExclusiveStartKeyCursorSystemNullableSystemInt64PagingDirectionEnum {
  Forward = 'Forward',
  Backward = 'Backward'
}

/**
 *
 * @export
 * @interface RobloxPlatformCoreExclusiveStartPagingIExclusiveStartKeyInfoRobloxPlatformBundlesCoreIBundleInstance
 */
export interface RobloxPlatformCoreExclusiveStartPagingIExclusiveStartKeyInfoRobloxPlatformBundlesCoreIBundleInstance {
  /**
   *
   * @type {string}
   * @memberof RobloxPlatformCoreExclusiveStartPagingIExclusiveStartKeyInfoRobloxPlatformBundlesCoreIBundleInstance
   */
  SortOrder?: RobloxPlatformCoreExclusiveStartPagingIExclusiveStartKeyInfoRobloxPlatformBundlesCoreIBundleInstanceSortOrderEnum;
  /**
   *
   * @type {string}
   * @memberof RobloxPlatformCoreExclusiveStartPagingIExclusiveStartKeyInfoRobloxPlatformBundlesCoreIBundleInstance
   */
  PagingDirection?: RobloxPlatformCoreExclusiveStartPagingIExclusiveStartKeyInfoRobloxPlatformBundlesCoreIBundleInstancePagingDirectionEnum;
  /**
   *
   * @type {number}
   * @memberof RobloxPlatformCoreExclusiveStartPagingIExclusiveStartKeyInfoRobloxPlatformBundlesCoreIBundleInstance
   */
  Count?: number;
}

/**
 * @export
 * @enum {string}
 */
export enum RobloxPlatformCoreExclusiveStartPagingIExclusiveStartKeyInfoRobloxPlatformBundlesCoreIBundleInstanceSortOrderEnum {
  Asc = 'Asc',
  Desc = 'Desc'
}
/**
 * @export
 * @enum {string}
 */
export enum RobloxPlatformCoreExclusiveStartPagingIExclusiveStartKeyInfoRobloxPlatformBundlesCoreIBundleInstancePagingDirectionEnum {
  Forward = 'Forward',
  Backward = 'Backward'
}

/**
 *
 * @export
 * @interface RobloxPlatformCoreExclusiveStartPagingIExclusiveStartKeyInfoSystemInt32
 */
export interface RobloxPlatformCoreExclusiveStartPagingIExclusiveStartKeyInfoSystemInt32 {
  /**
   *
   * @type {string}
   * @memberof RobloxPlatformCoreExclusiveStartPagingIExclusiveStartKeyInfoSystemInt32
   */
  SortOrder?: RobloxPlatformCoreExclusiveStartPagingIExclusiveStartKeyInfoSystemInt32SortOrderEnum;
  /**
   *
   * @type {string}
   * @memberof RobloxPlatformCoreExclusiveStartPagingIExclusiveStartKeyInfoSystemInt32
   */
  PagingDirection?: RobloxPlatformCoreExclusiveStartPagingIExclusiveStartKeyInfoSystemInt32PagingDirectionEnum;
  /**
   *
   * @type {number}
   * @memberof RobloxPlatformCoreExclusiveStartPagingIExclusiveStartKeyInfoSystemInt32
   */
  Count?: number;
}

/**
 * @export
 * @enum {string}
 */
export enum RobloxPlatformCoreExclusiveStartPagingIExclusiveStartKeyInfoSystemInt32SortOrderEnum {
  Asc = 'Asc',
  Desc = 'Desc'
}
/**
 * @export
 * @enum {string}
 */
export enum RobloxPlatformCoreExclusiveStartPagingIExclusiveStartKeyInfoSystemInt32PagingDirectionEnum {
  Forward = 'Forward',
  Backward = 'Backward'
}

/**
 *
 * @export
 * @interface RobloxPlatformCoreExclusiveStartPagingIExclusiveStartKeyInfoSystemInt64
 */
export interface RobloxPlatformCoreExclusiveStartPagingIExclusiveStartKeyInfoSystemInt64 {
  /**
   *
   * @type {string}
   * @memberof RobloxPlatformCoreExclusiveStartPagingIExclusiveStartKeyInfoSystemInt64
   */
  SortOrder?: RobloxPlatformCoreExclusiveStartPagingIExclusiveStartKeyInfoSystemInt64SortOrderEnum;
  /**
   *
   * @type {string}
   * @memberof RobloxPlatformCoreExclusiveStartPagingIExclusiveStartKeyInfoSystemInt64
   */
  PagingDirection?: RobloxPlatformCoreExclusiveStartPagingIExclusiveStartKeyInfoSystemInt64PagingDirectionEnum;
  /**
   *
   * @type {number}
   * @memberof RobloxPlatformCoreExclusiveStartPagingIExclusiveStartKeyInfoSystemInt64
   */
  Count?: number;
}

/**
 * @export
 * @enum {string}
 */
export enum RobloxPlatformCoreExclusiveStartPagingIExclusiveStartKeyInfoSystemInt64SortOrderEnum {
  Asc = 'Asc',
  Desc = 'Desc'
}
/**
 * @export
 * @enum {string}
 */
export enum RobloxPlatformCoreExclusiveStartPagingIExclusiveStartKeyInfoSystemInt64PagingDirectionEnum {
  Forward = 'Forward',
  Backward = 'Backward'
}

/**
 *
 * @export
 * @interface RobloxWebWebAPIExclusiveStartRequestRobloxPlatformBundlesCoreIBundleInstance
 */
export interface RobloxWebWebAPIExclusiveStartRequestRobloxPlatformBundlesCoreIBundleInstance {
  /**
   *
   * @type {RobloxPlatformCoreExclusiveStartPagingIExclusiveStartKeyInfoRobloxPlatformBundlesCoreIBundleInstance}
   * @memberof RobloxWebWebAPIExclusiveStartRequestRobloxPlatformBundlesCoreIBundleInstance
   */
  ExclusiveStartKeyInfo?: RobloxPlatformCoreExclusiveStartPagingIExclusiveStartKeyInfoRobloxPlatformBundlesCoreIBundleInstance;
  /**
   *
   * @type {string}
   * @memberof RobloxWebWebAPIExclusiveStartRequestRobloxPlatformBundlesCoreIBundleInstance
   */
  CursorRecipe?: string;
}
/**
 *
 * @export
 * @interface RobloxWebWebAPIExclusiveStartRequestSystemInt32
 */
export interface RobloxWebWebAPIExclusiveStartRequestSystemInt32 {
  /**
   *
   * @type {RobloxPlatformCoreExclusiveStartPagingIExclusiveStartKeyInfoSystemInt32}
   * @memberof RobloxWebWebAPIExclusiveStartRequestSystemInt32
   */
  ExclusiveStartKeyInfo?: RobloxPlatformCoreExclusiveStartPagingIExclusiveStartKeyInfoSystemInt32;
  /**
   *
   * @type {string}
   * @memberof RobloxWebWebAPIExclusiveStartRequestSystemInt32
   */
  CursorRecipe?: string;
}
/**
 *
 * @export
 * @interface RobloxWebWebAPIExclusiveStartRequestSystemInt64
 */
export interface RobloxWebWebAPIExclusiveStartRequestSystemInt64 {
  /**
   *
   * @type {RobloxPlatformCoreExclusiveStartPagingIExclusiveStartKeyInfoSystemInt64}
   * @memberof RobloxWebWebAPIExclusiveStartRequestSystemInt64
   */
  ExclusiveStartKeyInfo?: RobloxPlatformCoreExclusiveStartPagingIExclusiveStartKeyInfoSystemInt64;
  /**
   *
   * @type {string}
   * @memberof RobloxWebWebAPIExclusiveStartRequestSystemInt64
   */
  CursorRecipe?: string;
}
/**
 *
 * @export
 * @interface RobloxWebWebAPIModelsApiArrayResponseRobloxCatalogApiBundleDetailsModel
 */
export interface RobloxWebWebAPIModelsApiArrayResponseRobloxCatalogApiBundleDetailsModel {
  /**
   *
   * @type {Array<RobloxCatalogApiBundleDetailsModel>}
   * @memberof RobloxWebWebAPIModelsApiArrayResponseRobloxCatalogApiBundleDetailsModel
   */
  data?: Array<RobloxCatalogApiBundleDetailsModel>;
}
/**
 *
 * @export
 * @interface RobloxWebWebAPIModelsApiArrayResponseRobloxCatalogApiCatalogSearchDetailedResponseItem
 */
export interface RobloxWebWebAPIModelsApiArrayResponseRobloxCatalogApiCatalogSearchDetailedResponseItem {
  /**
   *
   * @type {Array<RobloxCatalogApiCatalogSearchDetailedResponseItem>}
   * @memberof RobloxWebWebAPIModelsApiArrayResponseRobloxCatalogApiCatalogSearchDetailedResponseItem
   */
  data?: Array<RobloxCatalogApiCatalogSearchDetailedResponseItem>;
}
/**
 *
 * @export
 * @interface RobloxWebWebAPIModelsApiLegacyPageResponseRobloxCatalogApiCatalogItemModel
 */
export interface RobloxWebWebAPIModelsApiLegacyPageResponseRobloxCatalogApiCatalogItemModel {
  /**
   *
   * @type {Array<RobloxCatalogApiCatalogItemModel>}
   * @memberof RobloxWebWebAPIModelsApiLegacyPageResponseRobloxCatalogApiCatalogItemModel
   */
  data?: Array<RobloxCatalogApiCatalogItemModel>;
  /**
   *
   * @type {number}
   * @memberof RobloxWebWebAPIModelsApiLegacyPageResponseRobloxCatalogApiCatalogItemModel
   */
  total?: number;
}
/**
 *
 * @export
 * @interface RobloxWebWebAPIModelsApiLegacyPageResponseRobloxCatalogApiRecommendationViewModelV2
 */
export interface RobloxWebWebAPIModelsApiLegacyPageResponseRobloxCatalogApiRecommendationViewModelV2 {
  /**
   *
   * @type {Array<RobloxCatalogApiRecommendationViewModelV2>}
   * @memberof RobloxWebWebAPIModelsApiLegacyPageResponseRobloxCatalogApiRecommendationViewModelV2
   */
  data?: Array<RobloxCatalogApiRecommendationViewModelV2>;
  /**
   *
   * @type {number}
   * @memberof RobloxWebWebAPIModelsApiLegacyPageResponseRobloxCatalogApiRecommendationViewModelV2
   */
  total?: number;
}
/**
 *
 * @export
 * @interface RobloxWebWebAPIModelsApiPageResponseRobloxCatalogApiBundleDetailsModel
 */
export interface RobloxWebWebAPIModelsApiPageResponseRobloxCatalogApiBundleDetailsModel {
  /**
   *
   * @type {string}
   * @memberof RobloxWebWebAPIModelsApiPageResponseRobloxCatalogApiBundleDetailsModel
   */
  previousPageCursor?: string;
  /**
   *
   * @type {string}
   * @memberof RobloxWebWebAPIModelsApiPageResponseRobloxCatalogApiBundleDetailsModel
   */
  nextPageCursor?: string;
  /**
   *
   * @type {Array<RobloxCatalogApiBundleDetailsModel>}
   * @memberof RobloxWebWebAPIModelsApiPageResponseRobloxCatalogApiBundleDetailsModel
   */
  data?: Array<RobloxCatalogApiBundleDetailsModel>;
}
/**
 *
 * @export
 * @interface RobloxWebWebAPIModelsApiPageResponseRobloxCatalogApiOwnedBundleModel
 */
export interface RobloxWebWebAPIModelsApiPageResponseRobloxCatalogApiOwnedBundleModel {
  /**
   *
   * @type {string}
   * @memberof RobloxWebWebAPIModelsApiPageResponseRobloxCatalogApiOwnedBundleModel
   */
  previousPageCursor?: string;
  /**
   *
   * @type {string}
   * @memberof RobloxWebWebAPIModelsApiPageResponseRobloxCatalogApiOwnedBundleModel
   */
  nextPageCursor?: string;
  /**
   *
   * @type {Array<RobloxCatalogApiOwnedBundleModel>}
   * @memberof RobloxWebWebAPIModelsApiPageResponseRobloxCatalogApiOwnedBundleModel
   */
  data?: Array<RobloxCatalogApiOwnedBundleModel>;
}

/**
 * BundleApi - axios parameter creator
 * @export
 */
export const BundleApiAxiosParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary Lists the bundles a particular asset belongs to. Use the Id of the last bundle in the response to get the next page.
     * @param {number} assetId
     * @param {'Asc' | 'Desc'} [sortOrder] The order the results are sorted in.
     * @param {10 | 25 | 50 | 100} [limit] The amount of results per request.
     * @param {string} [cursor] The paging cursor for the previous or next page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1AssetsAssetIdBundlesGet: async (
      assetId: number,
      sortOrder?: 'Asc' | 'Desc',
      limit?: 10 | 25 | 50 | 100,
      cursor?: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'assetId' is not null or undefined
      if (assetId === null || assetId === undefined) {
        throw new RequiredError(
          'assetId',
          'Required parameter assetId was null or undefined when calling v1AssetsAssetIdBundlesGet.'
        );
      }
      const localVarPath = `/v1/assets/{assetId}/bundles`.replace(
        `{${'assetId'}}`,
        encodeURIComponent(String(assetId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (sortOrder !== undefined) {
        localVarQueryParameter.sortOrder = sortOrder;
      }

      if (limit !== undefined) {
        localVarQueryParameter.limit = limit;
      }

      if (cursor !== undefined) {
        localVarQueryParameter.cursor = cursor;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Returns details about the given bundleId.
     * @param {number} bundleId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1BundlesBundleIdDetailsGet: async (
      bundleId: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'bundleId' is not null or undefined
      if (bundleId === null || bundleId === undefined) {
        throw new RequiredError(
          'bundleId',
          'Required parameter bundleId was null or undefined when calling v1BundlesBundleIdDetailsGet.'
        );
      }
      const localVarPath = `/v1/bundles/{bundleId}/details`.replace(
        `{${'bundleId'}}`,
        encodeURIComponent(String(bundleId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Gets recommendations for a given bundle, bundleId of 0 returns randomized bundles  - Accepts both public and authenticated users
     * @param {number} bundleId
     * @param {number} [numItems] The number of recommended items to return.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1BundlesBundleIdRecommendationsGet: async (
      bundleId: number,
      numItems?: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'bundleId' is not null or undefined
      if (bundleId === null || bundleId === undefined) {
        throw new RequiredError(
          'bundleId',
          'Required parameter bundleId was null or undefined when calling v1BundlesBundleIdRecommendationsGet.'
        );
      }
      const localVarPath = `/v1/bundles/{bundleId}/recommendations`.replace(
        `{${'bundleId'}}`,
        encodeURIComponent(String(bundleId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (numItems !== undefined) {
        localVarQueryParameter.numItems = numItems;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Unpacks a bundle and grants all of the associated items.  It may take a few seconds for all items to be granted after the request finishes.
     * @param {number} bundleId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1BundlesBundleIdUnpackPost: async (
      bundleId: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'bundleId' is not null or undefined
      if (bundleId === null || bundleId === undefined) {
        throw new RequiredError(
          'bundleId',
          'Required parameter bundleId was null or undefined when calling v1BundlesBundleIdUnpackPost.'
        );
      }
      const localVarPath = `/v1/bundles/{bundleId}/unpack`.replace(
        `{${'bundleId'}}`,
        encodeURIComponent(String(bundleId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Returns details about the given bundleIds.
     * @param {Array<number>} bundleIds
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1BundlesDetailsGet: async (
      bundleIds: Array<number>,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'bundleIds' is not null or undefined
      if (bundleIds === null || bundleIds === undefined) {
        throw new RequiredError(
          'bundleIds',
          'Required parameter bundleIds was null or undefined when calling v1BundlesDetailsGet.'
        );
      }
      const localVarPath = `/v1/bundles/details`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (bundleIds) {
        localVarQueryParameter.bundleIds = bundleIds.join(COLLECTION_FORMATS.csv);
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {number} userId
     * @param {'BodyParts' | 'AvatarAnimations'} bundleType
     * @param {10 | 25 | 50 | 100} [limit] The amount of results per request.
     * @param {string} [cursor] The paging cursor for the previous or next page.
     * @param {'Asc' | 'Desc'} [sortOrder] The order the results are sorted in.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1UsersUserIdBundlesBundleTypeGet: async (
      userId: number,
      bundleType: 'BodyParts' | 'AvatarAnimations',
      limit?: 10 | 25 | 50 | 100,
      cursor?: string,
      sortOrder?: 'Asc' | 'Desc',
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      if (userId === null || userId === undefined) {
        throw new RequiredError(
          'userId',
          'Required parameter userId was null or undefined when calling v1UsersUserIdBundlesBundleTypeGet.'
        );
      }
      // verify required parameter 'bundleType' is not null or undefined
      if (bundleType === null || bundleType === undefined) {
        throw new RequiredError(
          'bundleType',
          'Required parameter bundleType was null or undefined when calling v1UsersUserIdBundlesBundleTypeGet.'
        );
      }
      const localVarPath = `/v1/users/{userId}/bundles/{bundleType}`
        .replace(`{${'userId'}}`, encodeURIComponent(String(userId)))
        .replace(`{${'bundleType'}}`, encodeURIComponent(String(bundleType)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (limit !== undefined) {
        localVarQueryParameter.limit = limit;
      }

      if (cursor !== undefined) {
        localVarQueryParameter.cursor = cursor;
      }

      if (sortOrder !== undefined) {
        localVarQueryParameter.sortOrder = sortOrder;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Lists the bundles owned by a given user.
     * @param {number} userId
     * @param {'Asc' | 'Desc'} [sortOrder] Sorted by bundle
     * @param {10 | 25 | 50 | 100} [limit] The amount of results per request.
     * @param {string} [cursor] The paging cursor for the previous or next page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1UsersUserIdBundlesGet: async (
      userId: number,
      sortOrder?: 'Asc' | 'Desc',
      limit?: 10 | 25 | 50 | 100,
      cursor?: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      if (userId === null || userId === undefined) {
        throw new RequiredError(
          'userId',
          'Required parameter userId was null or undefined when calling v1UsersUserIdBundlesGet.'
        );
      }
      const localVarPath = `/v1/users/{userId}/bundles`.replace(
        `{${'userId'}}`,
        encodeURIComponent(String(userId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (sortOrder !== undefined) {
        localVarQueryParameter.sortOrder = sortOrder;
      }

      if (limit !== undefined) {
        localVarQueryParameter.limit = limit;
      }

      if (cursor !== undefined) {
        localVarQueryParameter.cursor = cursor;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * BundleApi - functional programming interface
 * @export
 */
export const BundleApiFp = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary Lists the bundles a particular asset belongs to. Use the Id of the last bundle in the response to get the next page.
     * @param {number} assetId
     * @param {'Asc' | 'Desc'} [sortOrder] The order the results are sorted in.
     * @param {10 | 25 | 50 | 100} [limit] The amount of results per request.
     * @param {string} [cursor] The paging cursor for the previous or next page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v1AssetsAssetIdBundlesGet(
      assetId: number,
      sortOrder?: 'Asc' | 'Desc',
      limit?: 10 | 25 | 50 | 100,
      cursor?: string,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<RobloxWebWebAPIModelsApiPageResponseRobloxCatalogApiBundleDetailsModel>
    > {
      const localVarAxiosArgs = await BundleApiAxiosParamCreator(
        configuration
      ).v1AssetsAssetIdBundlesGet(assetId, sortOrder, limit, cursor, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Returns details about the given bundleId.
     * @param {number} bundleId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v1BundlesBundleIdDetailsGet(
      bundleId: number,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<RobloxCatalogApiBundleDetailsModel>
    > {
      const localVarAxiosArgs = await BundleApiAxiosParamCreator(
        configuration
      ).v1BundlesBundleIdDetailsGet(bundleId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Gets recommendations for a given bundle, bundleId of 0 returns randomized bundles  - Accepts both public and authenticated users
     * @param {number} bundleId
     * @param {number} [numItems] The number of recommended items to return.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v1BundlesBundleIdRecommendationsGet(
      bundleId: number,
      numItems?: number,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<RobloxWebWebAPIModelsApiArrayResponseRobloxCatalogApiBundleDetailsModel>
    > {
      const localVarAxiosArgs = await BundleApiAxiosParamCreator(
        configuration
      ).v1BundlesBundleIdRecommendationsGet(bundleId, numItems, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Unpacks a bundle and grants all of the associated items.  It may take a few seconds for all items to be granted after the request finishes.
     * @param {number} bundleId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v1BundlesBundleIdUnpackPost(
      bundleId: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await BundleApiAxiosParamCreator(
        configuration
      ).v1BundlesBundleIdUnpackPost(bundleId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Returns details about the given bundleIds.
     * @param {Array<number>} bundleIds
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v1BundlesDetailsGet(
      bundleIds: Array<number>,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<RobloxCatalogApiBundleDetailsModel>>
    > {
      const localVarAxiosArgs = await BundleApiAxiosParamCreator(configuration).v1BundlesDetailsGet(
        bundleIds,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} userId
     * @param {'BodyParts' | 'AvatarAnimations'} bundleType
     * @param {10 | 25 | 50 | 100} [limit] The amount of results per request.
     * @param {string} [cursor] The paging cursor for the previous or next page.
     * @param {'Asc' | 'Desc'} [sortOrder] The order the results are sorted in.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v1UsersUserIdBundlesBundleTypeGet(
      userId: number,
      bundleType: 'BodyParts' | 'AvatarAnimations',
      limit?: 10 | 25 | 50 | 100,
      cursor?: string,
      sortOrder?: 'Asc' | 'Desc',
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<RobloxWebWebAPIModelsApiPageResponseRobloxCatalogApiOwnedBundleModel>
    > {
      const localVarAxiosArgs = await BundleApiAxiosParamCreator(
        configuration
      ).v1UsersUserIdBundlesBundleTypeGet(userId, bundleType, limit, cursor, sortOrder, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Lists the bundles owned by a given user.
     * @param {number} userId
     * @param {'Asc' | 'Desc'} [sortOrder] Sorted by bundle
     * @param {10 | 25 | 50 | 100} [limit] The amount of results per request.
     * @param {string} [cursor] The paging cursor for the previous or next page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v1UsersUserIdBundlesGet(
      userId: number,
      sortOrder?: 'Asc' | 'Desc',
      limit?: 10 | 25 | 50 | 100,
      cursor?: string,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<RobloxWebWebAPIModelsApiPageResponseRobloxCatalogApiOwnedBundleModel>
    > {
      const localVarAxiosArgs = await BundleApiAxiosParamCreator(
        configuration
      ).v1UsersUserIdBundlesGet(userId, sortOrder, limit, cursor, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    }
  };
};

/**
 * BundleApi - factory interface
 * @export
 */
export const BundleApiFactory = function(
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @summary Lists the bundles a particular asset belongs to. Use the Id of the last bundle in the response to get the next page.
     * @param {number} assetId
     * @param {'Asc' | 'Desc'} [sortOrder] The order the results are sorted in.
     * @param {10 | 25 | 50 | 100} [limit] The amount of results per request.
     * @param {string} [cursor] The paging cursor for the previous or next page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1AssetsAssetIdBundlesGet(
      assetId: number,
      sortOrder?: 'Asc' | 'Desc',
      limit?: 10 | 25 | 50 | 100,
      cursor?: string,
      options?: any
    ): AxiosPromise<RobloxWebWebAPIModelsApiPageResponseRobloxCatalogApiBundleDetailsModel> {
      return BundleApiFp(configuration)
        .v1AssetsAssetIdBundlesGet(assetId, sortOrder, limit, cursor, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Returns details about the given bundleId.
     * @param {number} bundleId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1BundlesBundleIdDetailsGet(
      bundleId: number,
      options?: any
    ): AxiosPromise<RobloxCatalogApiBundleDetailsModel> {
      return BundleApiFp(configuration)
        .v1BundlesBundleIdDetailsGet(bundleId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Gets recommendations for a given bundle, bundleId of 0 returns randomized bundles  - Accepts both public and authenticated users
     * @param {number} bundleId
     * @param {number} [numItems] The number of recommended items to return.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1BundlesBundleIdRecommendationsGet(
      bundleId: number,
      numItems?: number,
      options?: any
    ): AxiosPromise<RobloxWebWebAPIModelsApiArrayResponseRobloxCatalogApiBundleDetailsModel> {
      return BundleApiFp(configuration)
        .v1BundlesBundleIdRecommendationsGet(bundleId, numItems, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Unpacks a bundle and grants all of the associated items.  It may take a few seconds for all items to be granted after the request finishes.
     * @param {number} bundleId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1BundlesBundleIdUnpackPost(bundleId: number, options?: any): AxiosPromise<void> {
      return BundleApiFp(configuration)
        .v1BundlesBundleIdUnpackPost(bundleId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Returns details about the given bundleIds.
     * @param {Array<number>} bundleIds
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1BundlesDetailsGet(
      bundleIds: Array<number>,
      options?: any
    ): AxiosPromise<Array<RobloxCatalogApiBundleDetailsModel>> {
      return BundleApiFp(configuration)
        .v1BundlesDetailsGet(bundleIds, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} userId
     * @param {'BodyParts' | 'AvatarAnimations'} bundleType
     * @param {10 | 25 | 50 | 100} [limit] The amount of results per request.
     * @param {string} [cursor] The paging cursor for the previous or next page.
     * @param {'Asc' | 'Desc'} [sortOrder] The order the results are sorted in.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1UsersUserIdBundlesBundleTypeGet(
      userId: number,
      bundleType: 'BodyParts' | 'AvatarAnimations',
      limit?: 10 | 25 | 50 | 100,
      cursor?: string,
      sortOrder?: 'Asc' | 'Desc',
      options?: any
    ): AxiosPromise<RobloxWebWebAPIModelsApiPageResponseRobloxCatalogApiOwnedBundleModel> {
      return BundleApiFp(configuration)
        .v1UsersUserIdBundlesBundleTypeGet(userId, bundleType, limit, cursor, sortOrder, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Lists the bundles owned by a given user.
     * @param {number} userId
     * @param {'Asc' | 'Desc'} [sortOrder] Sorted by bundle
     * @param {10 | 25 | 50 | 100} [limit] The amount of results per request.
     * @param {string} [cursor] The paging cursor for the previous or next page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1UsersUserIdBundlesGet(
      userId: number,
      sortOrder?: 'Asc' | 'Desc',
      limit?: 10 | 25 | 50 | 100,
      cursor?: string,
      options?: any
    ): AxiosPromise<RobloxWebWebAPIModelsApiPageResponseRobloxCatalogApiOwnedBundleModel> {
      return BundleApiFp(configuration)
        .v1UsersUserIdBundlesGet(userId, sortOrder, limit, cursor, options)
        .then(request => request(axios, basePath));
    }
  };
};

/**
 * BundleApi - interface
 * @export
 * @interface BundleApi
 */
export interface BundleApiInterface {
  /**
   *
   * @summary Lists the bundles a particular asset belongs to. Use the Id of the last bundle in the response to get the next page.
   * @param {number} assetId
   * @param {'Asc' | 'Desc'} [sortOrder] The order the results are sorted in.
   * @param {10 | 25 | 50 | 100} [limit] The amount of results per request.
   * @param {string} [cursor] The paging cursor for the previous or next page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BundleApiInterface
   */
  v1AssetsAssetIdBundlesGet(
    assetId: number,
    sortOrder?: 'Asc' | 'Desc',
    limit?: 10 | 25 | 50 | 100,
    cursor?: string,
    options?: any
  ): AxiosPromise<RobloxWebWebAPIModelsApiPageResponseRobloxCatalogApiBundleDetailsModel>;

  /**
   *
   * @summary Returns details about the given bundleId.
   * @param {number} bundleId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BundleApiInterface
   */
  v1BundlesBundleIdDetailsGet(
    bundleId: number,
    options?: any
  ): AxiosPromise<RobloxCatalogApiBundleDetailsModel>;

  /**
   *
   * @summary Gets recommendations for a given bundle, bundleId of 0 returns randomized bundles  - Accepts both public and authenticated users
   * @param {number} bundleId
   * @param {number} [numItems] The number of recommended items to return.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BundleApiInterface
   */
  v1BundlesBundleIdRecommendationsGet(
    bundleId: number,
    numItems?: number,
    options?: any
  ): AxiosPromise<RobloxWebWebAPIModelsApiArrayResponseRobloxCatalogApiBundleDetailsModel>;

  /**
   *
   * @summary Unpacks a bundle and grants all of the associated items.  It may take a few seconds for all items to be granted after the request finishes.
   * @param {number} bundleId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BundleApiInterface
   */
  v1BundlesBundleIdUnpackPost(bundleId: number, options?: any): AxiosPromise<void>;

  /**
   *
   * @summary Returns details about the given bundleIds.
   * @param {Array<number>} bundleIds
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BundleApiInterface
   */
  v1BundlesDetailsGet(
    bundleIds: Array<number>,
    options?: any
  ): AxiosPromise<Array<RobloxCatalogApiBundleDetailsModel>>;

  /**
   *
   * @param {number} userId
   * @param {'BodyParts' | 'AvatarAnimations'} bundleType
   * @param {10 | 25 | 50 | 100} [limit] The amount of results per request.
   * @param {string} [cursor] The paging cursor for the previous or next page.
   * @param {'Asc' | 'Desc'} [sortOrder] The order the results are sorted in.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BundleApiInterface
   */
  v1UsersUserIdBundlesBundleTypeGet(
    userId: number,
    bundleType: 'BodyParts' | 'AvatarAnimations',
    limit?: 10 | 25 | 50 | 100,
    cursor?: string,
    sortOrder?: 'Asc' | 'Desc',
    options?: any
  ): AxiosPromise<RobloxWebWebAPIModelsApiPageResponseRobloxCatalogApiOwnedBundleModel>;

  /**
   *
   * @summary Lists the bundles owned by a given user.
   * @param {number} userId
   * @param {'Asc' | 'Desc'} [sortOrder] Sorted by bundle
   * @param {10 | 25 | 50 | 100} [limit] The amount of results per request.
   * @param {string} [cursor] The paging cursor for the previous or next page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BundleApiInterface
   */
  v1UsersUserIdBundlesGet(
    userId: number,
    sortOrder?: 'Asc' | 'Desc',
    limit?: 10 | 25 | 50 | 100,
    cursor?: string,
    options?: any
  ): AxiosPromise<RobloxWebWebAPIModelsApiPageResponseRobloxCatalogApiOwnedBundleModel>;
}

/**
 * BundleApi - object-oriented interface
 * @export
 * @class BundleApi
 * @extends {BaseAPI}
 */
export class BundleApi extends BaseAPI implements BundleApiInterface {
  /**
   *
   * @summary Lists the bundles a particular asset belongs to. Use the Id of the last bundle in the response to get the next page.
   * @param {number} assetId
   * @param {'Asc' | 'Desc'} [sortOrder] The order the results are sorted in.
   * @param {10 | 25 | 50 | 100} [limit] The amount of results per request.
   * @param {string} [cursor] The paging cursor for the previous or next page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BundleApi
   */
  public v1AssetsAssetIdBundlesGet(
    assetId: number,
    sortOrder?: 'Asc' | 'Desc',
    limit?: 10 | 25 | 50 | 100,
    cursor?: string,
    options?: any
  ) {
    return BundleApiFp(this.configuration)
      .v1AssetsAssetIdBundlesGet(assetId, sortOrder, limit, cursor, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Returns details about the given bundleId.
   * @param {number} bundleId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BundleApi
   */
  public v1BundlesBundleIdDetailsGet(bundleId: number, options?: any) {
    return BundleApiFp(this.configuration)
      .v1BundlesBundleIdDetailsGet(bundleId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Gets recommendations for a given bundle, bundleId of 0 returns randomized bundles  - Accepts both public and authenticated users
   * @param {number} bundleId
   * @param {number} [numItems] The number of recommended items to return.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BundleApi
   */
  public v1BundlesBundleIdRecommendationsGet(bundleId: number, numItems?: number, options?: any) {
    return BundleApiFp(this.configuration)
      .v1BundlesBundleIdRecommendationsGet(bundleId, numItems, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Unpacks a bundle and grants all of the associated items.  It may take a few seconds for all items to be granted after the request finishes.
   * @param {number} bundleId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BundleApi
   */
  public v1BundlesBundleIdUnpackPost(bundleId: number, options?: any) {
    return BundleApiFp(this.configuration)
      .v1BundlesBundleIdUnpackPost(bundleId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Returns details about the given bundleIds.
   * @param {Array<number>} bundleIds
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BundleApi
   */
  public v1BundlesDetailsGet(bundleIds: Array<number>, options?: any) {
    return BundleApiFp(this.configuration)
      .v1BundlesDetailsGet(bundleIds, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} userId
   * @param {'BodyParts' | 'AvatarAnimations'} bundleType
   * @param {10 | 25 | 50 | 100} [limit] The amount of results per request.
   * @param {string} [cursor] The paging cursor for the previous or next page.
   * @param {'Asc' | 'Desc'} [sortOrder] The order the results are sorted in.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BundleApi
   */
  public v1UsersUserIdBundlesBundleTypeGet(
    userId: number,
    bundleType: 'BodyParts' | 'AvatarAnimations',
    limit?: 10 | 25 | 50 | 100,
    cursor?: string,
    sortOrder?: 'Asc' | 'Desc',
    options?: any
  ) {
    return BundleApiFp(this.configuration)
      .v1UsersUserIdBundlesBundleTypeGet(userId, bundleType, limit, cursor, sortOrder, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Lists the bundles owned by a given user.
   * @param {number} userId
   * @param {'Asc' | 'Desc'} [sortOrder] Sorted by bundle
   * @param {10 | 25 | 50 | 100} [limit] The amount of results per request.
   * @param {string} [cursor] The paging cursor for the previous or next page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BundleApi
   */
  public v1UsersUserIdBundlesGet(
    userId: number,
    sortOrder?: 'Asc' | 'Desc',
    limit?: 10 | 25 | 50 | 100,
    cursor?: string,
    options?: any
  ) {
    return BundleApiFp(this.configuration)
      .v1UsersUserIdBundlesGet(userId, sortOrder, limit, cursor, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * CatalogApi - axios parameter creator
 * @export
 */
export const CatalogApiAxiosParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary Returns list of item details
     * @param {RobloxCatalogApiMultigetItemDetailsRequestModel} model {Roblox.Catalog.Api.MultigetItemDetailsRequestModel}
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1CatalogItemsDetailsPost: async (
      model: RobloxCatalogApiMultigetItemDetailsRequestModel,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'model' is not null or undefined
      if (model === null || model === undefined) {
        throw new RequiredError(
          'model',
          'Required parameter model was null or undefined when calling v1CatalogItemsDetailsPost.'
        );
      }
      const localVarPath = `/v1/catalog/items/details`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      const needsSerialization =
        typeof model !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(model !== undefined ? model : {})
        : model || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Returns catalog item details, complete with user ownership and economy information
     * @param {number} itemId long, id of the asset or bundle
     * @param {string} itemType String, asset or bundle only
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1CatalogItemsItemIdDetailsGet: async (
      itemId: number,
      itemType: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'itemId' is not null or undefined
      if (itemId === null || itemId === undefined) {
        throw new RequiredError(
          'itemId',
          'Required parameter itemId was null or undefined when calling v1CatalogItemsItemIdDetailsGet.'
        );
      }
      // verify required parameter 'itemType' is not null or undefined
      if (itemType === null || itemType === undefined) {
        throw new RequiredError(
          'itemType',
          'Required parameter itemType was null or undefined when calling v1CatalogItemsItemIdDetailsGet.'
        );
      }
      const localVarPath = `/v1/catalog/items/{itemId}/details`.replace(
        `{${'itemId'}}`,
        encodeURIComponent(String(itemId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (itemType !== undefined) {
        localVarQueryParameter.itemType = itemType;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Return Metadata for Catalog Webpage
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1CatalogMetadataGet: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/v1/catalog/metadata`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Returns catalog sorts
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1CatalogSortsGet: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/v1/catalog/sorts`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * CatalogApi - functional programming interface
 * @export
 */
export const CatalogApiFp = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary Returns list of item details
     * @param {RobloxCatalogApiMultigetItemDetailsRequestModel} model {Roblox.Catalog.Api.MultigetItemDetailsRequestModel}
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v1CatalogItemsDetailsPost(
      model: RobloxCatalogApiMultigetItemDetailsRequestModel,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<
        RobloxWebWebAPIModelsApiArrayResponseRobloxCatalogApiCatalogSearchDetailedResponseItem
      >
    > {
      const localVarAxiosArgs = await CatalogApiAxiosParamCreator(
        configuration
      ).v1CatalogItemsDetailsPost(model, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Returns catalog item details, complete with user ownership and economy information
     * @param {number} itemId long, id of the asset or bundle
     * @param {string} itemType String, asset or bundle only
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v1CatalogItemsItemIdDetailsGet(
      itemId: number,
      itemType: string,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<RobloxCatalogApiCatalogSearchDetailedCompleteResponseItem>
    > {
      const localVarAxiosArgs = await CatalogApiAxiosParamCreator(
        configuration
      ).v1CatalogItemsItemIdDetailsGet(itemId, itemType, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Return Metadata for Catalog Webpage
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v1CatalogMetadataGet(
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<RobloxCatalogApiCatalogMetadataModel>
    > {
      const localVarAxiosArgs = await CatalogApiAxiosParamCreator(
        configuration
      ).v1CatalogMetadataGet(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Returns catalog sorts
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v1CatalogSortsGet(
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<RobloxCatalogApiCatalogSortsResponseModel>
    > {
      const localVarAxiosArgs = await CatalogApiAxiosParamCreator(configuration).v1CatalogSortsGet(
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    }
  };
};

/**
 * CatalogApi - factory interface
 * @export
 */
export const CatalogApiFactory = function(
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @summary Returns list of item details
     * @param {RobloxCatalogApiMultigetItemDetailsRequestModel} model {Roblox.Catalog.Api.MultigetItemDetailsRequestModel}
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1CatalogItemsDetailsPost(
      model: RobloxCatalogApiMultigetItemDetailsRequestModel,
      options?: any
    ): AxiosPromise<
      RobloxWebWebAPIModelsApiArrayResponseRobloxCatalogApiCatalogSearchDetailedResponseItem
    > {
      return CatalogApiFp(configuration)
        .v1CatalogItemsDetailsPost(model, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Returns catalog item details, complete with user ownership and economy information
     * @param {number} itemId long, id of the asset or bundle
     * @param {string} itemType String, asset or bundle only
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1CatalogItemsItemIdDetailsGet(
      itemId: number,
      itemType: string,
      options?: any
    ): AxiosPromise<RobloxCatalogApiCatalogSearchDetailedCompleteResponseItem> {
      return CatalogApiFp(configuration)
        .v1CatalogItemsItemIdDetailsGet(itemId, itemType, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Return Metadata for Catalog Webpage
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1CatalogMetadataGet(options?: any): AxiosPromise<RobloxCatalogApiCatalogMetadataModel> {
      return CatalogApiFp(configuration)
        .v1CatalogMetadataGet(options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Returns catalog sorts
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1CatalogSortsGet(options?: any): AxiosPromise<RobloxCatalogApiCatalogSortsResponseModel> {
      return CatalogApiFp(configuration)
        .v1CatalogSortsGet(options)
        .then(request => request(axios, basePath));
    }
  };
};

/**
 * CatalogApi - interface
 * @export
 * @interface CatalogApi
 */
export interface CatalogApiInterface {
  /**
   *
   * @summary Returns list of item details
   * @param {RobloxCatalogApiMultigetItemDetailsRequestModel} model {Roblox.Catalog.Api.MultigetItemDetailsRequestModel}
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CatalogApiInterface
   */
  v1CatalogItemsDetailsPost(
    model: RobloxCatalogApiMultigetItemDetailsRequestModel,
    options?: any
  ): AxiosPromise<
    RobloxWebWebAPIModelsApiArrayResponseRobloxCatalogApiCatalogSearchDetailedResponseItem
  >;

  /**
   *
   * @summary Returns catalog item details, complete with user ownership and economy information
   * @param {number} itemId long, id of the asset or bundle
   * @param {string} itemType String, asset or bundle only
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CatalogApiInterface
   */
  v1CatalogItemsItemIdDetailsGet(
    itemId: number,
    itemType: string,
    options?: any
  ): AxiosPromise<RobloxCatalogApiCatalogSearchDetailedCompleteResponseItem>;

  /**
   *
   * @summary Return Metadata for Catalog Webpage
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CatalogApiInterface
   */
  v1CatalogMetadataGet(options?: any): AxiosPromise<RobloxCatalogApiCatalogMetadataModel>;

  /**
   *
   * @summary Returns catalog sorts
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CatalogApiInterface
   */
  v1CatalogSortsGet(options?: any): AxiosPromise<RobloxCatalogApiCatalogSortsResponseModel>;
}

/**
 * CatalogApi - object-oriented interface
 * @export
 * @class CatalogApi
 * @extends {BaseAPI}
 */
export class CatalogApi extends BaseAPI implements CatalogApiInterface {
  /**
   *
   * @summary Returns list of item details
   * @param {RobloxCatalogApiMultigetItemDetailsRequestModel} model {Roblox.Catalog.Api.MultigetItemDetailsRequestModel}
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CatalogApi
   */
  public v1CatalogItemsDetailsPost(
    model: RobloxCatalogApiMultigetItemDetailsRequestModel,
    options?: any
  ) {
    return CatalogApiFp(this.configuration)
      .v1CatalogItemsDetailsPost(model, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Returns catalog item details, complete with user ownership and economy information
   * @param {number} itemId long, id of the asset or bundle
   * @param {string} itemType String, asset or bundle only
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CatalogApi
   */
  public v1CatalogItemsItemIdDetailsGet(itemId: number, itemType: string, options?: any) {
    return CatalogApiFp(this.configuration)
      .v1CatalogItemsItemIdDetailsGet(itemId, itemType, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Return Metadata for Catalog Webpage
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CatalogApi
   */
  public v1CatalogMetadataGet(options?: any) {
    return CatalogApiFp(this.configuration)
      .v1CatalogMetadataGet(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Returns catalog sorts
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CatalogApi
   */
  public v1CatalogSortsGet(options?: any) {
    return CatalogApiFp(this.configuration)
      .v1CatalogSortsGet(options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * CategoryApi - axios parameter creator
 * @export
 */
export const CategoryApiAxiosParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary Lists a mapping for assets to category IDs to convert from inventory ID to catalog ID. Creates a mapping to link \'Get More\' button in inventory page to the relevant catalog page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1AssetToCategoryGet: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/v1/asset-to-category`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Lists a mapping for assets to subcategory IDs to convert from inventory ID to catalog ID. Creates a mapping to link \'Get More\' button in inventory page to the relevant catalog page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1AssetToSubcategoryGet: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/v1/asset-to-subcategory`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Lists Category Names and their Ids
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1CategoriesGet: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/v1/categories`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Lists Subcategory Names and their Ids
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1SubcategoriesGet: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/v1/subcategories`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * CategoryApi - functional programming interface
 * @export
 */
export const CategoryApiFp = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary Lists a mapping for assets to category IDs to convert from inventory ID to catalog ID. Creates a mapping to link \'Get More\' button in inventory page to the relevant catalog page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v1AssetToCategoryGet(
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: number }>
    > {
      const localVarAxiosArgs = await CategoryApiAxiosParamCreator(
        configuration
      ).v1AssetToCategoryGet(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Lists a mapping for assets to subcategory IDs to convert from inventory ID to catalog ID. Creates a mapping to link \'Get More\' button in inventory page to the relevant catalog page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v1AssetToSubcategoryGet(
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: number }>
    > {
      const localVarAxiosArgs = await CategoryApiAxiosParamCreator(
        configuration
      ).v1AssetToSubcategoryGet(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Lists Category Names and their Ids
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v1CategoriesGet(
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: number }>
    > {
      const localVarAxiosArgs = await CategoryApiAxiosParamCreator(configuration).v1CategoriesGet(
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Lists Subcategory Names and their Ids
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v1SubcategoriesGet(
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: number }>
    > {
      const localVarAxiosArgs = await CategoryApiAxiosParamCreator(
        configuration
      ).v1SubcategoriesGet(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    }
  };
};

/**
 * CategoryApi - factory interface
 * @export
 */
export const CategoryApiFactory = function(
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @summary Lists a mapping for assets to category IDs to convert from inventory ID to catalog ID. Creates a mapping to link \'Get More\' button in inventory page to the relevant catalog page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1AssetToCategoryGet(options?: any): AxiosPromise<{ [key: string]: number }> {
      return CategoryApiFp(configuration)
        .v1AssetToCategoryGet(options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Lists a mapping for assets to subcategory IDs to convert from inventory ID to catalog ID. Creates a mapping to link \'Get More\' button in inventory page to the relevant catalog page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1AssetToSubcategoryGet(options?: any): AxiosPromise<{ [key: string]: number }> {
      return CategoryApiFp(configuration)
        .v1AssetToSubcategoryGet(options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Lists Category Names and their Ids
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1CategoriesGet(options?: any): AxiosPromise<{ [key: string]: number }> {
      return CategoryApiFp(configuration)
        .v1CategoriesGet(options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Lists Subcategory Names and their Ids
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1SubcategoriesGet(options?: any): AxiosPromise<{ [key: string]: number }> {
      return CategoryApiFp(configuration)
        .v1SubcategoriesGet(options)
        .then(request => request(axios, basePath));
    }
  };
};

/**
 * CategoryApi - interface
 * @export
 * @interface CategoryApi
 */
export interface CategoryApiInterface {
  /**
   *
   * @summary Lists a mapping for assets to category IDs to convert from inventory ID to catalog ID. Creates a mapping to link \'Get More\' button in inventory page to the relevant catalog page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CategoryApiInterface
   */
  v1AssetToCategoryGet(options?: any): AxiosPromise<{ [key: string]: number }>;

  /**
   *
   * @summary Lists a mapping for assets to subcategory IDs to convert from inventory ID to catalog ID. Creates a mapping to link \'Get More\' button in inventory page to the relevant catalog page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CategoryApiInterface
   */
  v1AssetToSubcategoryGet(options?: any): AxiosPromise<{ [key: string]: number }>;

  /**
   *
   * @summary Lists Category Names and their Ids
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CategoryApiInterface
   */
  v1CategoriesGet(options?: any): AxiosPromise<{ [key: string]: number }>;

  /**
   *
   * @summary Lists Subcategory Names and their Ids
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CategoryApiInterface
   */
  v1SubcategoriesGet(options?: any): AxiosPromise<{ [key: string]: number }>;
}

/**
 * CategoryApi - object-oriented interface
 * @export
 * @class CategoryApi
 * @extends {BaseAPI}
 */
export class CategoryApi extends BaseAPI implements CategoryApiInterface {
  /**
   *
   * @summary Lists a mapping for assets to category IDs to convert from inventory ID to catalog ID. Creates a mapping to link \'Get More\' button in inventory page to the relevant catalog page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CategoryApi
   */
  public v1AssetToCategoryGet(options?: any) {
    return CategoryApiFp(this.configuration)
      .v1AssetToCategoryGet(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Lists a mapping for assets to subcategory IDs to convert from inventory ID to catalog ID. Creates a mapping to link \'Get More\' button in inventory page to the relevant catalog page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CategoryApi
   */
  public v1AssetToSubcategoryGet(options?: any) {
    return CategoryApiFp(this.configuration)
      .v1AssetToSubcategoryGet(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Lists Category Names and their Ids
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CategoryApi
   */
  public v1CategoriesGet(options?: any) {
    return CategoryApiFp(this.configuration)
      .v1CategoriesGet(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Lists Subcategory Names and their Ids
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CategoryApi
   */
  public v1SubcategoriesGet(options?: any) {
    return CategoryApiFp(this.configuration)
      .v1SubcategoriesGet(options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * ExclusiveItemsApi - axios parameter creator
 * @export
 */
export const ExclusiveItemsApiAxiosParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary Lists the exclusive catalog items for a particular app store.
     * @param {'GooglePlay' | 'Amazon' | 'iOS' | 'Xbox'} appStoreType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1ExclusiveItemsAppStoreTypeBundlesGet: async (
      appStoreType: 'GooglePlay' | 'Amazon' | 'iOS' | 'Xbox',
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'appStoreType' is not null or undefined
      if (appStoreType === null || appStoreType === undefined) {
        throw new RequiredError(
          'appStoreType',
          'Required parameter appStoreType was null or undefined when calling v1ExclusiveItemsAppStoreTypeBundlesGet.'
        );
      }
      const localVarPath = `/v1/exclusive-items/{appStoreType}/bundles`.replace(
        `{${'appStoreType'}}`,
        encodeURIComponent(String(appStoreType))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * ExclusiveItemsApi - functional programming interface
 * @export
 */
export const ExclusiveItemsApiFp = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary Lists the exclusive catalog items for a particular app store.
     * @param {'GooglePlay' | 'Amazon' | 'iOS' | 'Xbox'} appStoreType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v1ExclusiveItemsAppStoreTypeBundlesGet(
      appStoreType: 'GooglePlay' | 'Amazon' | 'iOS' | 'Xbox',
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<RobloxWebWebAPIModelsApiLegacyPageResponseRobloxCatalogApiCatalogItemModel>
    > {
      const localVarAxiosArgs = await ExclusiveItemsApiAxiosParamCreator(
        configuration
      ).v1ExclusiveItemsAppStoreTypeBundlesGet(appStoreType, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    }
  };
};

/**
 * ExclusiveItemsApi - factory interface
 * @export
 */
export const ExclusiveItemsApiFactory = function(
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @summary Lists the exclusive catalog items for a particular app store.
     * @param {'GooglePlay' | 'Amazon' | 'iOS' | 'Xbox'} appStoreType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1ExclusiveItemsAppStoreTypeBundlesGet(
      appStoreType: 'GooglePlay' | 'Amazon' | 'iOS' | 'Xbox',
      options?: any
    ): AxiosPromise<RobloxWebWebAPIModelsApiLegacyPageResponseRobloxCatalogApiCatalogItemModel> {
      return ExclusiveItemsApiFp(configuration)
        .v1ExclusiveItemsAppStoreTypeBundlesGet(appStoreType, options)
        .then(request => request(axios, basePath));
    }
  };
};

/**
 * ExclusiveItemsApi - interface
 * @export
 * @interface ExclusiveItemsApi
 */
export interface ExclusiveItemsApiInterface {
  /**
   *
   * @summary Lists the exclusive catalog items for a particular app store.
   * @param {'GooglePlay' | 'Amazon' | 'iOS' | 'Xbox'} appStoreType
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExclusiveItemsApiInterface
   */
  v1ExclusiveItemsAppStoreTypeBundlesGet(
    appStoreType: 'GooglePlay' | 'Amazon' | 'iOS' | 'Xbox',
    options?: any
  ): AxiosPromise<RobloxWebWebAPIModelsApiLegacyPageResponseRobloxCatalogApiCatalogItemModel>;
}

/**
 * ExclusiveItemsApi - object-oriented interface
 * @export
 * @class ExclusiveItemsApi
 * @extends {BaseAPI}
 */
export class ExclusiveItemsApi extends BaseAPI implements ExclusiveItemsApiInterface {
  /**
   *
   * @summary Lists the exclusive catalog items for a particular app store.
   * @param {'GooglePlay' | 'Amazon' | 'iOS' | 'Xbox'} appStoreType
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExclusiveItemsApi
   */
  public v1ExclusiveItemsAppStoreTypeBundlesGet(
    appStoreType: 'GooglePlay' | 'Amazon' | 'iOS' | 'Xbox',
    options?: any
  ) {
    return ExclusiveItemsApiFp(this.configuration)
      .v1ExclusiveItemsAppStoreTypeBundlesGet(appStoreType, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * FavoritesApi - axios parameter creator
 * @export
 */
export const FavoritesApiAxiosParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary Gets the favorite count for the given asset Id.
     * @param {number} assetId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1FavoritesAssetsAssetIdCountGet: async (
      assetId: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'assetId' is not null or undefined
      if (assetId === null || assetId === undefined) {
        throw new RequiredError(
          'assetId',
          'Required parameter assetId was null or undefined when calling v1FavoritesAssetsAssetIdCountGet.'
        );
      }
      const localVarPath = `/v1/favorites/assets/{assetId}/count`.replace(
        `{${'assetId'}}`,
        encodeURIComponent(String(assetId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Gets the favorite count for the given bundle Id.
     * @param {number} bundleId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1FavoritesBundlesBundleIdCountGet: async (
      bundleId: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'bundleId' is not null or undefined
      if (bundleId === null || bundleId === undefined) {
        throw new RequiredError(
          'bundleId',
          'Required parameter bundleId was null or undefined when calling v1FavoritesBundlesBundleIdCountGet.'
        );
      }
      const localVarPath = `/v1/favorites/bundles/{bundleId}/count`.replace(
        `{${'bundleId'}}`,
        encodeURIComponent(String(bundleId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Delete a favorite for an asset by the authenticated user.
     * @param {number} userId
     * @param {number} assetId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1FavoritesUsersUserIdAssetsAssetIdFavoriteDelete: async (
      userId: number,
      assetId: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      if (userId === null || userId === undefined) {
        throw new RequiredError(
          'userId',
          'Required parameter userId was null or undefined when calling v1FavoritesUsersUserIdAssetsAssetIdFavoriteDelete.'
        );
      }
      // verify required parameter 'assetId' is not null or undefined
      if (assetId === null || assetId === undefined) {
        throw new RequiredError(
          'assetId',
          'Required parameter assetId was null or undefined when calling v1FavoritesUsersUserIdAssetsAssetIdFavoriteDelete.'
        );
      }
      const localVarPath = `/v1/favorites/users/{userId}/assets/{assetId}/favorite`
        .replace(`{${'userId'}}`, encodeURIComponent(String(userId)))
        .replace(`{${'assetId'}}`, encodeURIComponent(String(assetId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Gets the favorite model for the asset and user.
     * @param {number} userId
     * @param {number} assetId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1FavoritesUsersUserIdAssetsAssetIdFavoriteGet: async (
      userId: number,
      assetId: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      if (userId === null || userId === undefined) {
        throw new RequiredError(
          'userId',
          'Required parameter userId was null or undefined when calling v1FavoritesUsersUserIdAssetsAssetIdFavoriteGet.'
        );
      }
      // verify required parameter 'assetId' is not null or undefined
      if (assetId === null || assetId === undefined) {
        throw new RequiredError(
          'assetId',
          'Required parameter assetId was null or undefined when calling v1FavoritesUsersUserIdAssetsAssetIdFavoriteGet.'
        );
      }
      const localVarPath = `/v1/favorites/users/{userId}/assets/{assetId}/favorite`
        .replace(`{${'userId'}}`, encodeURIComponent(String(userId)))
        .replace(`{${'assetId'}}`, encodeURIComponent(String(assetId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Create a favorite for an asset by the authenticated user.
     * @param {number} userId
     * @param {number} assetId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1FavoritesUsersUserIdAssetsAssetIdFavoritePost: async (
      userId: number,
      assetId: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      if (userId === null || userId === undefined) {
        throw new RequiredError(
          'userId',
          'Required parameter userId was null or undefined when calling v1FavoritesUsersUserIdAssetsAssetIdFavoritePost.'
        );
      }
      // verify required parameter 'assetId' is not null or undefined
      if (assetId === null || assetId === undefined) {
        throw new RequiredError(
          'assetId',
          'Required parameter assetId was null or undefined when calling v1FavoritesUsersUserIdAssetsAssetIdFavoritePost.'
        );
      }
      const localVarPath = `/v1/favorites/users/{userId}/assets/{assetId}/favorite`
        .replace(`{${'userId'}}`, encodeURIComponent(String(userId)))
        .replace(`{${'assetId'}}`, encodeURIComponent(String(assetId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Delete favorite for the bundle by the authenticated user.
     * @param {number} userId
     * @param {number} bundleId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1FavoritesUsersUserIdBundlesBundleIdFavoriteDelete: async (
      userId: number,
      bundleId: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      if (userId === null || userId === undefined) {
        throw new RequiredError(
          'userId',
          'Required parameter userId was null or undefined when calling v1FavoritesUsersUserIdBundlesBundleIdFavoriteDelete.'
        );
      }
      // verify required parameter 'bundleId' is not null or undefined
      if (bundleId === null || bundleId === undefined) {
        throw new RequiredError(
          'bundleId',
          'Required parameter bundleId was null or undefined when calling v1FavoritesUsersUserIdBundlesBundleIdFavoriteDelete.'
        );
      }
      const localVarPath = `/v1/favorites/users/{userId}/bundles/{bundleId}/favorite`
        .replace(`{${'userId'}}`, encodeURIComponent(String(userId)))
        .replace(`{${'bundleId'}}`, encodeURIComponent(String(bundleId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Gets the favorite model for the bundle and user.
     * @param {number} userId
     * @param {number} bundleId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1FavoritesUsersUserIdBundlesBundleIdFavoriteGet: async (
      userId: number,
      bundleId: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      if (userId === null || userId === undefined) {
        throw new RequiredError(
          'userId',
          'Required parameter userId was null or undefined when calling v1FavoritesUsersUserIdBundlesBundleIdFavoriteGet.'
        );
      }
      // verify required parameter 'bundleId' is not null or undefined
      if (bundleId === null || bundleId === undefined) {
        throw new RequiredError(
          'bundleId',
          'Required parameter bundleId was null or undefined when calling v1FavoritesUsersUserIdBundlesBundleIdFavoriteGet.'
        );
      }
      const localVarPath = `/v1/favorites/users/{userId}/bundles/{bundleId}/favorite`
        .replace(`{${'userId'}}`, encodeURIComponent(String(userId)))
        .replace(`{${'bundleId'}}`, encodeURIComponent(String(bundleId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Create a favorite for the bundle by the authenticated user.
     * @param {number} userId
     * @param {number} bundleId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1FavoritesUsersUserIdBundlesBundleIdFavoritePost: async (
      userId: number,
      bundleId: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      if (userId === null || userId === undefined) {
        throw new RequiredError(
          'userId',
          'Required parameter userId was null or undefined when calling v1FavoritesUsersUserIdBundlesBundleIdFavoritePost.'
        );
      }
      // verify required parameter 'bundleId' is not null or undefined
      if (bundleId === null || bundleId === undefined) {
        throw new RequiredError(
          'bundleId',
          'Required parameter bundleId was null or undefined when calling v1FavoritesUsersUserIdBundlesBundleIdFavoritePost.'
        );
      }
      const localVarPath = `/v1/favorites/users/{userId}/bundles/{bundleId}/favorite`
        .replace(`{${'userId'}}`, encodeURIComponent(String(userId)))
        .replace(`{${'bundleId'}}`, encodeURIComponent(String(bundleId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * FavoritesApi - functional programming interface
 * @export
 */
export const FavoritesApiFp = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary Gets the favorite count for the given asset Id.
     * @param {number} assetId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v1FavoritesAssetsAssetIdCountGet(
      assetId: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
      const localVarAxiosArgs = await FavoritesApiAxiosParamCreator(
        configuration
      ).v1FavoritesAssetsAssetIdCountGet(assetId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Gets the favorite count for the given bundle Id.
     * @param {number} bundleId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v1FavoritesBundlesBundleIdCountGet(
      bundleId: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
      const localVarAxiosArgs = await FavoritesApiAxiosParamCreator(
        configuration
      ).v1FavoritesBundlesBundleIdCountGet(bundleId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Delete a favorite for an asset by the authenticated user.
     * @param {number} userId
     * @param {number} assetId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v1FavoritesUsersUserIdAssetsAssetIdFavoriteDelete(
      userId: number,
      assetId: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await FavoritesApiAxiosParamCreator(
        configuration
      ).v1FavoritesUsersUserIdAssetsAssetIdFavoriteDelete(userId, assetId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Gets the favorite model for the asset and user.
     * @param {number} userId
     * @param {number} assetId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v1FavoritesUsersUserIdAssetsAssetIdFavoriteGet(
      userId: number,
      assetId: number,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<RobloxCatalogApiAssetFavoriteModel>
    > {
      const localVarAxiosArgs = await FavoritesApiAxiosParamCreator(
        configuration
      ).v1FavoritesUsersUserIdAssetsAssetIdFavoriteGet(userId, assetId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Create a favorite for an asset by the authenticated user.
     * @param {number} userId
     * @param {number} assetId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v1FavoritesUsersUserIdAssetsAssetIdFavoritePost(
      userId: number,
      assetId: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await FavoritesApiAxiosParamCreator(
        configuration
      ).v1FavoritesUsersUserIdAssetsAssetIdFavoritePost(userId, assetId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Delete favorite for the bundle by the authenticated user.
     * @param {number} userId
     * @param {number} bundleId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v1FavoritesUsersUserIdBundlesBundleIdFavoriteDelete(
      userId: number,
      bundleId: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await FavoritesApiAxiosParamCreator(
        configuration
      ).v1FavoritesUsersUserIdBundlesBundleIdFavoriteDelete(userId, bundleId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Gets the favorite model for the bundle and user.
     * @param {number} userId
     * @param {number} bundleId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v1FavoritesUsersUserIdBundlesBundleIdFavoriteGet(
      userId: number,
      bundleId: number,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<RobloxCatalogApiBundleFavoriteModel>
    > {
      const localVarAxiosArgs = await FavoritesApiAxiosParamCreator(
        configuration
      ).v1FavoritesUsersUserIdBundlesBundleIdFavoriteGet(userId, bundleId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Create a favorite for the bundle by the authenticated user.
     * @param {number} userId
     * @param {number} bundleId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v1FavoritesUsersUserIdBundlesBundleIdFavoritePost(
      userId: number,
      bundleId: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await FavoritesApiAxiosParamCreator(
        configuration
      ).v1FavoritesUsersUserIdBundlesBundleIdFavoritePost(userId, bundleId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    }
  };
};

/**
 * FavoritesApi - factory interface
 * @export
 */
export const FavoritesApiFactory = function(
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @summary Gets the favorite count for the given asset Id.
     * @param {number} assetId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1FavoritesAssetsAssetIdCountGet(assetId: number, options?: any): AxiosPromise<number> {
      return FavoritesApiFp(configuration)
        .v1FavoritesAssetsAssetIdCountGet(assetId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Gets the favorite count for the given bundle Id.
     * @param {number} bundleId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1FavoritesBundlesBundleIdCountGet(bundleId: number, options?: any): AxiosPromise<number> {
      return FavoritesApiFp(configuration)
        .v1FavoritesBundlesBundleIdCountGet(bundleId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Delete a favorite for an asset by the authenticated user.
     * @param {number} userId
     * @param {number} assetId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1FavoritesUsersUserIdAssetsAssetIdFavoriteDelete(
      userId: number,
      assetId: number,
      options?: any
    ): AxiosPromise<object> {
      return FavoritesApiFp(configuration)
        .v1FavoritesUsersUserIdAssetsAssetIdFavoriteDelete(userId, assetId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Gets the favorite model for the asset and user.
     * @param {number} userId
     * @param {number} assetId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1FavoritesUsersUserIdAssetsAssetIdFavoriteGet(
      userId: number,
      assetId: number,
      options?: any
    ): AxiosPromise<RobloxCatalogApiAssetFavoriteModel> {
      return FavoritesApiFp(configuration)
        .v1FavoritesUsersUserIdAssetsAssetIdFavoriteGet(userId, assetId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Create a favorite for an asset by the authenticated user.
     * @param {number} userId
     * @param {number} assetId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1FavoritesUsersUserIdAssetsAssetIdFavoritePost(
      userId: number,
      assetId: number,
      options?: any
    ): AxiosPromise<object> {
      return FavoritesApiFp(configuration)
        .v1FavoritesUsersUserIdAssetsAssetIdFavoritePost(userId, assetId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Delete favorite for the bundle by the authenticated user.
     * @param {number} userId
     * @param {number} bundleId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1FavoritesUsersUserIdBundlesBundleIdFavoriteDelete(
      userId: number,
      bundleId: number,
      options?: any
    ): AxiosPromise<object> {
      return FavoritesApiFp(configuration)
        .v1FavoritesUsersUserIdBundlesBundleIdFavoriteDelete(userId, bundleId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Gets the favorite model for the bundle and user.
     * @param {number} userId
     * @param {number} bundleId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1FavoritesUsersUserIdBundlesBundleIdFavoriteGet(
      userId: number,
      bundleId: number,
      options?: any
    ): AxiosPromise<RobloxCatalogApiBundleFavoriteModel> {
      return FavoritesApiFp(configuration)
        .v1FavoritesUsersUserIdBundlesBundleIdFavoriteGet(userId, bundleId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Create a favorite for the bundle by the authenticated user.
     * @param {number} userId
     * @param {number} bundleId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1FavoritesUsersUserIdBundlesBundleIdFavoritePost(
      userId: number,
      bundleId: number,
      options?: any
    ): AxiosPromise<object> {
      return FavoritesApiFp(configuration)
        .v1FavoritesUsersUserIdBundlesBundleIdFavoritePost(userId, bundleId, options)
        .then(request => request(axios, basePath));
    }
  };
};

/**
 * FavoritesApi - interface
 * @export
 * @interface FavoritesApi
 */
export interface FavoritesApiInterface {
  /**
   *
   * @summary Gets the favorite count for the given asset Id.
   * @param {number} assetId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FavoritesApiInterface
   */
  v1FavoritesAssetsAssetIdCountGet(assetId: number, options?: any): AxiosPromise<number>;

  /**
   *
   * @summary Gets the favorite count for the given bundle Id.
   * @param {number} bundleId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FavoritesApiInterface
   */
  v1FavoritesBundlesBundleIdCountGet(bundleId: number, options?: any): AxiosPromise<number>;

  /**
   *
   * @summary Delete a favorite for an asset by the authenticated user.
   * @param {number} userId
   * @param {number} assetId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FavoritesApiInterface
   */
  v1FavoritesUsersUserIdAssetsAssetIdFavoriteDelete(
    userId: number,
    assetId: number,
    options?: any
  ): AxiosPromise<object>;

  /**
   *
   * @summary Gets the favorite model for the asset and user.
   * @param {number} userId
   * @param {number} assetId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FavoritesApiInterface
   */
  v1FavoritesUsersUserIdAssetsAssetIdFavoriteGet(
    userId: number,
    assetId: number,
    options?: any
  ): AxiosPromise<RobloxCatalogApiAssetFavoriteModel>;

  /**
   *
   * @summary Create a favorite for an asset by the authenticated user.
   * @param {number} userId
   * @param {number} assetId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FavoritesApiInterface
   */
  v1FavoritesUsersUserIdAssetsAssetIdFavoritePost(
    userId: number,
    assetId: number,
    options?: any
  ): AxiosPromise<object>;

  /**
   *
   * @summary Delete favorite for the bundle by the authenticated user.
   * @param {number} userId
   * @param {number} bundleId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FavoritesApiInterface
   */
  v1FavoritesUsersUserIdBundlesBundleIdFavoriteDelete(
    userId: number,
    bundleId: number,
    options?: any
  ): AxiosPromise<object>;

  /**
   *
   * @summary Gets the favorite model for the bundle and user.
   * @param {number} userId
   * @param {number} bundleId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FavoritesApiInterface
   */
  v1FavoritesUsersUserIdBundlesBundleIdFavoriteGet(
    userId: number,
    bundleId: number,
    options?: any
  ): AxiosPromise<RobloxCatalogApiBundleFavoriteModel>;

  /**
   *
   * @summary Create a favorite for the bundle by the authenticated user.
   * @param {number} userId
   * @param {number} bundleId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FavoritesApiInterface
   */
  v1FavoritesUsersUserIdBundlesBundleIdFavoritePost(
    userId: number,
    bundleId: number,
    options?: any
  ): AxiosPromise<object>;
}

/**
 * FavoritesApi - object-oriented interface
 * @export
 * @class FavoritesApi
 * @extends {BaseAPI}
 */
export class FavoritesApi extends BaseAPI implements FavoritesApiInterface {
  /**
   *
   * @summary Gets the favorite count for the given asset Id.
   * @param {number} assetId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FavoritesApi
   */
  public v1FavoritesAssetsAssetIdCountGet(assetId: number, options?: any) {
    return FavoritesApiFp(this.configuration)
      .v1FavoritesAssetsAssetIdCountGet(assetId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Gets the favorite count for the given bundle Id.
   * @param {number} bundleId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FavoritesApi
   */
  public v1FavoritesBundlesBundleIdCountGet(bundleId: number, options?: any) {
    return FavoritesApiFp(this.configuration)
      .v1FavoritesBundlesBundleIdCountGet(bundleId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Delete a favorite for an asset by the authenticated user.
   * @param {number} userId
   * @param {number} assetId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FavoritesApi
   */
  public v1FavoritesUsersUserIdAssetsAssetIdFavoriteDelete(
    userId: number,
    assetId: number,
    options?: any
  ) {
    return FavoritesApiFp(this.configuration)
      .v1FavoritesUsersUserIdAssetsAssetIdFavoriteDelete(userId, assetId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Gets the favorite model for the asset and user.
   * @param {number} userId
   * @param {number} assetId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FavoritesApi
   */
  public v1FavoritesUsersUserIdAssetsAssetIdFavoriteGet(
    userId: number,
    assetId: number,
    options?: any
  ) {
    return FavoritesApiFp(this.configuration)
      .v1FavoritesUsersUserIdAssetsAssetIdFavoriteGet(userId, assetId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Create a favorite for an asset by the authenticated user.
   * @param {number} userId
   * @param {number} assetId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FavoritesApi
   */
  public v1FavoritesUsersUserIdAssetsAssetIdFavoritePost(
    userId: number,
    assetId: number,
    options?: any
  ) {
    return FavoritesApiFp(this.configuration)
      .v1FavoritesUsersUserIdAssetsAssetIdFavoritePost(userId, assetId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Delete favorite for the bundle by the authenticated user.
   * @param {number} userId
   * @param {number} bundleId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FavoritesApi
   */
  public v1FavoritesUsersUserIdBundlesBundleIdFavoriteDelete(
    userId: number,
    bundleId: number,
    options?: any
  ) {
    return FavoritesApiFp(this.configuration)
      .v1FavoritesUsersUserIdBundlesBundleIdFavoriteDelete(userId, bundleId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Gets the favorite model for the bundle and user.
   * @param {number} userId
   * @param {number} bundleId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FavoritesApi
   */
  public v1FavoritesUsersUserIdBundlesBundleIdFavoriteGet(
    userId: number,
    bundleId: number,
    options?: any
  ) {
    return FavoritesApiFp(this.configuration)
      .v1FavoritesUsersUserIdBundlesBundleIdFavoriteGet(userId, bundleId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Create a favorite for the bundle by the authenticated user.
   * @param {number} userId
   * @param {number} bundleId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FavoritesApi
   */
  public v1FavoritesUsersUserIdBundlesBundleIdFavoritePost(
    userId: number,
    bundleId: number,
    options?: any
  ) {
    return FavoritesApiFp(this.configuration)
      .v1FavoritesUsersUserIdBundlesBundleIdFavoritePost(userId, bundleId, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * RecommendationsApi - axios parameter creator
 * @export
 */
export const RecommendationsApiAxiosParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary Gets a list of recommended assets, given an asset type  - Migrated from Inventory.Api, functional similarity preserved
     * @param {number} assetTypeId The asset type id {Roblox.AssetType}
     * @param {number} [numItems] The number of recommended items to return.
     * @param {number} [contextAssetId] Optional asset id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1RecommendationsAssetAssetTypeIdGet: async (
      assetTypeId: number,
      numItems?: number,
      contextAssetId?: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'assetTypeId' is not null or undefined
      if (assetTypeId === null || assetTypeId === undefined) {
        throw new RequiredError(
          'assetTypeId',
          'Required parameter assetTypeId was null or undefined when calling v1RecommendationsAssetAssetTypeIdGet.'
        );
      }
      const localVarPath = `/v1/recommendations/asset/{assetTypeId}`.replace(
        `{${'assetTypeId'}}`,
        encodeURIComponent(String(assetTypeId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (numItems !== undefined) {
        localVarQueryParameter.numItems = numItems;
      }

      if (contextAssetId !== undefined) {
        localVarQueryParameter.contextAssetId = contextAssetId;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Return Metadata for Recommendations Component based on the page it is used on
     * @param {string} [page]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1RecommendationsMetadataGet: async (
      page?: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/recommendations/metadata`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter.page = page;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * RecommendationsApi - functional programming interface
 * @export
 */
export const RecommendationsApiFp = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary Gets a list of recommended assets, given an asset type  - Migrated from Inventory.Api, functional similarity preserved
     * @param {number} assetTypeId The asset type id {Roblox.AssetType}
     * @param {number} [numItems] The number of recommended items to return.
     * @param {number} [contextAssetId] Optional asset id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v1RecommendationsAssetAssetTypeIdGet(
      assetTypeId: number,
      numItems?: number,
      contextAssetId?: number,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<
        RobloxWebWebAPIModelsApiLegacyPageResponseRobloxCatalogApiRecommendationViewModelV2
      >
    > {
      const localVarAxiosArgs = await RecommendationsApiAxiosParamCreator(
        configuration
      ).v1RecommendationsAssetAssetTypeIdGet(assetTypeId, numItems, contextAssetId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Return Metadata for Recommendations Component based on the page it is used on
     * @param {string} [page]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v1RecommendationsMetadataGet(
      page?: string,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<RobloxCatalogApiRecommendationsMetadataModel>
    > {
      const localVarAxiosArgs = await RecommendationsApiAxiosParamCreator(
        configuration
      ).v1RecommendationsMetadataGet(page, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    }
  };
};

/**
 * RecommendationsApi - factory interface
 * @export
 */
export const RecommendationsApiFactory = function(
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @summary Gets a list of recommended assets, given an asset type  - Migrated from Inventory.Api, functional similarity preserved
     * @param {number} assetTypeId The asset type id {Roblox.AssetType}
     * @param {number} [numItems] The number of recommended items to return.
     * @param {number} [contextAssetId] Optional asset id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1RecommendationsAssetAssetTypeIdGet(
      assetTypeId: number,
      numItems?: number,
      contextAssetId?: number,
      options?: any
    ): AxiosPromise<
      RobloxWebWebAPIModelsApiLegacyPageResponseRobloxCatalogApiRecommendationViewModelV2
    > {
      return RecommendationsApiFp(configuration)
        .v1RecommendationsAssetAssetTypeIdGet(assetTypeId, numItems, contextAssetId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Return Metadata for Recommendations Component based on the page it is used on
     * @param {string} [page]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1RecommendationsMetadataGet(
      page?: string,
      options?: any
    ): AxiosPromise<RobloxCatalogApiRecommendationsMetadataModel> {
      return RecommendationsApiFp(configuration)
        .v1RecommendationsMetadataGet(page, options)
        .then(request => request(axios, basePath));
    }
  };
};

/**
 * RecommendationsApi - interface
 * @export
 * @interface RecommendationsApi
 */
export interface RecommendationsApiInterface {
  /**
   *
   * @summary Gets a list of recommended assets, given an asset type  - Migrated from Inventory.Api, functional similarity preserved
   * @param {number} assetTypeId The asset type id {Roblox.AssetType}
   * @param {number} [numItems] The number of recommended items to return.
   * @param {number} [contextAssetId] Optional asset id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RecommendationsApiInterface
   */
  v1RecommendationsAssetAssetTypeIdGet(
    assetTypeId: number,
    numItems?: number,
    contextAssetId?: number,
    options?: any
  ): AxiosPromise<
    RobloxWebWebAPIModelsApiLegacyPageResponseRobloxCatalogApiRecommendationViewModelV2
  >;

  /**
   *
   * @summary Return Metadata for Recommendations Component based on the page it is used on
   * @param {string} [page]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RecommendationsApiInterface
   */
  v1RecommendationsMetadataGet(
    page?: string,
    options?: any
  ): AxiosPromise<RobloxCatalogApiRecommendationsMetadataModel>;
}

/**
 * RecommendationsApi - object-oriented interface
 * @export
 * @class RecommendationsApi
 * @extends {BaseAPI}
 */
export class RecommendationsApi extends BaseAPI implements RecommendationsApiInterface {
  /**
   *
   * @summary Gets a list of recommended assets, given an asset type  - Migrated from Inventory.Api, functional similarity preserved
   * @param {number} assetTypeId The asset type id {Roblox.AssetType}
   * @param {number} [numItems] The number of recommended items to return.
   * @param {number} [contextAssetId] Optional asset id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RecommendationsApi
   */
  public v1RecommendationsAssetAssetTypeIdGet(
    assetTypeId: number,
    numItems?: number,
    contextAssetId?: number,
    options?: any
  ) {
    return RecommendationsApiFp(this.configuration)
      .v1RecommendationsAssetAssetTypeIdGet(assetTypeId, numItems, contextAssetId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Return Metadata for Recommendations Component based on the page it is used on
   * @param {string} [page]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RecommendationsApi
   */
  public v1RecommendationsMetadataGet(page?: string, options?: any) {
    return RecommendationsApiFp(this.configuration)
      .v1RecommendationsMetadataGet(page, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * SearchApi - axios parameter creator
 * @export
 */
export const SearchApiAxiosParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary Search for avatarCatalog items
     * @param {'Accessories' | 'All' | 'AvatarAnimations' | 'BodyParts' | 'Clothing' | 'Collectibles' | 'Featured' | 'Gear' | 'CommunityCreations' | 'Premium' | 'Recommended'} [modelCategory]
     * @param {'Accessories' | 'All' | 'AvatarAnimations' | 'BackAccessories' | 'BodyParts' | 'Clothing' | 'Collectibles' | 'FaceAccessories' | 'Faces' | 'Featured' | 'FrontAccessories' | 'Gear' | 'HairAccessories' | 'Hats' | 'Heads' | 'NeckAccessories' | 'Pants' | 'Shirts' | 'ShoulderAccessories' | 'Tshirts' | 'WaistAccessories' | 'Bundles' | 'AnimationBundles' | 'EmoteAnimations' | 'CommunityCreations' | 'Melee' | 'Ranged' | 'Explosive' | 'PowerUp' | 'Navigation' | 'Musical' | 'Social' | 'Building' | 'Transport' | 'Premium' | 'Recommended'} [modelSubcategory]
     * @param {'Past12Hours' | 'PastDay' | 'Past3Days' | 'PastWeek' | 'PastMonth' | 'AllTime'} [modelSortAggregation]
     * @param {'All' | 'Robux' | 'Tickets' | 'CustomRobux' | 'CustomTickets' | 'Free'} [modelSortCurrency]
     * @param {Array<string>} [modelGenres]
     * @param {'Relevance' | 'Favorited' | 'Sales' | 'Updated' | 'PriceAsc' | 'PriceDesc'} [modelSortType]
     * @param {'User' | 'Group'} [modelCreatorType]
     * @param {number} [modelCreatorTargetId]
     * @param {string} [modelCreatorName]
     * @param {number} [modelMaxPrice]
     * @param {number} [modelMinPrice]
     * @param {string} [modelKeyword]
     * @param {boolean} [modelIncludeNotForSale]
     * @param {Array<string>} [modelTagNames]
     * @param {'Asc' | 'Desc'} [sortOrder] The order the results are sorted in.
     * @param {10 | 28 | 30} [limit] The amount of results per request.
     * @param {string} [cursor] The paging cursor for the previous or next page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1SearchItemsDetailsGet: async (
      modelCategory?:
        | 'Accessories'
        | 'All'
        | 'AvatarAnimations'
        | 'BodyParts'
        | 'Clothing'
        | 'Collectibles'
        | 'Featured'
        | 'Gear'
        | 'CommunityCreations'
        | 'Premium'
        | 'Recommended',
      modelSubcategory?:
        | 'Accessories'
        | 'All'
        | 'AvatarAnimations'
        | 'BackAccessories'
        | 'BodyParts'
        | 'Clothing'
        | 'Collectibles'
        | 'FaceAccessories'
        | 'Faces'
        | 'Featured'
        | 'FrontAccessories'
        | 'Gear'
        | 'HairAccessories'
        | 'Hats'
        | 'Heads'
        | 'NeckAccessories'
        | 'Pants'
        | 'Shirts'
        | 'ShoulderAccessories'
        | 'Tshirts'
        | 'WaistAccessories'
        | 'Bundles'
        | 'AnimationBundles'
        | 'EmoteAnimations'
        | 'CommunityCreations'
        | 'Melee'
        | 'Ranged'
        | 'Explosive'
        | 'PowerUp'
        | 'Navigation'
        | 'Musical'
        | 'Social'
        | 'Building'
        | 'Transport'
        | 'Premium'
        | 'Recommended',
      modelSortAggregation?:
        | 'Past12Hours'
        | 'PastDay'
        | 'Past3Days'
        | 'PastWeek'
        | 'PastMonth'
        | 'AllTime',
      modelSortCurrency?: 'All' | 'Robux' | 'Tickets' | 'CustomRobux' | 'CustomTickets' | 'Free',
      modelGenres?: Array<string>,
      modelSortType?: 'Relevance' | 'Favorited' | 'Sales' | 'Updated' | 'PriceAsc' | 'PriceDesc',
      modelCreatorType?: 'User' | 'Group',
      modelCreatorTargetId?: number,
      modelCreatorName?: string,
      modelMaxPrice?: number,
      modelMinPrice?: number,
      modelKeyword?: string,
      modelIncludeNotForSale?: boolean,
      modelTagNames?: Array<string>,
      sortOrder?: 'Asc' | 'Desc',
      limit?: 10 | 28 | 30,
      cursor?: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/search/items/details`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (modelCategory !== undefined) {
        localVarQueryParameter['model.category'] = modelCategory;
      }

      if (modelSubcategory !== undefined) {
        localVarQueryParameter['model.subcategory'] = modelSubcategory;
      }

      if (modelSortAggregation !== undefined) {
        localVarQueryParameter['model.sortAggregation'] = modelSortAggregation;
      }

      if (modelSortCurrency !== undefined) {
        localVarQueryParameter['model.sortCurrency'] = modelSortCurrency;
      }

      if (modelGenres) {
        localVarQueryParameter['model.genres'] = modelGenres;
      }

      if (modelSortType !== undefined) {
        localVarQueryParameter['model.sortType'] = modelSortType;
      }

      if (modelCreatorType !== undefined) {
        localVarQueryParameter['model.creatorType'] = modelCreatorType;
      }

      if (modelCreatorTargetId !== undefined) {
        localVarQueryParameter['model.creatorTargetId'] = modelCreatorTargetId;
      }

      if (modelCreatorName !== undefined) {
        localVarQueryParameter['model.creatorName'] = modelCreatorName;
      }

      if (modelMaxPrice !== undefined) {
        localVarQueryParameter['model.maxPrice'] = modelMaxPrice;
      }

      if (modelMinPrice !== undefined) {
        localVarQueryParameter['model.minPrice'] = modelMinPrice;
      }

      if (modelKeyword !== undefined) {
        localVarQueryParameter['model.keyword'] = modelKeyword;
      }

      if (modelIncludeNotForSale !== undefined) {
        localVarQueryParameter['model.includeNotForSale'] = modelIncludeNotForSale;
      }

      if (modelTagNames) {
        localVarQueryParameter['model.tagNames'] = modelTagNames;
      }

      if (sortOrder !== undefined) {
        localVarQueryParameter.sortOrder = sortOrder;
      }

      if (limit !== undefined) {
        localVarQueryParameter.limit = limit;
      }

      if (cursor !== undefined) {
        localVarQueryParameter.cursor = cursor;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Search for avatarCatalog item ids
     * @param {'Accessories' | 'All' | 'AvatarAnimations' | 'BodyParts' | 'Clothing' | 'Collectibles' | 'Featured' | 'Gear' | 'CommunityCreations' | 'Premium' | 'Recommended'} [modelCategory]
     * @param {'Accessories' | 'All' | 'AvatarAnimations' | 'BackAccessories' | 'BodyParts' | 'Clothing' | 'Collectibles' | 'FaceAccessories' | 'Faces' | 'Featured' | 'FrontAccessories' | 'Gear' | 'HairAccessories' | 'Hats' | 'Heads' | 'NeckAccessories' | 'Pants' | 'Shirts' | 'ShoulderAccessories' | 'Tshirts' | 'WaistAccessories' | 'Bundles' | 'AnimationBundles' | 'EmoteAnimations' | 'CommunityCreations' | 'Melee' | 'Ranged' | 'Explosive' | 'PowerUp' | 'Navigation' | 'Musical' | 'Social' | 'Building' | 'Transport' | 'Premium' | 'Recommended'} [modelSubcategory]
     * @param {'Past12Hours' | 'PastDay' | 'Past3Days' | 'PastWeek' | 'PastMonth' | 'AllTime'} [modelSortAggregation]
     * @param {'All' | 'Robux' | 'Tickets' | 'CustomRobux' | 'CustomTickets' | 'Free'} [modelSortCurrency]
     * @param {Array<string>} [modelGenres]
     * @param {'Relevance' | 'Favorited' | 'Sales' | 'Updated' | 'PriceAsc' | 'PriceDesc'} [modelSortType]
     * @param {'User' | 'Group'} [modelCreatorType]
     * @param {number} [modelCreatorTargetId]
     * @param {string} [modelCreatorName]
     * @param {number} [modelMaxPrice]
     * @param {number} [modelMinPrice]
     * @param {string} [modelKeyword]
     * @param {boolean} [modelIncludeNotForSale]
     * @param {Array<string>} [modelTagNames]
     * @param {'Asc' | 'Desc'} [sortOrder] The order the results are sorted in.
     * @param {10 | 28 | 30 | 50 | 60 | 100} [limit] The amount of results per request.
     * @param {string} [cursor] The paging cursor for the previous or next page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1SearchItemsGet: async (
      modelCategory?:
        | 'Accessories'
        | 'All'
        | 'AvatarAnimations'
        | 'BodyParts'
        | 'Clothing'
        | 'Collectibles'
        | 'Featured'
        | 'Gear'
        | 'CommunityCreations'
        | 'Premium'
        | 'Recommended',
      modelSubcategory?:
        | 'Accessories'
        | 'All'
        | 'AvatarAnimations'
        | 'BackAccessories'
        | 'BodyParts'
        | 'Clothing'
        | 'Collectibles'
        | 'FaceAccessories'
        | 'Faces'
        | 'Featured'
        | 'FrontAccessories'
        | 'Gear'
        | 'HairAccessories'
        | 'Hats'
        | 'Heads'
        | 'NeckAccessories'
        | 'Pants'
        | 'Shirts'
        | 'ShoulderAccessories'
        | 'Tshirts'
        | 'WaistAccessories'
        | 'Bundles'
        | 'AnimationBundles'
        | 'EmoteAnimations'
        | 'CommunityCreations'
        | 'Melee'
        | 'Ranged'
        | 'Explosive'
        | 'PowerUp'
        | 'Navigation'
        | 'Musical'
        | 'Social'
        | 'Building'
        | 'Transport'
        | 'Premium'
        | 'Recommended',
      modelSortAggregation?:
        | 'Past12Hours'
        | 'PastDay'
        | 'Past3Days'
        | 'PastWeek'
        | 'PastMonth'
        | 'AllTime',
      modelSortCurrency?: 'All' | 'Robux' | 'Tickets' | 'CustomRobux' | 'CustomTickets' | 'Free',
      modelGenres?: Array<string>,
      modelSortType?: 'Relevance' | 'Favorited' | 'Sales' | 'Updated' | 'PriceAsc' | 'PriceDesc',
      modelCreatorType?: 'User' | 'Group',
      modelCreatorTargetId?: number,
      modelCreatorName?: string,
      modelMaxPrice?: number,
      modelMinPrice?: number,
      modelKeyword?: string,
      modelIncludeNotForSale?: boolean,
      modelTagNames?: Array<string>,
      sortOrder?: 'Asc' | 'Desc',
      limit?: 10 | 28 | 30 | 50 | 60 | 100,
      cursor?: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/search/items`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (modelCategory !== undefined) {
        localVarQueryParameter['model.category'] = modelCategory;
      }

      if (modelSubcategory !== undefined) {
        localVarQueryParameter['model.subcategory'] = modelSubcategory;
      }

      if (modelSortAggregation !== undefined) {
        localVarQueryParameter['model.sortAggregation'] = modelSortAggregation;
      }

      if (modelSortCurrency !== undefined) {
        localVarQueryParameter['model.sortCurrency'] = modelSortCurrency;
      }

      if (modelGenres) {
        localVarQueryParameter['model.genres'] = modelGenres;
      }

      if (modelSortType !== undefined) {
        localVarQueryParameter['model.sortType'] = modelSortType;
      }

      if (modelCreatorType !== undefined) {
        localVarQueryParameter['model.creatorType'] = modelCreatorType;
      }

      if (modelCreatorTargetId !== undefined) {
        localVarQueryParameter['model.creatorTargetId'] = modelCreatorTargetId;
      }

      if (modelCreatorName !== undefined) {
        localVarQueryParameter['model.creatorName'] = modelCreatorName;
      }

      if (modelMaxPrice !== undefined) {
        localVarQueryParameter['model.maxPrice'] = modelMaxPrice;
      }

      if (modelMinPrice !== undefined) {
        localVarQueryParameter['model.minPrice'] = modelMinPrice;
      }

      if (modelKeyword !== undefined) {
        localVarQueryParameter['model.keyword'] = modelKeyword;
      }

      if (modelIncludeNotForSale !== undefined) {
        localVarQueryParameter['model.includeNotForSale'] = modelIncludeNotForSale;
      }

      if (modelTagNames) {
        localVarQueryParameter['model.tagNames'] = modelTagNames;
      }

      if (sortOrder !== undefined) {
        localVarQueryParameter.sortOrder = sortOrder;
      }

      if (limit !== undefined) {
        localVarQueryParameter.limit = limit;
      }

      if (cursor !== undefined) {
        localVarQueryParameter.cursor = cursor;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Return Navigation Menu Items for Catalog Webpage
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1SearchNavigationMenuItemsGet: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/v1/search/navigation-menu-items`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * SearchApi - functional programming interface
 * @export
 */
export const SearchApiFp = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary Search for avatarCatalog items
     * @param {'Accessories' | 'All' | 'AvatarAnimations' | 'BodyParts' | 'Clothing' | 'Collectibles' | 'Featured' | 'Gear' | 'CommunityCreations' | 'Premium' | 'Recommended'} [modelCategory]
     * @param {'Accessories' | 'All' | 'AvatarAnimations' | 'BackAccessories' | 'BodyParts' | 'Clothing' | 'Collectibles' | 'FaceAccessories' | 'Faces' | 'Featured' | 'FrontAccessories' | 'Gear' | 'HairAccessories' | 'Hats' | 'Heads' | 'NeckAccessories' | 'Pants' | 'Shirts' | 'ShoulderAccessories' | 'Tshirts' | 'WaistAccessories' | 'Bundles' | 'AnimationBundles' | 'EmoteAnimations' | 'CommunityCreations' | 'Melee' | 'Ranged' | 'Explosive' | 'PowerUp' | 'Navigation' | 'Musical' | 'Social' | 'Building' | 'Transport' | 'Premium' | 'Recommended'} [modelSubcategory]
     * @param {'Past12Hours' | 'PastDay' | 'Past3Days' | 'PastWeek' | 'PastMonth' | 'AllTime'} [modelSortAggregation]
     * @param {'All' | 'Robux' | 'Tickets' | 'CustomRobux' | 'CustomTickets' | 'Free'} [modelSortCurrency]
     * @param {Array<string>} [modelGenres]
     * @param {'Relevance' | 'Favorited' | 'Sales' | 'Updated' | 'PriceAsc' | 'PriceDesc'} [modelSortType]
     * @param {'User' | 'Group'} [modelCreatorType]
     * @param {number} [modelCreatorTargetId]
     * @param {string} [modelCreatorName]
     * @param {number} [modelMaxPrice]
     * @param {number} [modelMinPrice]
     * @param {string} [modelKeyword]
     * @param {boolean} [modelIncludeNotForSale]
     * @param {Array<string>} [modelTagNames]
     * @param {'Asc' | 'Desc'} [sortOrder] The order the results are sorted in.
     * @param {10 | 28 | 30} [limit] The amount of results per request.
     * @param {string} [cursor] The paging cursor for the previous or next page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v1SearchItemsDetailsGet(
      modelCategory?:
        | 'Accessories'
        | 'All'
        | 'AvatarAnimations'
        | 'BodyParts'
        | 'Clothing'
        | 'Collectibles'
        | 'Featured'
        | 'Gear'
        | 'CommunityCreations'
        | 'Premium'
        | 'Recommended',
      modelSubcategory?:
        | 'Accessories'
        | 'All'
        | 'AvatarAnimations'
        | 'BackAccessories'
        | 'BodyParts'
        | 'Clothing'
        | 'Collectibles'
        | 'FaceAccessories'
        | 'Faces'
        | 'Featured'
        | 'FrontAccessories'
        | 'Gear'
        | 'HairAccessories'
        | 'Hats'
        | 'Heads'
        | 'NeckAccessories'
        | 'Pants'
        | 'Shirts'
        | 'ShoulderAccessories'
        | 'Tshirts'
        | 'WaistAccessories'
        | 'Bundles'
        | 'AnimationBundles'
        | 'EmoteAnimations'
        | 'CommunityCreations'
        | 'Melee'
        | 'Ranged'
        | 'Explosive'
        | 'PowerUp'
        | 'Navigation'
        | 'Musical'
        | 'Social'
        | 'Building'
        | 'Transport'
        | 'Premium'
        | 'Recommended',
      modelSortAggregation?:
        | 'Past12Hours'
        | 'PastDay'
        | 'Past3Days'
        | 'PastWeek'
        | 'PastMonth'
        | 'AllTime',
      modelSortCurrency?: 'All' | 'Robux' | 'Tickets' | 'CustomRobux' | 'CustomTickets' | 'Free',
      modelGenres?: Array<string>,
      modelSortType?: 'Relevance' | 'Favorited' | 'Sales' | 'Updated' | 'PriceAsc' | 'PriceDesc',
      modelCreatorType?: 'User' | 'Group',
      modelCreatorTargetId?: number,
      modelCreatorName?: string,
      modelMaxPrice?: number,
      modelMinPrice?: number,
      modelKeyword?: string,
      modelIncludeNotForSale?: boolean,
      modelTagNames?: Array<string>,
      sortOrder?: 'Asc' | 'Desc',
      limit?: 10 | 28 | 30,
      cursor?: string,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<
        RobloxCatalogApiCatalogSearchPageResponseRobloxCatalogApiCatalogSearchDetailedResponseItem
      >
    > {
      const localVarAxiosArgs = await SearchApiAxiosParamCreator(
        configuration
      ).v1SearchItemsDetailsGet(
        modelCategory,
        modelSubcategory,
        modelSortAggregation,
        modelSortCurrency,
        modelGenres,
        modelSortType,
        modelCreatorType,
        modelCreatorTargetId,
        modelCreatorName,
        modelMaxPrice,
        modelMinPrice,
        modelKeyword,
        modelIncludeNotForSale,
        modelTagNames,
        sortOrder,
        limit,
        cursor,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Search for avatarCatalog item ids
     * @param {'Accessories' | 'All' | 'AvatarAnimations' | 'BodyParts' | 'Clothing' | 'Collectibles' | 'Featured' | 'Gear' | 'CommunityCreations' | 'Premium' | 'Recommended'} [modelCategory]
     * @param {'Accessories' | 'All' | 'AvatarAnimations' | 'BackAccessories' | 'BodyParts' | 'Clothing' | 'Collectibles' | 'FaceAccessories' | 'Faces' | 'Featured' | 'FrontAccessories' | 'Gear' | 'HairAccessories' | 'Hats' | 'Heads' | 'NeckAccessories' | 'Pants' | 'Shirts' | 'ShoulderAccessories' | 'Tshirts' | 'WaistAccessories' | 'Bundles' | 'AnimationBundles' | 'EmoteAnimations' | 'CommunityCreations' | 'Melee' | 'Ranged' | 'Explosive' | 'PowerUp' | 'Navigation' | 'Musical' | 'Social' | 'Building' | 'Transport' | 'Premium' | 'Recommended'} [modelSubcategory]
     * @param {'Past12Hours' | 'PastDay' | 'Past3Days' | 'PastWeek' | 'PastMonth' | 'AllTime'} [modelSortAggregation]
     * @param {'All' | 'Robux' | 'Tickets' | 'CustomRobux' | 'CustomTickets' | 'Free'} [modelSortCurrency]
     * @param {Array<string>} [modelGenres]
     * @param {'Relevance' | 'Favorited' | 'Sales' | 'Updated' | 'PriceAsc' | 'PriceDesc'} [modelSortType]
     * @param {'User' | 'Group'} [modelCreatorType]
     * @param {number} [modelCreatorTargetId]
     * @param {string} [modelCreatorName]
     * @param {number} [modelMaxPrice]
     * @param {number} [modelMinPrice]
     * @param {string} [modelKeyword]
     * @param {boolean} [modelIncludeNotForSale]
     * @param {Array<string>} [modelTagNames]
     * @param {'Asc' | 'Desc'} [sortOrder] The order the results are sorted in.
     * @param {10 | 28 | 30 | 50 | 60 | 100} [limit] The amount of results per request.
     * @param {string} [cursor] The paging cursor for the previous or next page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v1SearchItemsGet(
      modelCategory?:
        | 'Accessories'
        | 'All'
        | 'AvatarAnimations'
        | 'BodyParts'
        | 'Clothing'
        | 'Collectibles'
        | 'Featured'
        | 'Gear'
        | 'CommunityCreations'
        | 'Premium'
        | 'Recommended',
      modelSubcategory?:
        | 'Accessories'
        | 'All'
        | 'AvatarAnimations'
        | 'BackAccessories'
        | 'BodyParts'
        | 'Clothing'
        | 'Collectibles'
        | 'FaceAccessories'
        | 'Faces'
        | 'Featured'
        | 'FrontAccessories'
        | 'Gear'
        | 'HairAccessories'
        | 'Hats'
        | 'Heads'
        | 'NeckAccessories'
        | 'Pants'
        | 'Shirts'
        | 'ShoulderAccessories'
        | 'Tshirts'
        | 'WaistAccessories'
        | 'Bundles'
        | 'AnimationBundles'
        | 'EmoteAnimations'
        | 'CommunityCreations'
        | 'Melee'
        | 'Ranged'
        | 'Explosive'
        | 'PowerUp'
        | 'Navigation'
        | 'Musical'
        | 'Social'
        | 'Building'
        | 'Transport'
        | 'Premium'
        | 'Recommended',
      modelSortAggregation?:
        | 'Past12Hours'
        | 'PastDay'
        | 'Past3Days'
        | 'PastWeek'
        | 'PastMonth'
        | 'AllTime',
      modelSortCurrency?: 'All' | 'Robux' | 'Tickets' | 'CustomRobux' | 'CustomTickets' | 'Free',
      modelGenres?: Array<string>,
      modelSortType?: 'Relevance' | 'Favorited' | 'Sales' | 'Updated' | 'PriceAsc' | 'PriceDesc',
      modelCreatorType?: 'User' | 'Group',
      modelCreatorTargetId?: number,
      modelCreatorName?: string,
      modelMaxPrice?: number,
      modelMinPrice?: number,
      modelKeyword?: string,
      modelIncludeNotForSale?: boolean,
      modelTagNames?: Array<string>,
      sortOrder?: 'Asc' | 'Desc',
      limit?: 10 | 28 | 30 | 50 | 60 | 100,
      cursor?: string,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<
        RobloxCatalogApiCatalogSearchPageResponseRobloxCatalogApiCatalogSearchResponseItem
      >
    > {
      const localVarAxiosArgs = await SearchApiAxiosParamCreator(configuration).v1SearchItemsGet(
        modelCategory,
        modelSubcategory,
        modelSortAggregation,
        modelSortCurrency,
        modelGenres,
        modelSortType,
        modelCreatorType,
        modelCreatorTargetId,
        modelCreatorName,
        modelMaxPrice,
        modelMinPrice,
        modelKeyword,
        modelIncludeNotForSale,
        modelTagNames,
        sortOrder,
        limit,
        cursor,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Return Navigation Menu Items for Catalog Webpage
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v1SearchNavigationMenuItemsGet(
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<RobloxCatalogApiCatalogMenuItemsResponseModel>
    > {
      const localVarAxiosArgs = await SearchApiAxiosParamCreator(
        configuration
      ).v1SearchNavigationMenuItemsGet(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    }
  };
};

/**
 * SearchApi - factory interface
 * @export
 */
export const SearchApiFactory = function(
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @summary Search for avatarCatalog items
     * @param {'Accessories' | 'All' | 'AvatarAnimations' | 'BodyParts' | 'Clothing' | 'Collectibles' | 'Featured' | 'Gear' | 'CommunityCreations' | 'Premium' | 'Recommended'} [modelCategory]
     * @param {'Accessories' | 'All' | 'AvatarAnimations' | 'BackAccessories' | 'BodyParts' | 'Clothing' | 'Collectibles' | 'FaceAccessories' | 'Faces' | 'Featured' | 'FrontAccessories' | 'Gear' | 'HairAccessories' | 'Hats' | 'Heads' | 'NeckAccessories' | 'Pants' | 'Shirts' | 'ShoulderAccessories' | 'Tshirts' | 'WaistAccessories' | 'Bundles' | 'AnimationBundles' | 'EmoteAnimations' | 'CommunityCreations' | 'Melee' | 'Ranged' | 'Explosive' | 'PowerUp' | 'Navigation' | 'Musical' | 'Social' | 'Building' | 'Transport' | 'Premium' | 'Recommended'} [modelSubcategory]
     * @param {'Past12Hours' | 'PastDay' | 'Past3Days' | 'PastWeek' | 'PastMonth' | 'AllTime'} [modelSortAggregation]
     * @param {'All' | 'Robux' | 'Tickets' | 'CustomRobux' | 'CustomTickets' | 'Free'} [modelSortCurrency]
     * @param {Array<string>} [modelGenres]
     * @param {'Relevance' | 'Favorited' | 'Sales' | 'Updated' | 'PriceAsc' | 'PriceDesc'} [modelSortType]
     * @param {'User' | 'Group'} [modelCreatorType]
     * @param {number} [modelCreatorTargetId]
     * @param {string} [modelCreatorName]
     * @param {number} [modelMaxPrice]
     * @param {number} [modelMinPrice]
     * @param {string} [modelKeyword]
     * @param {boolean} [modelIncludeNotForSale]
     * @param {Array<string>} [modelTagNames]
     * @param {'Asc' | 'Desc'} [sortOrder] The order the results are sorted in.
     * @param {10 | 28 | 30} [limit] The amount of results per request.
     * @param {string} [cursor] The paging cursor for the previous or next page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1SearchItemsDetailsGet(
      modelCategory?:
        | 'Accessories'
        | 'All'
        | 'AvatarAnimations'
        | 'BodyParts'
        | 'Clothing'
        | 'Collectibles'
        | 'Featured'
        | 'Gear'
        | 'CommunityCreations'
        | 'Premium'
        | 'Recommended',
      modelSubcategory?:
        | 'Accessories'
        | 'All'
        | 'AvatarAnimations'
        | 'BackAccessories'
        | 'BodyParts'
        | 'Clothing'
        | 'Collectibles'
        | 'FaceAccessories'
        | 'Faces'
        | 'Featured'
        | 'FrontAccessories'
        | 'Gear'
        | 'HairAccessories'
        | 'Hats'
        | 'Heads'
        | 'NeckAccessories'
        | 'Pants'
        | 'Shirts'
        | 'ShoulderAccessories'
        | 'Tshirts'
        | 'WaistAccessories'
        | 'Bundles'
        | 'AnimationBundles'
        | 'EmoteAnimations'
        | 'CommunityCreations'
        | 'Melee'
        | 'Ranged'
        | 'Explosive'
        | 'PowerUp'
        | 'Navigation'
        | 'Musical'
        | 'Social'
        | 'Building'
        | 'Transport'
        | 'Premium'
        | 'Recommended',
      modelSortAggregation?:
        | 'Past12Hours'
        | 'PastDay'
        | 'Past3Days'
        | 'PastWeek'
        | 'PastMonth'
        | 'AllTime',
      modelSortCurrency?: 'All' | 'Robux' | 'Tickets' | 'CustomRobux' | 'CustomTickets' | 'Free',
      modelGenres?: Array<string>,
      modelSortType?: 'Relevance' | 'Favorited' | 'Sales' | 'Updated' | 'PriceAsc' | 'PriceDesc',
      modelCreatorType?: 'User' | 'Group',
      modelCreatorTargetId?: number,
      modelCreatorName?: string,
      modelMaxPrice?: number,
      modelMinPrice?: number,
      modelKeyword?: string,
      modelIncludeNotForSale?: boolean,
      modelTagNames?: Array<string>,
      sortOrder?: 'Asc' | 'Desc',
      limit?: 10 | 28 | 30,
      cursor?: string,
      options?: any
    ): AxiosPromise<
      RobloxCatalogApiCatalogSearchPageResponseRobloxCatalogApiCatalogSearchDetailedResponseItem
    > {
      return SearchApiFp(configuration)
        .v1SearchItemsDetailsGet(
          modelCategory,
          modelSubcategory,
          modelSortAggregation,
          modelSortCurrency,
          modelGenres,
          modelSortType,
          modelCreatorType,
          modelCreatorTargetId,
          modelCreatorName,
          modelMaxPrice,
          modelMinPrice,
          modelKeyword,
          modelIncludeNotForSale,
          modelTagNames,
          sortOrder,
          limit,
          cursor,
          options
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Search for avatarCatalog item ids
     * @param {'Accessories' | 'All' | 'AvatarAnimations' | 'BodyParts' | 'Clothing' | 'Collectibles' | 'Featured' | 'Gear' | 'CommunityCreations' | 'Premium' | 'Recommended'} [modelCategory]
     * @param {'Accessories' | 'All' | 'AvatarAnimations' | 'BackAccessories' | 'BodyParts' | 'Clothing' | 'Collectibles' | 'FaceAccessories' | 'Faces' | 'Featured' | 'FrontAccessories' | 'Gear' | 'HairAccessories' | 'Hats' | 'Heads' | 'NeckAccessories' | 'Pants' | 'Shirts' | 'ShoulderAccessories' | 'Tshirts' | 'WaistAccessories' | 'Bundles' | 'AnimationBundles' | 'EmoteAnimations' | 'CommunityCreations' | 'Melee' | 'Ranged' | 'Explosive' | 'PowerUp' | 'Navigation' | 'Musical' | 'Social' | 'Building' | 'Transport' | 'Premium' | 'Recommended'} [modelSubcategory]
     * @param {'Past12Hours' | 'PastDay' | 'Past3Days' | 'PastWeek' | 'PastMonth' | 'AllTime'} [modelSortAggregation]
     * @param {'All' | 'Robux' | 'Tickets' | 'CustomRobux' | 'CustomTickets' | 'Free'} [modelSortCurrency]
     * @param {Array<string>} [modelGenres]
     * @param {'Relevance' | 'Favorited' | 'Sales' | 'Updated' | 'PriceAsc' | 'PriceDesc'} [modelSortType]
     * @param {'User' | 'Group'} [modelCreatorType]
     * @param {number} [modelCreatorTargetId]
     * @param {string} [modelCreatorName]
     * @param {number} [modelMaxPrice]
     * @param {number} [modelMinPrice]
     * @param {string} [modelKeyword]
     * @param {boolean} [modelIncludeNotForSale]
     * @param {Array<string>} [modelTagNames]
     * @param {'Asc' | 'Desc'} [sortOrder] The order the results are sorted in.
     * @param {10 | 28 | 30 | 50 | 60 | 100} [limit] The amount of results per request.
     * @param {string} [cursor] The paging cursor for the previous or next page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1SearchItemsGet(
      modelCategory?:
        | 'Accessories'
        | 'All'
        | 'AvatarAnimations'
        | 'BodyParts'
        | 'Clothing'
        | 'Collectibles'
        | 'Featured'
        | 'Gear'
        | 'CommunityCreations'
        | 'Premium'
        | 'Recommended',
      modelSubcategory?:
        | 'Accessories'
        | 'All'
        | 'AvatarAnimations'
        | 'BackAccessories'
        | 'BodyParts'
        | 'Clothing'
        | 'Collectibles'
        | 'FaceAccessories'
        | 'Faces'
        | 'Featured'
        | 'FrontAccessories'
        | 'Gear'
        | 'HairAccessories'
        | 'Hats'
        | 'Heads'
        | 'NeckAccessories'
        | 'Pants'
        | 'Shirts'
        | 'ShoulderAccessories'
        | 'Tshirts'
        | 'WaistAccessories'
        | 'Bundles'
        | 'AnimationBundles'
        | 'EmoteAnimations'
        | 'CommunityCreations'
        | 'Melee'
        | 'Ranged'
        | 'Explosive'
        | 'PowerUp'
        | 'Navigation'
        | 'Musical'
        | 'Social'
        | 'Building'
        | 'Transport'
        | 'Premium'
        | 'Recommended',
      modelSortAggregation?:
        | 'Past12Hours'
        | 'PastDay'
        | 'Past3Days'
        | 'PastWeek'
        | 'PastMonth'
        | 'AllTime',
      modelSortCurrency?: 'All' | 'Robux' | 'Tickets' | 'CustomRobux' | 'CustomTickets' | 'Free',
      modelGenres?: Array<string>,
      modelSortType?: 'Relevance' | 'Favorited' | 'Sales' | 'Updated' | 'PriceAsc' | 'PriceDesc',
      modelCreatorType?: 'User' | 'Group',
      modelCreatorTargetId?: number,
      modelCreatorName?: string,
      modelMaxPrice?: number,
      modelMinPrice?: number,
      modelKeyword?: string,
      modelIncludeNotForSale?: boolean,
      modelTagNames?: Array<string>,
      sortOrder?: 'Asc' | 'Desc',
      limit?: 10 | 28 | 30 | 50 | 60 | 100,
      cursor?: string,
      options?: any
    ): AxiosPromise<
      RobloxCatalogApiCatalogSearchPageResponseRobloxCatalogApiCatalogSearchResponseItem
    > {
      return SearchApiFp(configuration)
        .v1SearchItemsGet(
          modelCategory,
          modelSubcategory,
          modelSortAggregation,
          modelSortCurrency,
          modelGenres,
          modelSortType,
          modelCreatorType,
          modelCreatorTargetId,
          modelCreatorName,
          modelMaxPrice,
          modelMinPrice,
          modelKeyword,
          modelIncludeNotForSale,
          modelTagNames,
          sortOrder,
          limit,
          cursor,
          options
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Return Navigation Menu Items for Catalog Webpage
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1SearchNavigationMenuItemsGet(
      options?: any
    ): AxiosPromise<RobloxCatalogApiCatalogMenuItemsResponseModel> {
      return SearchApiFp(configuration)
        .v1SearchNavigationMenuItemsGet(options)
        .then(request => request(axios, basePath));
    }
  };
};

/**
 * SearchApi - interface
 * @export
 * @interface SearchApi
 */
export interface SearchApiInterface {
  /**
   *
   * @summary Search for avatarCatalog items
   * @param {'Accessories' | 'All' | 'AvatarAnimations' | 'BodyParts' | 'Clothing' | 'Collectibles' | 'Featured' | 'Gear' | 'CommunityCreations' | 'Premium' | 'Recommended'} [modelCategory]
   * @param {'Accessories' | 'All' | 'AvatarAnimations' | 'BackAccessories' | 'BodyParts' | 'Clothing' | 'Collectibles' | 'FaceAccessories' | 'Faces' | 'Featured' | 'FrontAccessories' | 'Gear' | 'HairAccessories' | 'Hats' | 'Heads' | 'NeckAccessories' | 'Pants' | 'Shirts' | 'ShoulderAccessories' | 'Tshirts' | 'WaistAccessories' | 'Bundles' | 'AnimationBundles' | 'EmoteAnimations' | 'CommunityCreations' | 'Melee' | 'Ranged' | 'Explosive' | 'PowerUp' | 'Navigation' | 'Musical' | 'Social' | 'Building' | 'Transport' | 'Premium' | 'Recommended'} [modelSubcategory]
   * @param {'Past12Hours' | 'PastDay' | 'Past3Days' | 'PastWeek' | 'PastMonth' | 'AllTime'} [modelSortAggregation]
   * @param {'All' | 'Robux' | 'Tickets' | 'CustomRobux' | 'CustomTickets' | 'Free'} [modelSortCurrency]
   * @param {Array<string>} [modelGenres]
   * @param {'Relevance' | 'Favorited' | 'Sales' | 'Updated' | 'PriceAsc' | 'PriceDesc'} [modelSortType]
   * @param {'User' | 'Group'} [modelCreatorType]
   * @param {number} [modelCreatorTargetId]
   * @param {string} [modelCreatorName]
   * @param {number} [modelMaxPrice]
   * @param {number} [modelMinPrice]
   * @param {string} [modelKeyword]
   * @param {boolean} [modelIncludeNotForSale]
   * @param {Array<string>} [modelTagNames]
   * @param {'Asc' | 'Desc'} [sortOrder] The order the results are sorted in.
   * @param {10 | 28 | 30} [limit] The amount of results per request.
   * @param {string} [cursor] The paging cursor for the previous or next page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SearchApiInterface
   */
  v1SearchItemsDetailsGet(
    modelCategory?:
      | 'Accessories'
      | 'All'
      | 'AvatarAnimations'
      | 'BodyParts'
      | 'Clothing'
      | 'Collectibles'
      | 'Featured'
      | 'Gear'
      | 'CommunityCreations'
      | 'Premium'
      | 'Recommended',
    modelSubcategory?:
      | 'Accessories'
      | 'All'
      | 'AvatarAnimations'
      | 'BackAccessories'
      | 'BodyParts'
      | 'Clothing'
      | 'Collectibles'
      | 'FaceAccessories'
      | 'Faces'
      | 'Featured'
      | 'FrontAccessories'
      | 'Gear'
      | 'HairAccessories'
      | 'Hats'
      | 'Heads'
      | 'NeckAccessories'
      | 'Pants'
      | 'Shirts'
      | 'ShoulderAccessories'
      | 'Tshirts'
      | 'WaistAccessories'
      | 'Bundles'
      | 'AnimationBundles'
      | 'EmoteAnimations'
      | 'CommunityCreations'
      | 'Melee'
      | 'Ranged'
      | 'Explosive'
      | 'PowerUp'
      | 'Navigation'
      | 'Musical'
      | 'Social'
      | 'Building'
      | 'Transport'
      | 'Premium'
      | 'Recommended',
    modelSortAggregation?:
      | 'Past12Hours'
      | 'PastDay'
      | 'Past3Days'
      | 'PastWeek'
      | 'PastMonth'
      | 'AllTime',
    modelSortCurrency?: 'All' | 'Robux' | 'Tickets' | 'CustomRobux' | 'CustomTickets' | 'Free',
    modelGenres?: Array<string>,
    modelSortType?: 'Relevance' | 'Favorited' | 'Sales' | 'Updated' | 'PriceAsc' | 'PriceDesc',
    modelCreatorType?: 'User' | 'Group',
    modelCreatorTargetId?: number,
    modelCreatorName?: string,
    modelMaxPrice?: number,
    modelMinPrice?: number,
    modelKeyword?: string,
    modelIncludeNotForSale?: boolean,
    modelTagNames?: Array<string>,
    sortOrder?: 'Asc' | 'Desc',
    limit?: 10 | 28 | 30,
    cursor?: string,
    options?: any
  ): AxiosPromise<
    RobloxCatalogApiCatalogSearchPageResponseRobloxCatalogApiCatalogSearchDetailedResponseItem
  >;

  /**
   *
   * @summary Search for avatarCatalog item ids
   * @param {'Accessories' | 'All' | 'AvatarAnimations' | 'BodyParts' | 'Clothing' | 'Collectibles' | 'Featured' | 'Gear' | 'CommunityCreations' | 'Premium' | 'Recommended'} [modelCategory]
   * @param {'Accessories' | 'All' | 'AvatarAnimations' | 'BackAccessories' | 'BodyParts' | 'Clothing' | 'Collectibles' | 'FaceAccessories' | 'Faces' | 'Featured' | 'FrontAccessories' | 'Gear' | 'HairAccessories' | 'Hats' | 'Heads' | 'NeckAccessories' | 'Pants' | 'Shirts' | 'ShoulderAccessories' | 'Tshirts' | 'WaistAccessories' | 'Bundles' | 'AnimationBundles' | 'EmoteAnimations' | 'CommunityCreations' | 'Melee' | 'Ranged' | 'Explosive' | 'PowerUp' | 'Navigation' | 'Musical' | 'Social' | 'Building' | 'Transport' | 'Premium' | 'Recommended'} [modelSubcategory]
   * @param {'Past12Hours' | 'PastDay' | 'Past3Days' | 'PastWeek' | 'PastMonth' | 'AllTime'} [modelSortAggregation]
   * @param {'All' | 'Robux' | 'Tickets' | 'CustomRobux' | 'CustomTickets' | 'Free'} [modelSortCurrency]
   * @param {Array<string>} [modelGenres]
   * @param {'Relevance' | 'Favorited' | 'Sales' | 'Updated' | 'PriceAsc' | 'PriceDesc'} [modelSortType]
   * @param {'User' | 'Group'} [modelCreatorType]
   * @param {number} [modelCreatorTargetId]
   * @param {string} [modelCreatorName]
   * @param {number} [modelMaxPrice]
   * @param {number} [modelMinPrice]
   * @param {string} [modelKeyword]
   * @param {boolean} [modelIncludeNotForSale]
   * @param {Array<string>} [modelTagNames]
   * @param {'Asc' | 'Desc'} [sortOrder] The order the results are sorted in.
   * @param {10 | 28 | 30 | 50 | 60 | 100} [limit] The amount of results per request.
   * @param {string} [cursor] The paging cursor for the previous or next page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SearchApiInterface
   */
  v1SearchItemsGet(
    modelCategory?:
      | 'Accessories'
      | 'All'
      | 'AvatarAnimations'
      | 'BodyParts'
      | 'Clothing'
      | 'Collectibles'
      | 'Featured'
      | 'Gear'
      | 'CommunityCreations'
      | 'Premium'
      | 'Recommended',
    modelSubcategory?:
      | 'Accessories'
      | 'All'
      | 'AvatarAnimations'
      | 'BackAccessories'
      | 'BodyParts'
      | 'Clothing'
      | 'Collectibles'
      | 'FaceAccessories'
      | 'Faces'
      | 'Featured'
      | 'FrontAccessories'
      | 'Gear'
      | 'HairAccessories'
      | 'Hats'
      | 'Heads'
      | 'NeckAccessories'
      | 'Pants'
      | 'Shirts'
      | 'ShoulderAccessories'
      | 'Tshirts'
      | 'WaistAccessories'
      | 'Bundles'
      | 'AnimationBundles'
      | 'EmoteAnimations'
      | 'CommunityCreations'
      | 'Melee'
      | 'Ranged'
      | 'Explosive'
      | 'PowerUp'
      | 'Navigation'
      | 'Musical'
      | 'Social'
      | 'Building'
      | 'Transport'
      | 'Premium'
      | 'Recommended',
    modelSortAggregation?:
      | 'Past12Hours'
      | 'PastDay'
      | 'Past3Days'
      | 'PastWeek'
      | 'PastMonth'
      | 'AllTime',
    modelSortCurrency?: 'All' | 'Robux' | 'Tickets' | 'CustomRobux' | 'CustomTickets' | 'Free',
    modelGenres?: Array<string>,
    modelSortType?: 'Relevance' | 'Favorited' | 'Sales' | 'Updated' | 'PriceAsc' | 'PriceDesc',
    modelCreatorType?: 'User' | 'Group',
    modelCreatorTargetId?: number,
    modelCreatorName?: string,
    modelMaxPrice?: number,
    modelMinPrice?: number,
    modelKeyword?: string,
    modelIncludeNotForSale?: boolean,
    modelTagNames?: Array<string>,
    sortOrder?: 'Asc' | 'Desc',
    limit?: 10 | 28 | 30 | 50 | 60 | 100,
    cursor?: string,
    options?: any
  ): AxiosPromise<
    RobloxCatalogApiCatalogSearchPageResponseRobloxCatalogApiCatalogSearchResponseItem
  >;

  /**
   *
   * @summary Return Navigation Menu Items for Catalog Webpage
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SearchApiInterface
   */
  v1SearchNavigationMenuItemsGet(
    options?: any
  ): AxiosPromise<RobloxCatalogApiCatalogMenuItemsResponseModel>;
}

/**
 * SearchApi - object-oriented interface
 * @export
 * @class SearchApi
 * @extends {BaseAPI}
 */
export class SearchApi extends BaseAPI implements SearchApiInterface {
  /**
   *
   * @summary Search for avatarCatalog items
   * @param {'Accessories' | 'All' | 'AvatarAnimations' | 'BodyParts' | 'Clothing' | 'Collectibles' | 'Featured' | 'Gear' | 'CommunityCreations' | 'Premium' | 'Recommended'} [modelCategory]
   * @param {'Accessories' | 'All' | 'AvatarAnimations' | 'BackAccessories' | 'BodyParts' | 'Clothing' | 'Collectibles' | 'FaceAccessories' | 'Faces' | 'Featured' | 'FrontAccessories' | 'Gear' | 'HairAccessories' | 'Hats' | 'Heads' | 'NeckAccessories' | 'Pants' | 'Shirts' | 'ShoulderAccessories' | 'Tshirts' | 'WaistAccessories' | 'Bundles' | 'AnimationBundles' | 'EmoteAnimations' | 'CommunityCreations' | 'Melee' | 'Ranged' | 'Explosive' | 'PowerUp' | 'Navigation' | 'Musical' | 'Social' | 'Building' | 'Transport' | 'Premium' | 'Recommended'} [modelSubcategory]
   * @param {'Past12Hours' | 'PastDay' | 'Past3Days' | 'PastWeek' | 'PastMonth' | 'AllTime'} [modelSortAggregation]
   * @param {'All' | 'Robux' | 'Tickets' | 'CustomRobux' | 'CustomTickets' | 'Free'} [modelSortCurrency]
   * @param {Array<string>} [modelGenres]
   * @param {'Relevance' | 'Favorited' | 'Sales' | 'Updated' | 'PriceAsc' | 'PriceDesc'} [modelSortType]
   * @param {'User' | 'Group'} [modelCreatorType]
   * @param {number} [modelCreatorTargetId]
   * @param {string} [modelCreatorName]
   * @param {number} [modelMaxPrice]
   * @param {number} [modelMinPrice]
   * @param {string} [modelKeyword]
   * @param {boolean} [modelIncludeNotForSale]
   * @param {Array<string>} [modelTagNames]
   * @param {'Asc' | 'Desc'} [sortOrder] The order the results are sorted in.
   * @param {10 | 28 | 30} [limit] The amount of results per request.
   * @param {string} [cursor] The paging cursor for the previous or next page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SearchApi
   */
  public v1SearchItemsDetailsGet(
    modelCategory?:
      | 'Accessories'
      | 'All'
      | 'AvatarAnimations'
      | 'BodyParts'
      | 'Clothing'
      | 'Collectibles'
      | 'Featured'
      | 'Gear'
      | 'CommunityCreations'
      | 'Premium'
      | 'Recommended',
    modelSubcategory?:
      | 'Accessories'
      | 'All'
      | 'AvatarAnimations'
      | 'BackAccessories'
      | 'BodyParts'
      | 'Clothing'
      | 'Collectibles'
      | 'FaceAccessories'
      | 'Faces'
      | 'Featured'
      | 'FrontAccessories'
      | 'Gear'
      | 'HairAccessories'
      | 'Hats'
      | 'Heads'
      | 'NeckAccessories'
      | 'Pants'
      | 'Shirts'
      | 'ShoulderAccessories'
      | 'Tshirts'
      | 'WaistAccessories'
      | 'Bundles'
      | 'AnimationBundles'
      | 'EmoteAnimations'
      | 'CommunityCreations'
      | 'Melee'
      | 'Ranged'
      | 'Explosive'
      | 'PowerUp'
      | 'Navigation'
      | 'Musical'
      | 'Social'
      | 'Building'
      | 'Transport'
      | 'Premium'
      | 'Recommended',
    modelSortAggregation?:
      | 'Past12Hours'
      | 'PastDay'
      | 'Past3Days'
      | 'PastWeek'
      | 'PastMonth'
      | 'AllTime',
    modelSortCurrency?: 'All' | 'Robux' | 'Tickets' | 'CustomRobux' | 'CustomTickets' | 'Free',
    modelGenres?: Array<string>,
    modelSortType?: 'Relevance' | 'Favorited' | 'Sales' | 'Updated' | 'PriceAsc' | 'PriceDesc',
    modelCreatorType?: 'User' | 'Group',
    modelCreatorTargetId?: number,
    modelCreatorName?: string,
    modelMaxPrice?: number,
    modelMinPrice?: number,
    modelKeyword?: string,
    modelIncludeNotForSale?: boolean,
    modelTagNames?: Array<string>,
    sortOrder?: 'Asc' | 'Desc',
    limit?: 10 | 28 | 30,
    cursor?: string,
    options?: any
  ) {
    return SearchApiFp(this.configuration)
      .v1SearchItemsDetailsGet(
        modelCategory,
        modelSubcategory,
        modelSortAggregation,
        modelSortCurrency,
        modelGenres,
        modelSortType,
        modelCreatorType,
        modelCreatorTargetId,
        modelCreatorName,
        modelMaxPrice,
        modelMinPrice,
        modelKeyword,
        modelIncludeNotForSale,
        modelTagNames,
        sortOrder,
        limit,
        cursor,
        options
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Search for avatarCatalog item ids
   * @param {'Accessories' | 'All' | 'AvatarAnimations' | 'BodyParts' | 'Clothing' | 'Collectibles' | 'Featured' | 'Gear' | 'CommunityCreations' | 'Premium' | 'Recommended'} [modelCategory]
   * @param {'Accessories' | 'All' | 'AvatarAnimations' | 'BackAccessories' | 'BodyParts' | 'Clothing' | 'Collectibles' | 'FaceAccessories' | 'Faces' | 'Featured' | 'FrontAccessories' | 'Gear' | 'HairAccessories' | 'Hats' | 'Heads' | 'NeckAccessories' | 'Pants' | 'Shirts' | 'ShoulderAccessories' | 'Tshirts' | 'WaistAccessories' | 'Bundles' | 'AnimationBundles' | 'EmoteAnimations' | 'CommunityCreations' | 'Melee' | 'Ranged' | 'Explosive' | 'PowerUp' | 'Navigation' | 'Musical' | 'Social' | 'Building' | 'Transport' | 'Premium' | 'Recommended'} [modelSubcategory]
   * @param {'Past12Hours' | 'PastDay' | 'Past3Days' | 'PastWeek' | 'PastMonth' | 'AllTime'} [modelSortAggregation]
   * @param {'All' | 'Robux' | 'Tickets' | 'CustomRobux' | 'CustomTickets' | 'Free'} [modelSortCurrency]
   * @param {Array<string>} [modelGenres]
   * @param {'Relevance' | 'Favorited' | 'Sales' | 'Updated' | 'PriceAsc' | 'PriceDesc'} [modelSortType]
   * @param {'User' | 'Group'} [modelCreatorType]
   * @param {number} [modelCreatorTargetId]
   * @param {string} [modelCreatorName]
   * @param {number} [modelMaxPrice]
   * @param {number} [modelMinPrice]
   * @param {string} [modelKeyword]
   * @param {boolean} [modelIncludeNotForSale]
   * @param {Array<string>} [modelTagNames]
   * @param {'Asc' | 'Desc'} [sortOrder] The order the results are sorted in.
   * @param {10 | 28 | 30 | 50 | 60 | 100} [limit] The amount of results per request.
   * @param {string} [cursor] The paging cursor for the previous or next page.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SearchApi
   */
  public v1SearchItemsGet(
    modelCategory?:
      | 'Accessories'
      | 'All'
      | 'AvatarAnimations'
      | 'BodyParts'
      | 'Clothing'
      | 'Collectibles'
      | 'Featured'
      | 'Gear'
      | 'CommunityCreations'
      | 'Premium'
      | 'Recommended',
    modelSubcategory?:
      | 'Accessories'
      | 'All'
      | 'AvatarAnimations'
      | 'BackAccessories'
      | 'BodyParts'
      | 'Clothing'
      | 'Collectibles'
      | 'FaceAccessories'
      | 'Faces'
      | 'Featured'
      | 'FrontAccessories'
      | 'Gear'
      | 'HairAccessories'
      | 'Hats'
      | 'Heads'
      | 'NeckAccessories'
      | 'Pants'
      | 'Shirts'
      | 'ShoulderAccessories'
      | 'Tshirts'
      | 'WaistAccessories'
      | 'Bundles'
      | 'AnimationBundles'
      | 'EmoteAnimations'
      | 'CommunityCreations'
      | 'Melee'
      | 'Ranged'
      | 'Explosive'
      | 'PowerUp'
      | 'Navigation'
      | 'Musical'
      | 'Social'
      | 'Building'
      | 'Transport'
      | 'Premium'
      | 'Recommended',
    modelSortAggregation?:
      | 'Past12Hours'
      | 'PastDay'
      | 'Past3Days'
      | 'PastWeek'
      | 'PastMonth'
      | 'AllTime',
    modelSortCurrency?: 'All' | 'Robux' | 'Tickets' | 'CustomRobux' | 'CustomTickets' | 'Free',
    modelGenres?: Array<string>,
    modelSortType?: 'Relevance' | 'Favorited' | 'Sales' | 'Updated' | 'PriceAsc' | 'PriceDesc',
    modelCreatorType?: 'User' | 'Group',
    modelCreatorTargetId?: number,
    modelCreatorName?: string,
    modelMaxPrice?: number,
    modelMinPrice?: number,
    modelKeyword?: string,
    modelIncludeNotForSale?: boolean,
    modelTagNames?: Array<string>,
    sortOrder?: 'Asc' | 'Desc',
    limit?: 10 | 28 | 30 | 50 | 60 | 100,
    cursor?: string,
    options?: any
  ) {
    return SearchApiFp(this.configuration)
      .v1SearchItemsGet(
        modelCategory,
        modelSubcategory,
        modelSortAggregation,
        modelSortCurrency,
        modelGenres,
        modelSortType,
        modelCreatorType,
        modelCreatorTargetId,
        modelCreatorName,
        modelMaxPrice,
        modelMinPrice,
        modelKeyword,
        modelIncludeNotForSale,
        modelTagNames,
        sortOrder,
        limit,
        cursor,
        options
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Return Navigation Menu Items for Catalog Webpage
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SearchApi
   */
  public v1SearchNavigationMenuItemsGet(options?: any) {
    return SearchApiFp(this.configuration)
      .v1SearchNavigationMenuItemsGet(options)
      .then(request => request(this.axios, this.basePath));
  }
}
