// tslint:disable
/// <reference path="./custom.d.ts" />
/**
 * Inventory Api v2
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import { Configuration } from './configuration';
import { httpClient as globalAxios } from '../../../http/http';
import { AxiosPromise, AxiosInstance } from 'axios';
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface ApiLegacyPageResponseRecommendationViewModelV2A
 */
export interface ApiLegacyPageResponseRecommendationViewModelV2A {
    /**
     * 
     * @type {Array<RecommendationViewModelV2A>}
     * @memberof ApiLegacyPageResponseRecommendationViewModelV2A
     */
    data?: Array<RecommendationViewModelV2A>;
    /**
     * 
     * @type {number}
     * @memberof ApiLegacyPageResponseRecommendationViewModelV2A
     */
    total?: number;
}
/**
 * 
 * @export
 * @interface ApiPageResponseAssetOwnerResponse
 */
export interface ApiPageResponseAssetOwnerResponse {
    /**
     * 
     * @type {string}
     * @memberof ApiPageResponseAssetOwnerResponse
     */
    previousPageCursor?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPageResponseAssetOwnerResponse
     */
    nextPageCursor?: string;
    /**
     * 
     * @type {Array<AssetOwnerResponse>}
     * @memberof ApiPageResponseAssetOwnerResponse
     */
    data?: Array<AssetOwnerResponse>;
}
/**
 * 
 * @export
 * @interface ApiPageResponseUserAssetModel
 */
export interface ApiPageResponseUserAssetModel {
    /**
     * 
     * @type {string}
     * @memberof ApiPageResponseUserAssetModel
     */
    previousPageCursor?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPageResponseUserAssetModel
     */
    nextPageCursor?: string;
    /**
     * 
     * @type {Array<UserAssetModel>}
     * @memberof ApiPageResponseUserAssetModel
     */
    data?: Array<UserAssetModel>;
}
/**
 * 
 * @export
 * @interface AssetOwnerResponse
 */
export interface AssetOwnerResponse {
    /**
     * 
     * @type {number}
     * @memberof AssetOwnerResponse
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof AssetOwnerResponse
     */
    serialNumber?: number;
    /**
     * 
     * @type {RelatedEntityTypeResponseAgentType}
     * @memberof AssetOwnerResponse
     */
    owner?: RelatedEntityTypeResponseAgentType;
    /**
     * 
     * @type {Date}
     * @memberof AssetOwnerResponse
     */
    created?: Date;
    /**
     * 
     * @type {Date}
     * @memberof AssetOwnerResponse
     */
    updated?: Date;
}
/**
 * 
 * @export
 * @interface CreatorContainerV2
 */
export interface CreatorContainerV2 {
    /**
     * 
     * @type {number}
     * @memberof CreatorContainerV2
     */
    creatorId?: number;
    /**
     * 
     * @type {string}
     * @memberof CreatorContainerV2
     */
    creatorType?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatorContainerV2
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatorContainerV2
     */
    creatorProfileLink?: string;
}
/**
 * 
 * @export
 * @interface ExclusiveStartRequestInt64
 */
export interface ExclusiveStartRequestInt64 {
    /**
     * 
     * @type {IExclusiveStartKeyInfoInt64}
     * @memberof ExclusiveStartRequestInt64
     */
    exclusiveStartKeyInfo?: IExclusiveStartKeyInfoInt64;
    /**
     * 
     * @type {string}
     * @memberof ExclusiveStartRequestInt64
     */
    cursorRecipe?: string;
}
/**
 * 
 * @export
 * @interface IExclusiveStartKeyInfoInt64
 */
export interface IExclusiveStartKeyInfoInt64 {
    /**
     * 
     * @type {string}
     * @memberof IExclusiveStartKeyInfoInt64
     */
    sortOrder?: IExclusiveStartKeyInfoInt64SortOrderEnum;
    /**
     * 
     * @type {string}
     * @memberof IExclusiveStartKeyInfoInt64
     */
    pagingDirection?: IExclusiveStartKeyInfoInt64PagingDirectionEnum;
    /**
     * 
     * @type {number}
     * @memberof IExclusiveStartKeyInfoInt64
     */
    count?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum IExclusiveStartKeyInfoInt64SortOrderEnum {
    Asc = 'Asc',
    Desc = 'Desc'
}
/**
    * @export
    * @enum {string}
    */
export enum IExclusiveStartKeyInfoInt64PagingDirectionEnum {
    Forward = 'Forward',
    Backward = 'Backward'
}

/**
 * 
 * @export
 * @interface ItemContainerV2
 */
export interface ItemContainerV2 {
    /**
     * 
     * @type {number}
     * @memberof ItemContainerV2
     */
    assetId?: number;
    /**
     * 
     * @type {string}
     * @memberof ItemContainerV2
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof ItemContainerV2
     */
    price?: number;
    /**
     * 
     * @type {string}
     * @memberof ItemContainerV2
     */
    absoluteUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof ItemContainerV2
     */
    audioUrl?: string;
}
/**
 * 
 * @export
 * @interface ProductContainerV2
 */
export interface ProductContainerV2 {
    /**
     * 
     * @type {number}
     * @memberof ProductContainerV2
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductContainerV2
     */
    priceInRobux?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ProductContainerV2
     */
    isForSale?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProductContainerV2
     */
    isPublicDomain?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProductContainerV2
     */
    isResellable?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProductContainerV2
     */
    isLimited?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProductContainerV2
     */
    isLimitedUnique?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ProductContainerV2
     */
    serialNumber?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ProductContainerV2
     */
    isRental?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ProductContainerV2
     */
    rentalDurationInHours?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductContainerV2
     */
    bcRequirement?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductContainerV2
     */
    totalPrivateSales?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductContainerV2
     */
    sellerId?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductContainerV2
     */
    sellerName?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductContainerV2
     */
    lowestPrivateSaleUserAssetId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ProductContainerV2
     */
    isXboxExclusiveItem?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProductContainerV2
     */
    offsaleDeadline?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductContainerV2
     */
    noPriceText?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProductContainerV2
     */
    isFree?: boolean;
}
/**
 * 
 * @export
 * @interface RecommendationViewModelV2A
 */
export interface RecommendationViewModelV2A {
    /**
     * 
     * @type {ThumbResultContainerV2}
     * @memberof RecommendationViewModelV2A
     */
    thumbnail?: ThumbResultContainerV2;
    /**
     * 
     * @type {ItemContainerV2}
     * @memberof RecommendationViewModelV2A
     */
    item?: ItemContainerV2;
    /**
     * 
     * @type {CreatorContainerV2}
     * @memberof RecommendationViewModelV2A
     */
    creator?: CreatorContainerV2;
    /**
     * 
     * @type {ProductContainerV2}
     * @memberof RecommendationViewModelV2A
     */
    product?: ProductContainerV2;
}
/**
 * 
 * @export
 * @interface RelatedEntityTypeResponseAgentType
 */
export interface RelatedEntityTypeResponseAgentType {
    /**
     * 
     * @type {number}
     * @memberof RelatedEntityTypeResponseAgentType
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof RelatedEntityTypeResponseAgentType
     */
    type?: RelatedEntityTypeResponseAgentTypeTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum RelatedEntityTypeResponseAgentTypeTypeEnum {
    User = 'User',
    Group = 'Group'
}

/**
 * 
 * @export
 * @interface ThumbResultContainerV2
 */
export interface ThumbResultContainerV2 {
    /**
     * 
     * @type {boolean}
     * @memberof ThumbResultContainerV2
     */
    _final?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ThumbResultContainerV2
     */
    url?: string;
    /**
     * 
     * @type {string}
     * @memberof ThumbResultContainerV2
     */
    retryUrl?: string;
}
/**
 * A model containing information about a {Roblox.Platform.AssetOwnership.UserAsset}
 * @export
 * @interface UserAssetModel
 */
export interface UserAssetModel {
    /**
     * 
     * @type {number}
     * @memberof UserAssetModel
     */
    userAssetId?: number;
    /**
     * 
     * @type {number}
     * @memberof UserAssetModel
     */
    assetId?: number;
    /**
     * 
     * @type {number}
     * @memberof UserAssetModel
     */
    serialNumber?: number;
    /**
     * 
     * @type {UserModel}
     * @memberof UserAssetModel
     */
    owner?: UserModel;
    /**
     * 
     * @type {Date}
     * @memberof UserAssetModel
     */
    created?: Date;
    /**
     * 
     * @type {Date}
     * @memberof UserAssetModel
     */
    updated?: Date;
}
/**
 * A model representing data about an {Roblox.Platform.Membership.IUser}
 * @export
 * @interface UserModel
 */
export interface UserModel {
    /**
     * 
     * @type {number}
     * @memberof UserModel
     */
    userId?: number;
    /**
     * 
     * @type {string}
     * @memberof UserModel
     */
    username?: string;
    /**
     * 
     * @type {string}
     * @memberof UserModel
     */
    buildersClubMembershipType?: UserModelBuildersClubMembershipTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum UserModelBuildersClubMembershipTypeEnum {
    None = 'None',
    BC = 'BC',
    TBC = 'TBC',
    OBC = 'OBC',
    RobloxPremium = 'RobloxPremium'
}


/**
 * AssetsApi - axios parameter creator
 * @export
 */
export const AssetsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets a list of owners of an asset.
         * @param {number} assetId The asset id.
         * @param {'Asc' | 'Desc'} [sortOrder] Sorted by userAssetId
         * @param {10 | 25 | 50 | 100} [limit] The amount of results per request.
         * @param {string} [cursor] The paging cursor for the previous or next page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2AssetsAssetIdOwnersGet(assetId: number, sortOrder?: 'Asc' | 'Desc', limit?: 10 | 25 | 50 | 100, cursor?: string, options: any = {}): RequestArgs {
            // verify required parameter 'assetId' is not null or undefined
            if (assetId === null || assetId === undefined) {
                throw new RequiredError('assetId','Required parameter assetId was null or undefined when calling v2AssetsAssetIdOwnersGet.');
            }
            const localVarPath = `/v2/assets/{assetId}/owners`
                .replace(`{${"assetId"}}`, encodeURIComponent(String(assetId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (cursor !== undefined) {
                localVarQueryParameter['cursor'] = cursor;
            }


                localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a list of recommended assets, given an asset type
         * @param {number} assetTypeId The asset type id {Roblox.AssetType}
         * @param {number} [numItems] The number of recommended items to return.
         * @param {number} [contextAssetId] 
         * @param {number} [thumbWidth] Thumbnail Width
         * @param {number} [thumbHeight] Thumbnail Height
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2RecommendationsAssetTypeIdGet(assetTypeId: number, numItems?: number, contextAssetId?: number, thumbWidth?: number, thumbHeight?: number, options: any = {}): RequestArgs {
            // verify required parameter 'assetTypeId' is not null or undefined
            if (assetTypeId === null || assetTypeId === undefined) {
                throw new RequiredError('assetTypeId','Required parameter assetTypeId was null or undefined when calling v2RecommendationsAssetTypeIdGet.');
            }
            const localVarPath = `/v2/recommendations/{assetTypeId}`
                .replace(`{${"assetTypeId"}}`, encodeURIComponent(String(assetTypeId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (numItems !== undefined) {
                localVarQueryParameter['numItems'] = numItems;
            }

            if (contextAssetId !== undefined) {
                localVarQueryParameter['contextAssetId'] = contextAssetId;
            }

            if (thumbWidth !== undefined) {
                localVarQueryParameter['thumbWidth'] = thumbWidth;
            }

            if (thumbHeight !== undefined) {
                localVarQueryParameter['thumbHeight'] = thumbHeight;
            }


                localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AssetsApi - functional programming interface
 * @export
 */
export const AssetsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets a list of owners of an asset.
         * @param {number} assetId The asset id.
         * @param {'Asc' | 'Desc'} [sortOrder] Sorted by userAssetId
         * @param {10 | 25 | 50 | 100} [limit] The amount of results per request.
         * @param {string} [cursor] The paging cursor for the previous or next page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2AssetsAssetIdOwnersGet(assetId: number, sortOrder?: 'Asc' | 'Desc', limit?: 10 | 25 | 50 | 100, cursor?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiPageResponseAssetOwnerResponse> {
            const localVarAxiosArgs = AssetsApiAxiosParamCreator(configuration).v2AssetsAssetIdOwnersGet(assetId, sortOrder, limit, cursor, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets a list of recommended assets, given an asset type
         * @param {number} assetTypeId The asset type id {Roblox.AssetType}
         * @param {number} [numItems] The number of recommended items to return.
         * @param {number} [contextAssetId] 
         * @param {number} [thumbWidth] Thumbnail Width
         * @param {number} [thumbHeight] Thumbnail Height
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2RecommendationsAssetTypeIdGet(assetTypeId: number, numItems?: number, contextAssetId?: number, thumbWidth?: number, thumbHeight?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiLegacyPageResponseRecommendationViewModelV2A> {
            const localVarAxiosArgs = AssetsApiAxiosParamCreator(configuration).v2RecommendationsAssetTypeIdGet(assetTypeId, numItems, contextAssetId, thumbWidth, thumbHeight, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * AssetsApi - factory interface
 * @export
 */
export const AssetsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Gets a list of owners of an asset.
         * @param {number} assetId The asset id.
         * @param {'Asc' | 'Desc'} [sortOrder] Sorted by userAssetId
         * @param {10 | 25 | 50 | 100} [limit] The amount of results per request.
         * @param {string} [cursor] The paging cursor for the previous or next page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2AssetsAssetIdOwnersGet(assetId: number, sortOrder?: 'Asc' | 'Desc', limit?: 10 | 25 | 50 | 100, cursor?: string, options?: any) {
            return AssetsApiFp(configuration).v2AssetsAssetIdOwnersGet(assetId, sortOrder, limit, cursor, options)(axios, basePath);
        },
        /**
         * 
         * @summary Gets a list of recommended assets, given an asset type
         * @param {number} assetTypeId The asset type id {Roblox.AssetType}
         * @param {number} [numItems] The number of recommended items to return.
         * @param {number} [contextAssetId] 
         * @param {number} [thumbWidth] Thumbnail Width
         * @param {number} [thumbHeight] Thumbnail Height
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2RecommendationsAssetTypeIdGet(assetTypeId: number, numItems?: number, contextAssetId?: number, thumbWidth?: number, thumbHeight?: number, options?: any) {
            return AssetsApiFp(configuration).v2RecommendationsAssetTypeIdGet(assetTypeId, numItems, contextAssetId, thumbWidth, thumbHeight, options)(axios, basePath);
        },
    };
};

/**
 * AssetsApi - interface
 * @export
 * @interface AssetsApi
 */
export interface AssetsApiInterface {
    /**
     * 
     * @summary Gets a list of owners of an asset.
     * @param {number} assetId The asset id.
     * @param {'Asc' | 'Desc'} [sortOrder] Sorted by userAssetId
     * @param {10 | 25 | 50 | 100} [limit] The amount of results per request.
     * @param {string} [cursor] The paging cursor for the previous or next page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetsApiInterface
     */
    v2AssetsAssetIdOwnersGet(assetId: number, sortOrder?: 'Asc' | 'Desc', limit?: 10 | 25 | 50 | 100, cursor?: string, options?: any): AxiosPromise<ApiPageResponseAssetOwnerResponse>;

    /**
     * 
     * @summary Gets a list of recommended assets, given an asset type
     * @param {number} assetTypeId The asset type id {Roblox.AssetType}
     * @param {number} [numItems] The number of recommended items to return.
     * @param {number} [contextAssetId] 
     * @param {number} [thumbWidth] Thumbnail Width
     * @param {number} [thumbHeight] Thumbnail Height
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetsApiInterface
     */
    v2RecommendationsAssetTypeIdGet(assetTypeId: number, numItems?: number, contextAssetId?: number, thumbWidth?: number, thumbHeight?: number, options?: any): AxiosPromise<ApiLegacyPageResponseRecommendationViewModelV2A>;

}

/**
 * AssetsApi - object-oriented interface
 * @export
 * @class AssetsApi
 * @extends {BaseAPI}
 */
export class AssetsApi extends BaseAPI implements AssetsApiInterface {
    /**
     * 
     * @summary Gets a list of owners of an asset.
     * @param {number} assetId The asset id.
     * @param {'Asc' | 'Desc'} [sortOrder] Sorted by userAssetId
     * @param {10 | 25 | 50 | 100} [limit] The amount of results per request.
     * @param {string} [cursor] The paging cursor for the previous or next page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetsApi
     */
    public v2AssetsAssetIdOwnersGet(assetId: number, sortOrder?: 'Asc' | 'Desc', limit?: 10 | 25 | 50 | 100, cursor?: string, options?: any) {
        return AssetsApiFp(this.configuration).v2AssetsAssetIdOwnersGet(assetId, sortOrder, limit, cursor, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Gets a list of recommended assets, given an asset type
     * @param {number} assetTypeId The asset type id {Roblox.AssetType}
     * @param {number} [numItems] The number of recommended items to return.
     * @param {number} [contextAssetId] 
     * @param {number} [thumbWidth] Thumbnail Width
     * @param {number} [thumbHeight] Thumbnail Height
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetsApi
     */
    public v2RecommendationsAssetTypeIdGet(assetTypeId: number, numItems?: number, contextAssetId?: number, thumbWidth?: number, thumbHeight?: number, options?: any) {
        return AssetsApiFp(this.configuration).v2RecommendationsAssetTypeIdGet(assetTypeId, numItems, contextAssetId, thumbWidth, thumbHeight, options)(this.axios, this.basePath);
    }

}


/**
 * InventoryApi - axios parameter creator
 * @export
 */
export const InventoryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets user\'s inventory based on specific asset type.
         * @param {number} userId 
         * @param {number} assetTypeId 
         * @param {'Asc' | 'Desc'} [sortOrder] The order the results are sorted in.
         * @param {10 | 25 | 50 | 100} [limit] The amount of results per request.
         * @param {string} [cursor] The paging cursor for the previous or next page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2UsersUserIdInventoryAssetTypeIdGet(userId: number, assetTypeId: number, sortOrder?: 'Asc' | 'Desc', limit?: 10 | 25 | 50 | 100, cursor?: string, options: any = {}): RequestArgs {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling v2UsersUserIdInventoryAssetTypeIdGet.');
            }
            // verify required parameter 'assetTypeId' is not null or undefined
            if (assetTypeId === null || assetTypeId === undefined) {
                throw new RequiredError('assetTypeId','Required parameter assetTypeId was null or undefined when calling v2UsersUserIdInventoryAssetTypeIdGet.');
            }
            const localVarPath = `/v2/users/{userId}/inventory/{assetTypeId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"assetTypeId"}}`, encodeURIComponent(String(assetTypeId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (cursor !== undefined) {
                localVarQueryParameter['cursor'] = cursor;
            }


                localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InventoryApi - functional programming interface
 * @export
 */
export const InventoryApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets user\'s inventory based on specific asset type.
         * @param {number} userId 
         * @param {number} assetTypeId 
         * @param {'Asc' | 'Desc'} [sortOrder] The order the results are sorted in.
         * @param {10 | 25 | 50 | 100} [limit] The amount of results per request.
         * @param {string} [cursor] The paging cursor for the previous or next page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2UsersUserIdInventoryAssetTypeIdGet(userId: number, assetTypeId: number, sortOrder?: 'Asc' | 'Desc', limit?: 10 | 25 | 50 | 100, cursor?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiPageResponseUserAssetModel> {
            const localVarAxiosArgs = InventoryApiAxiosParamCreator(configuration).v2UsersUserIdInventoryAssetTypeIdGet(userId, assetTypeId, sortOrder, limit, cursor, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * InventoryApi - factory interface
 * @export
 */
export const InventoryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Gets user\'s inventory based on specific asset type.
         * @param {number} userId 
         * @param {number} assetTypeId 
         * @param {'Asc' | 'Desc'} [sortOrder] The order the results are sorted in.
         * @param {10 | 25 | 50 | 100} [limit] The amount of results per request.
         * @param {string} [cursor] The paging cursor for the previous or next page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2UsersUserIdInventoryAssetTypeIdGet(userId: number, assetTypeId: number, sortOrder?: 'Asc' | 'Desc', limit?: 10 | 25 | 50 | 100, cursor?: string, options?: any) {
            return InventoryApiFp(configuration).v2UsersUserIdInventoryAssetTypeIdGet(userId, assetTypeId, sortOrder, limit, cursor, options)(axios, basePath);
        },
    };
};

/**
 * InventoryApi - interface
 * @export
 * @interface InventoryApi
 */
export interface InventoryApiInterface {
    /**
     * 
     * @summary Gets user\'s inventory based on specific asset type.
     * @param {number} userId 
     * @param {number} assetTypeId 
     * @param {'Asc' | 'Desc'} [sortOrder] The order the results are sorted in.
     * @param {10 | 25 | 50 | 100} [limit] The amount of results per request.
     * @param {string} [cursor] The paging cursor for the previous or next page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventoryApiInterface
     */
    v2UsersUserIdInventoryAssetTypeIdGet(userId: number, assetTypeId: number, sortOrder?: 'Asc' | 'Desc', limit?: 10 | 25 | 50 | 100, cursor?: string, options?: any): AxiosPromise<ApiPageResponseUserAssetModel>;

}

/**
 * InventoryApi - object-oriented interface
 * @export
 * @class InventoryApi
 * @extends {BaseAPI}
 */
export class InventoryApi extends BaseAPI implements InventoryApiInterface {
    /**
     * 
     * @summary Gets user\'s inventory based on specific asset type.
     * @param {number} userId 
     * @param {number} assetTypeId 
     * @param {'Asc' | 'Desc'} [sortOrder] The order the results are sorted in.
     * @param {10 | 25 | 50 | 100} [limit] The amount of results per request.
     * @param {string} [cursor] The paging cursor for the previous or next page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventoryApi
     */
    public v2UsersUserIdInventoryAssetTypeIdGet(userId: number, assetTypeId: number, sortOrder?: 'Asc' | 'Desc', limit?: 10 | 25 | 50 | 100, cursor?: string, options?: any) {
        return InventoryApiFp(this.configuration).v2UsersUserIdInventoryAssetTypeIdGet(userId, assetTypeId, sortOrder, limit, cursor, options)(this.axios, this.basePath);
    }

}


